<mat-selection-list #providers [disabled]="readonly">
  @for (provider of serviceProviders; track provider) {
    <mat-list-option
      [value]="provider.id"
      [selected]="provider.selected"
      (click)="providerClicked(provider.id)"
    >
      {{ provider.providerAgency }}
    </mat-list-option>
    <mat-divider></mat-divider>
  }
</mat-selection-list>

@if (serviceProviders.length < 1) {
  <div class="m-2">No providers available for selected service categories</div>
}
