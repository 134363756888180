<mat-dialog-content class="pb-0">
  <h4 mat-dialog-title>Provider Comments</h4>

  @if (data.interestComment) {
    <b>Service Request Comment</b>
    <div>{{ data.interestComment }}</div>
    <mat-divider></mat-divider>
    <br>
  }

  @if (data.proposalComment) {
    <b>Proposal Comment</b>
    <div>{{ data.proposalComment }}</div>
    <mat-divider></mat-divider>
    <br>
  }

  @if (!data.interestComment && !data.proposalComment) {
    <div class="m-2">Provider did not leave a comment</div>
    <mat-divider></mat-divider>
  }
</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Close</button>
</mat-dialog-actions>
