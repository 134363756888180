import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PaginatedService, PathsWithMethod } from "../services/paginated.service";
import { PaginationQuery, PaginationQueryBase } from "./query-models/pagination-query.model";
import { paths } from "../../../../../src/clients/backend/backend-openapi-schema";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { PaginationResult } from "./query-models/pagination-result.model";
import { PAGE_SIZE_OPTIONS } from "../utils/constants/pagination.constants";
import { Sort } from "@angular/material/sort";

@Component({
    template: ""
})
export abstract class PaginatedComponent<TModel, TQuery extends PaginationQueryBase> implements OnInit, AfterViewInit {
    abstract tblQuery: PaginationQueryBase;
    pageSizeOptions = PAGE_SIZE_OPTIONS;
    loading: boolean = true;
    dataSource: MatTableDataSource<TModel> = new MatTableDataSource<TModel>();
    route: PathsWithMethod<paths, "post"> = this.buildRoute();
    displayedColumns: string[] = this.buildColumnHeaders();
    protected pagination: PaginationQuery = new PaginationQuery({
        page: 0,
        pageSize: 25,
        sortAscending: true,
        sortField: "Id"
    });
    protected paginationResult: PaginationResult<TModel, TQuery> | null = null;

    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

    constructor(protected service: PaginatedService<TModel, TQuery>) {
    }

    ngAfterViewInit(): void {
        this.getData(null);
    }

    ngOnInit(): void { }

    async getData(pageEvent: PageEvent | null): Promise<void> {
        this.loading = true;

        const query = this.buildQuery();

        if (pageEvent) {
            query.pagination.page = pageEvent.pageIndex;
            query.pagination.pageSize = pageEvent.pageSize;
        }

        const result = await this.service.paginatedGet(query, this.route);

        this.paginationResult = result;
        this.dataSource.data = result.items;

        this.loading = false;
    }

    async announceSortChange(sortState: Sort) {
      this.tblQuery.pagination.sortField = sortState.active
      this.tblQuery.pagination.sortAscending = sortState.direction == 'asc' ? true : false;

      this.getData(null)
    }

    abstract buildQuery(): TQuery;

    abstract buildColumnHeaders(): string[];

    abstract buildRoute(): PathsWithMethod<paths, "post">;
}
