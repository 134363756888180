import { Component, OnInit } from '@angular/core';
import { ServiceRequestService } from '../../../../services/service-request.service';
import { ServiceRequest } from '../../../../models/service-request.models';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterService } from '../../../../services/router.service';
import { ProviderServiceRequestService } from '../../../../services/provider-service-request.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ALLOWED_FILE_TYPES } from '../../../../utils/constants/allowed-file-types.constants';
import { FileUploaderComponent } from '../../../shared/file-uploader/file-uploader.component';
import { AttachmentsListComponent } from '../../../shared/attachments-list/attachments-list.component';
import { Attachment } from '../../../../models/attachment.model';
import { FileService } from '../../../../services/file.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { ServiceRequestProvidersService } from '../../../../services/service-request-providers.servive';
import { ProviderWithdrawlComponent } from '../../../dialogs/provider-withdrawl/provider-withdrawl.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-interest-view-service-request',
  standalone: true,
  imports: [
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
    FileUploaderComponent,
    AttachmentsListComponent,
    ReactiveFormsModule
  ],
  templateUrl: './interest-view-service-request.component.html',
  styleUrl: './interest-view-service-request.component.scss'
})
export class InterestViewServiceRequestComponent implements OnInit {
  serviceRequest!: ServiceRequest;
  interestId!: string;
  providerInterestResponseDate!: Date;
  providerProposalResponseDate!: Date;
  preferredStartDate?: Date = undefined;
  allowedFileTypes = ALLOWED_FILE_TYPES;
  newAttachments: File[] = [];
  serviceRequestAttachments: Attachment[] = [];
  providerAttachments: Attachment[] = [];
  comments = new FormControl("");
  isLoading: boolean = true;

constructor(
  private serviceRequestService: ServiceRequestService,
  private providerServiceRequestService: ProviderServiceRequestService,
  public activatedRoute: ActivatedRoute,
  public routerService: RouterService,
  private fileService: FileService,
  private dialog: MatDialog,
){}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async params => {
      const serviceRequestId = params["id"];
      this.interestId = params["interestId"];
      this.serviceRequest = await this.serviceRequestService.getServiceRequestById(serviceRequestId);
      this.isLoading = false;
      this.providerInterestResponseDate = new Date(this.serviceRequest.providerInterestResponseDate);
      this.providerProposalResponseDate = new Date(this.serviceRequest.providerProposalResponseDate);
      if (this.serviceRequest.preferredStartDate !== null) {
        this.preferredStartDate = new Date(this.serviceRequest.preferredStartDate);
      }
      this.serviceRequestAttachments = this.serviceRequest.attachments;
      this.serviceRequest.providers.forEach(provider => {
        this.providerAttachments = provider.serviceRequestAttachments;
      })
      if(this.isDisabled) {
        this.comments.disable();
      }
    });
  }

  get isDisabled(): boolean {
    return this.serviceRequest.providers[0]?.status !== "Pending";
  }

  async submitInterestClick() {
    await this.providerServiceRequestService.providerInterested(this.interestId, "provider-serivce-requests", this.comments.value!);

    await this.fileService.uploadProviderAttachment(this.interestId, this.newAttachments).subscribe(() => { });

    this.routerService.goToProviderServiceRequestsScreen();
  }

  removeFile(file: File) {
    const indexToRemove = this.newAttachments.indexOf(file);
    this.newAttachments.splice(indexToRemove, 1);
  }

  openWithdrawDialog() {
    const dialogRef = this.dialog.open(ProviderWithdrawlComponent, {
      width: '30%'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== null && result !== undefined) {
        this.providerServiceRequestService.withdrawProvider(
          this.interestId,
          'provider-service-requests',
          result
        );

        this.routerService.goToProviderServiceRequestsScreen();
      }
    });
  }

  createProposalClick() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "serviceRequestProviderId": this.interestId,
        "serviceRequestId": this.serviceRequest.id 
      }
    };

    this.routerService.goToProviderCreateProposalScreen(navigationExtras);
  }
}
