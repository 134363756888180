import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule, NgFor } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { Role } from '../../../../models/role.model';
import { IUpsertUserCommand } from '../../../../form-interfaces/update-user-form.interface';
import { RoleService } from '../../../../services/role.service';
import { ServiceCategory } from '../../../../models/service-category.models';
import { ServiceCategoryService } from '../../../../services/service-category.service';
import { UserService } from '../../../../services/user.service';
import { RouterService } from '../../../../services/router.service';
import { MatButtonModule } from '@angular/material/button';
import { ADMINISTRATOR, SERVICE_COORDINATOR, SERVICE_COORDINATOR_ADMINISTRATOR, SERVICE_PROVIDER, SUPPORT_COORDINATOR, SUPPORT_COORDINATOR_ADMINISTRATOR } from '../../../../utils/constants/role.constants';
import { StrongPasswordRegEx } from '../../../../utils/constants/password-regex.constant';
import { User } from '../../../../models/user.models';
import { MatDividerModule } from '@angular/material/divider';
import { ServiceCoordinatorGroupService } from '../../../../services/service-coordinator-group.service';
import { PaginatedServiceCoordinatorGroup } from '../../../../models/service-coordinator-group.models';

@Component({
  selector: 'app-upsert-user',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    NgFor,
    MatSelectModule,
    CommonModule,
    MatButtonModule,
    MatDividerModule
  ],
  templateUrl: './upsert-user.component.html',
  styleUrl: './upsert-user.component.scss'
})
export class UpsertUserComponent implements OnInit {
  roles: Role[] = [];
  serviceCategories: ServiceCategory[] = [];
  serviceCoordinatorGroups: PaginatedServiceCoordinatorGroup[] = [];

  serviceProviderRoleIds: string[] = [];
  serviceCoordinatorRoleIds: string[] = [];
  supportCoordinatorRoleIds: string[] = [];
  administratorRoleIds: string[] = [];

  upsertUserForm!: FormGroup<IUpsertUserCommand>;

  isSaving: boolean = false;

  get passwordError(): string {
    if (this.upsertUserForm?.controls.password.hasError('pattern')) {
      return "Password must contain at least ten characters, one uppercase letter, one lowercase letter, one number and one special character.";
    }

    return "";
  }

  get emailError(): string {
    if (this.upsertUserForm?.controls.email.hasError('email')) {
      return "Enter a valid email";
    }

    return "";
  }

  get pageTitle(): string {
    if (this.upsertUserForm?.controls.id.value === "") {
      return "Create User"
    } else {
      return "Editing " + this.upsertUserForm?.controls.firstName.value + " " + this.upsertUserForm?.controls.lastName.value
    }
  }

  get serviceCategoriesVisible(): boolean {
    const roles = this.upsertUserForm?.controls.roleIds.value;

    return roles.some(r => this.serviceProviderRoleIds.includes(r));
  }

  get serviceCoordinatorGroupVisible(): boolean {
    const roleIds = this.upsertUserForm?.controls.roleIds.value;

    if (roleIds.some(r => this.serviceCoordinatorRoleIds.includes(r)) || roleIds.some(r => this.supportCoordinatorRoleIds.includes(r))) {
      return true;
    }

    return false;
  }

  constructor(
    public activatedRoute: ActivatedRoute,
    public routerService: RouterService,
    private roleService: RoleService,
    private userService: UserService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceCoordinatorGroupService: ServiceCoordinatorGroupService
  ) {}

  async ngOnInit() {
    await this.getRoles();
    await this.getServiceCategories();
    await this.getServiceCoordinatorGroups();

    this.activatedRoute.queryParams.subscribe(async params => {
      const userId = params["id"];

      const user: User | undefined = await this.getUser(userId);
      this.upsertUserForm = this.getUpsertUserForm(user);

      this.setRollRelatedValidators();

      if (user == undefined) {
        this.setPasswordValidators();
      }
    });
  }

  setPasswordValidators() {
    this.upsertUserForm?.controls.password.setValidators([
      Validators.required,
      Validators.pattern(StrongPasswordRegEx)
    ]);

    this.upsertUserForm?.controls.password.updateValueAndValidity();
  }

  setRollRelatedValidators() {
    this.upsertUserForm?.get('roleIds')!.valueChanges.subscribe(() => {
      const val = this.upsertUserForm?.getRawValue();

      // Check if roleIds contains any roll that requires a coordinator group
      if (val?.roleIds.some(r => this.serviceCoordinatorRoleIds.includes(r)) || val?.roleIds.some(r => this.supportCoordinatorRoleIds.includes(r))) {
        this.upsertUserForm?.controls.serviceCoordinatorGroup.setValidators(
          Validators.required
        );
        this.upsertUserForm?.controls.serviceCoordinatorGroup.updateValueAndValidity();
      } else {
        this.upsertUserForm?.controls.serviceCoordinatorGroup.setValue(null);
        this.upsertUserForm?.controls.serviceCoordinatorGroup.clearValidators();
        this.upsertUserForm?.controls.serviceCoordinatorGroup.updateValueAndValidity();
      }

      // Remove Service Categories if service provider role not present
      if (!val?.roleIds.some(r => this.serviceProviderRoleIds.includes(r))) {
        this.upsertUserForm?.controls.serviceCategoryIds.setValue(null);
      }
    });
  }

  disableRoleSelection(id: string) {
    const val = this.upsertUserForm?.getRawValue();

    if (val?.roleIds.some(r => this.administratorRoleIds.includes(r)) && !this.administratorRoleIds.includes(id)) {
      return true;
    }

    if (val?.roleIds.some(r => this.supportCoordinatorRoleIds.includes(r)) && !this.supportCoordinatorRoleIds.includes(id)) {
      return true;
    }

    if (val?.roleIds.some(r => this.serviceCoordinatorRoleIds.includes(r)) && !this.serviceCoordinatorRoleIds.includes(id)) {
      return true;
    }

    if (val?.roleIds.some(r => this.serviceProviderRoleIds.includes(r)) && !this.serviceProviderRoleIds.includes(id)) {
      return true;
    }

    return false;
  }

  async getRoles() {
    this.roles = await this.roleService.getRoles();

    if (this.roles != undefined) {
      this.roles.forEach(role => {
        if (role.name == SERVICE_COORDINATOR || role.name == SERVICE_COORDINATOR_ADMINISTRATOR) {
          this.serviceCoordinatorRoleIds.push(role.id);
        } else if (role.name == SUPPORT_COORDINATOR || role.name == SUPPORT_COORDINATOR_ADMINISTRATOR) {
          this.supportCoordinatorRoleIds.push(role.id);
        } else if (role.name == SERVICE_PROVIDER) {
          this.serviceProviderRoleIds.push(role.id);
        } else if (role.name == ADMINISTRATOR) {
          this.administratorRoleIds.push(role.id);
        }
      });
    }
  }

  async getServiceCategories() {
    this.serviceCategories = await this.serviceCategoryService.getServiceCategory();
  }

  async getServiceCoordinatorGroups() {
    this.serviceCoordinatorGroups = await this.serviceCoordinatorGroupService.getServiceCoordinatorGroups();
  }

  getUpsertUserForm(user: User | undefined): FormGroup<IUpsertUserCommand> {
    const roleIds: string[] = user?.getRoleIds() ?? [];
    const serviceCategoryIds: string[] = user?.getServiceCategories() ?? [];

    return new FormGroup<IUpsertUserCommand>({
      firstName: new FormControl<string>(user?.firstName ?? "", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      lastName: new FormControl<string>(user?.lastName ?? "", {
          nonNullable: true,
          validators: [Validators.required],
      }),
      email: new FormControl<string>(user?.email ?? "", {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.email
          ],
      }),
      roleIds: new FormControl<string[]>(roleIds, {
          nonNullable: true,
          validators: [Validators.required],
      }),
      providerAgency: new FormControl<string>(user?.providerAgency ?? "", {
        nonNullable: true
      }),
      serviceCoordinatorGroup: new FormControl<string>(user?.serviceCoordinatorGroup?.id ?? "", {
        nonNullable: false,
      }),
      serviceCategoryIds: new FormControl<string[]>(serviceCategoryIds, {
        nonNullable: false,
      }),
      id: new FormControl<string>(user?.id ?? "", {
        nonNullable: false,
      }),
      password: new FormControl<string>("", {
        nonNullable: false
      })
    });
  }

  async getUser(id: string | null): Promise<User | undefined> {
    if(id) {
      return await this.userService.getUserById(id);
    }

    return;
  }

  async onSaveClick() {
    if (this.upsertUserForm?.valid) {
      this.isSaving = true;

      let value = this.upsertUserForm.getRawValue();

      if (value.id !== null && value.id !== undefined && value.id !== "") {
        await this.userService.updateUser(value.id!, value.firstName, value.lastName, value.email, value.roleIds, value.providerAgency, value.serviceCoordinatorGroup ? value.serviceCoordinatorGroup : null, value.serviceCategoryIds);
      } else {
        await this.userService.registerUser(value.email, value.password!, value.firstName, value.lastName, value.roleIds, value.providerAgency, value.serviceCoordinatorGroup, value.serviceCategoryIds);
      }

      this.routerService.goToUsersScreen();
    }
  }

  onCancelClick() {
    this.upsertUserForm?.reset();
    this.routerService.goToUsersScreen();
  }
}
