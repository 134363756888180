<mat-dialog-content>
  <h4 mat-dialog-title>Provider Attachments</h4>
  <mat-divider></mat-divider>

  <div *ngFor="let attachment of data.attachments; let i = index" class="m-4">
    <button
      type="button"
      mat-icon-button
      (click)="this.fileService.downloadProviderAttachment(attachment)"
    >
      <mat-icon>download</mat-icon>
    </button>
    {{ attachment.displayName }}
    <mat-divider></mat-divider>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mx-1">
  <button mat-flat-button color="accent" [mat-dialog-close]>Close</button>
</mat-dialog-actions>
