import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { AuthenticatedAbstractService } from './authenticated-abstract.service';
import { Role } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends AuthenticatedAbstractService {
  constructor(private storageService: StorageService) {
    super(storageService);
  }

  async getRoles(): Promise<Role[]> {
    const { data, error } = await this.authenticatedClient.GET("/Role/GetRoles", {});

    const roles = data?.roles.map(r => new Role(r.id, r.name, r.userCount));

    return roles ?? [];
  }
}
