import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { CreateServiceRequest, ServiceRequest, PaginatedServiceRequest, UpdateServiceRequest } from '../models/service-request.models';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { ServiceRequestPaginationQuery } from '../models/query-models/service-request-pagination-query.model';
import { PaginatedService } from './paginated.service';
import { FileService } from './file.service';
import { Observable } from 'rxjs';
import { ExcludedProvider } from '../models/user.models';
import { Proposal } from '../models/proposal.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestService extends PaginatedService<PaginatedServiceRequest, ServiceRequestPaginationQuery> {
  constructor(private storageService: StorageService, private fileService: FileService) {
    super(storageService)
  }

  protected override handleSuccess(data: any): PaginationResult<PaginatedServiceRequest, ServiceRequestPaginationQuery> {
    const result = data as PaginationResult<PaginatedServiceRequest, ServiceRequestPaginationQuery>;
    const serviceRequests: PaginatedServiceRequest[] = result?.items.map(sr => new PaginatedServiceRequest(sr)) ?? [];
    const query: ServiceRequestPaginationQuery = ServiceRequestPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<PaginatedServiceRequest, ServiceRequestPaginationQuery>(serviceRequests, result.totalItems, query);
  }

  protected override handleNullOrUndefined(): PaginationResult<PaginatedServiceRequest, ServiceRequestPaginationQuery> {
    return new PaginationResult<PaginatedServiceRequest, ServiceRequestPaginationQuery>([], 0, new ServiceRequestPaginationQuery(null));
  }

  async getServiceRequestById(id: string): Promise<ServiceRequest> {
    const { data, error } = await this.authenticatedClient.POST("/ServiceRequest/GetServiceRequestById", {
      body: {
        "id": id
      }
    });

    return new ServiceRequest(data?.serviceRequest.id!, data?.serviceRequest.serviceRequestNumber!, data?.serviceRequest.status!, data?.serviceRequest.providerInterestResponseDate!, data?.serviceRequest.providerProposalResponseDate!, data?.serviceRequest.firstName!, data?.serviceRequest.lastName!, data?.serviceRequest.middleName!, data?.serviceRequest.mci!, data?.serviceRequest.comments!, data?.serviceRequest.preferredStartDate!, data?.serviceRequest.serviceLocation!, data?.serviceRequest.frequencyAndDuration!, data?.serviceRequest.requestType!, data?.serviceRequest.canBeClosed!, data?.serviceRequest.coordinatorEmail!, data?.serviceRequest.attachments!, data?.serviceRequest.categories!, data?.serviceRequest.providers!, data?.serviceRequest.proposals!, data?.serviceRequest.excludedProviders!);
  }

  async createServiceRequest(firstName: string, lastName: string, middleName: string | null, mci: string, comments: string, serviceLocation: string, frequencyAndDuration: string, preferredStartDate: string | null, providerInterestResponseDate: string, providerProposalResponseDate: string, serviceCategoryIds: string[], providersToExclude: ExcludedProvider[]): Promise<string[] | any> {
    const serviceRequest: CreateServiceRequest = new CreateServiceRequest(firstName, lastName, middleName, mci, comments, serviceLocation, frequencyAndDuration, preferredStartDate, providerInterestResponseDate, providerProposalResponseDate, serviceCategoryIds, providersToExclude);
    const { data, error } = await this.authenticatedClient.POST("/ServiceRequest/CreateServiceRequest", { body: serviceRequest });

    if (error != undefined) {
      return error;
    }

    return data?.serviceRequestIds!;
  }

  async updateServiceRequest(id: string, firstName: string, lastName: string, middleName: string | null, mci: string, comments: string, providerInterestResponseDate: string, providerProposalResponseDate: string, serviceCategoryIds: string[], preferredStartDate: string | null, frequencyAndDuration: string, serviceLocation: string | null, providersToExclude: ExcludedProvider[]): Promise<any> {
    const serviceRequest: UpdateServiceRequest = new UpdateServiceRequest(id, firstName, lastName, middleName, mci, comments, providerInterestResponseDate, providerProposalResponseDate, serviceCategoryIds, preferredStartDate, frequencyAndDuration, serviceLocation, providersToExclude);

    const { data, error } = await this.authenticatedClient.POST("/ServiceRequest/UpdateServiceRequest", { body: serviceRequest });

    if (error != undefined) {
      return error;
    }
  }

  uploadFiles(id: string, files: File[]): Observable<any> {
    return this.fileService.uploadServiceRequestAttachment(id, files);
  }

  deleteAttachment(id: string) {
    this.authenticatedClient.POST("/ServiceRequest/DeleteAttachment", { body: { "serviceRequestAttachmentId": id } });
  }

  deleteProviderAttachment(id: string) {
    this.authenticatedClient.POST("/ServiceRequest/DeleteProviderAttachment", { body: { "providerAttachmentId": id } });
  }

  async requestInterest(id: string, routeForEmail: string): Promise<void> {
    await this.authenticatedClient.POST("/ServiceRequest/RequestInterest", {
      body: {
        routeForEmail: routeForEmail,
        id: id
      }
    });
  }

  async createProposal(serviceRequestProviderId: string, firstName: string, lastName: string, startDate: string, frequencyAndDuration: string, serviceLocation: string, proposedAction: string, comments: string, routeForEmail: string): Promise<any> {
    const proposal: Proposal = new Proposal(serviceRequestProviderId, firstName, lastName, startDate, frequencyAndDuration, serviceLocation, proposedAction, comments, routeForEmail);

    const { data, error } = await this.authenticatedClient.POST("/ServiceRequest/CreateProposal", {body: proposal});

    if (error != undefined) {
      return error;
    }
  }

  async acceptProposal(id: string, routeForEmail: string, acceptedReason: string): Promise<any> {
    return await this.authenticatedClient.POST("/ServiceRequest/AcceptProposal", { body: {
      serviceRequestProposalId: id,
      routeForEmail: routeForEmail,
      acceptedReason: acceptedReason
    }});
  }

  async selectProviderWithoutProposal(id: string, acceptedReason: string): Promise<any> {
    return await this.authenticatedClient.POST("/ServiceRequest/SelectProviderWithoutProposal", { body: {
      serviceRequestProviderId: id,
      acceptedReason: acceptedReason
    }});
  }

  async closeServiceRequest(id: string): Promise<any> {
    return await this.authenticatedClient.POST("/ServiceRequest/CloseServiceRequest", { body: {
      id: id
    }});
  }

  async cancelServiceRequest(id: string, cancelReason: string): Promise<any> {
    return await this.authenticatedClient.POST("/ServiceRequest/CancelServiceRequest", {body: {
      id: id,
      cancelReason: cancelReason
    }});
  }
}
