<mat-action-list class="p-0 h-full">
  @for (link of navLinks; track link) {
    <button *ngIf="link.isVisible" mat-list-item (click)="link.click()">
      <mat-icon [matTooltip]="link.tooltipText">{{ link.icon }}</mat-icon>
    </button>
  }

  <button
    class="absolute bottom-0"
    mat-list-item
    (click)="logoutClick()"
    matTooltip="Logout"
  >
    <mat-icon>logout</mat-icon>
  </button>
</mat-action-list>
