import { IPaginationQuery, PaginationQuery, PaginationQueryBase } from "./pagination-query.model";

export interface IUserPaginationQuery extends PaginationQueryBase {
    email: string | null;
    userRoles: string | null;
    status: string | null;
}

export class UserPaginationQuery extends PaginationQueryBase {
    email: string | null;
    userRoles: string | null;
    status: string | null;

    constructor (
        email: string | null,
        userRoles: string | null,
        status: string | null
    ) {
        super();
        this.email = email;
        this.userRoles = userRoles;
        this.status = status;
    }

    static create(query: IUserPaginationQuery): UserPaginationQuery {
        let userQuery =  new UserPaginationQuery(query.email ?? "", query.userRoles ?? "", query.status ?? "");
        userQuery.pagination = new PaginationQuery(query.pagination);

        return userQuery;
    }
}
