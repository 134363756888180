<mat-dialog-content class="pb-2">
  <h4 mat-dialog-title>{{ data.title }}</h4>

  <div>{{ data.question }}</div>
</mat-dialog-content>

<mat-dialog-actions class="mx-4 gap-2">
  <button mat-flat-button color="accent" [mat-dialog-close]>Cancel</button>

  <button mat-flat-button color="primary" (click)="okClick()" class="ml-auto">
    Ok
  </button>
</mat-dialog-actions>
