import { IPaginationQuery, PaginationQuery, PaginationQueryBase } from "./pagination-query.model";

export interface IServiceRequestReportPaginationQuery {
    serviceRequestNumber: number | null;
    serviceRequestStatus: string | null;
    providerFirstName: string | null;
    providerLastName: string | null;
    providerStatus: string | null;
    providerAgency: string | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    email: string | null;
    mci: string | null;
    startDate: string | null;
    endDate: string | null;
    serviceRequestType: string | null;
    serviceCategory: string | null;
    serviceSupportCoordinator: string | null;
    pagination: IPaginationQuery;
}

export class ServiceRequestReportPaginationQuery extends PaginationQueryBase {
    serviceRequestNumber: number | null;
    serviceRequestStatus: string | null;
    providerFirstName: string | null;
    providerLastName: string | null;
    providerStatus: string | null;
    providerAgency: string | null;
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
    email: string | null;
    mci: string | null;
    startDate: string | null;
    endDate: string | null;
    serviceRequestType: string | null;
    serviceCategory: string | null;
    serviceSupportCoordinator: string | null;

    constructor (serviceRequest: IServiceRequestReportPaginationQuery | null) {
        super();
        this.serviceRequestNumber = serviceRequest?.serviceRequestNumber ?? null;
        this.serviceRequestStatus = serviceRequest?.serviceRequestStatus ?? null;
        this.providerFirstName = serviceRequest?.providerFirstName ?? null;
        this.providerLastName = serviceRequest?.providerLastName ?? null;
        this.providerStatus = serviceRequest?.providerStatus ?? null;
        this.providerAgency = serviceRequest?.providerAgency ?? null
        this.firstName = serviceRequest?.firstName ?? null;
        this.middleName = serviceRequest?.middleName ?? null;
        this.lastName = serviceRequest?.lastName ?? null;
        this.email = serviceRequest?.email ?? null;
        this.mci = serviceRequest?.mci ?? null;
        this.startDate = serviceRequest?.startDate ?? null;
        this.endDate = serviceRequest?.endDate ?? null;
        this.serviceRequestType = serviceRequest?.serviceRequestType ?? null;
        this.serviceCategory = serviceRequest?.serviceCategory ?? null;
        this.serviceSupportCoordinator = serviceRequest?.serviceSupportCoordinator ?? null;
    }

    static create(query: IServiceRequestReportPaginationQuery): ServiceRequestReportPaginationQuery {
        let serviceRequestQuery =  new ServiceRequestReportPaginationQuery(query);
        serviceRequestQuery.pagination = new PaginationQuery(query.pagination);

        return serviceRequestQuery;
    }
}
