<div class="flex flex-col my-8 items-center justify-center">
  <div class="text-3xl font-serif">Request for Provider System</div>
  <div
    class="login-container w-1/3 basis-1/3 border-solid border-2 border-sasmg-green rounded m-8 p-8 bg-slate-100"
  >
    <form [formGroup]="loginForm" class="form" (ngSubmit)="onLoginClick()">
      <div class="flex flex-col divide-y">
        <div class="text-xl mx-auto">User Login</div>

        <p class="incorrect-password" *ngIf="enteredIncorrectly">
          Incorrect Email or Password
        </p>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input name="email" matInput formControlName="email" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input
            name="password"
            matInput
            [type]="passwordVisible ? 'text' : 'password'"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="passwordVisible = !passwordVisible"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="!passwordVisible">
            <mat-icon>{{passwordVisible ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
        </mat-form-field>
        <div class="input-g">

        </div>

        <button mat-flat-button class="m-2" type="submit" color="primary">
          <div *ngIf="!isLoading">
            Login
          </div>
          <div *ngIf="isLoading">
            <mat-spinner [diameter]="25" color="accent"></mat-spinner>
          </div>
        </button>
        <button mat-button class="m-2" type="button" (click)="openDialog()">
          Forgot Password
        </button>
      </div>
    </form>
  </div>
</div>
