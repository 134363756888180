import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { CreateServiceCategory, GetServiceCategoryById, ServiceCategory, UpdateServiceCategory } from '../models/service-category.models';
import { PaginatedService } from './paginated.service';
import { PaginatedServiceCategory } from '../models/service-coordinator.models';
import { ServiceCategoryPaginationQuery } from '../models/query-models/service-category-pagination-query.models';
import { PaginationResult } from '../models/query-models/pagination-result.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceCategoryService extends PaginatedService<PaginatedServiceCategory, ServiceCategoryPaginationQuery> {
  protected override handleSuccess(data: any): PaginationResult<PaginatedServiceCategory, ServiceCategoryPaginationQuery> {
    const result = data as PaginationResult<PaginatedServiceCategory, ServiceCategoryPaginationQuery>;
    const serviceCategories: PaginatedServiceCategory[] = result?.items.map(u => new PaginatedServiceCategory(u)) ?? [];
    const query: ServiceCategoryPaginationQuery = ServiceCategoryPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<PaginatedServiceCategory, ServiceCategoryPaginationQuery>(serviceCategories, result.totalItems, query);
  }

  protected override handleNullOrUndefined(): PaginationResult<PaginatedServiceCategory, ServiceCategoryPaginationQuery> {
    return new PaginationResult<PaginatedServiceCategory, ServiceCategoryPaginationQuery>([], 0, new ServiceCategoryPaginationQuery(null, null));
  }

  constructor(private storageService: StorageService) {
    super(storageService);
  }

  async getServiceCategory(): Promise<ServiceCategory[]> {
    const { data, error } = await this.authenticatedClient.GET("/ServiceCategory/GetAllServiceCategories", {});
    const serviceCategories = data?.serviceCategories.map(sc => new ServiceCategory(sc.id, sc.name, sc.status));

    return serviceCategories ?? [];
  }

  async getServiceCategoryById(id: string): Promise<ServiceCategory | null> {
    const getServiceCategory: GetServiceCategoryById = new GetServiceCategoryById(id);

    const { data, error } = await this.authenticatedClient.POST("/ServiceCategory/GetServiceCategoryById", { body: getServiceCategory });

    if (!data) {
      return null;
    }

    return new ServiceCategory(data.serviceCategory.id, data.serviceCategory.name, data.serviceCategory.status);
  }

  async createServiceCategory(name: string) {
    const serviceCategory: CreateServiceCategory = new CreateServiceCategory(name);

    const { data, error } = await this.authenticatedClient.POST("/ServiceCategory/CreateServiceCategory", { body: serviceCategory });
  }

  async updateServiceCategory(id: string, name: string) {
    const serviceCategory: UpdateServiceCategory = new UpdateServiceCategory(id, name);

    const { data, error } = await this.authenticatedClient.POST("/ServiceCategory/UpdateServiceCategory", { body: serviceCategory });
  }
}
