<mat-dialog-content class="pb-0">
  <div [formGroup]="forgotPasswordForm">
    <h4 mat-dialog-title>Forgot Password</h4>

    <p>Enter email to reset password</p>

    <mat-form-field>
      @if (forgotPasswordForm.controls.email.errors?.["email"]) {
        <mat-error>Email not valid</mat-error>
      }
      <mat-label>Email</mat-label>
      <input matInput cdkFocusInitial formControlName="email" />
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Cancel</button>

  <button mat-flat-button color="primary" (click)="okClick()" class="ml-auto">
    Ok
  </button>
</mat-dialog-actions>
