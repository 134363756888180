import { Component } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { PaginatedServiceRequestReport } from '../../../models/service-request-report.model';
import { ServiceRequestReportService } from '../../../services/service-request-report.service';
import { RouterService } from '../../../services/router.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ServiceRequestReportPaginationQuery } from '../../../models/query-models/service-request-report-pagination-query.model';
import { PaginatedComponent } from '../../../models/paginated.component';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule, MatHint, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { InterestStatus } from '../../../utils/constants/interest-status.constants';
import { ServiceRequestStatus } from '../../../utils/constants/service-request-status.constants';
import { ServiceRequestTypes } from '../../../utils/constants/service-request-type.constants';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { ServiceCategoryService } from '../../../services/service-category.service';
import { ServiceCategory } from '../../../models/service-category.models';
import { UserService } from '../../../services/user.service';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { AutocompleteUser } from '../../../models/user.models';
import { PrettyConstantPipe } from "../../../utils/extensions/pretty-constant-pipe";
@Component({
  selector: 'app-service-requests-report',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatLabel,
    MatHint,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatDatepickerModule,
    MatSortModule,
    MatAutocompleteModule,
    PrettyConstantPipe
],
  templateUrl: './service-requests-report.component.html',
  styleUrl: './service-requests-report.component.scss'
})

export class ServiceRequestsReportComponent extends PaginatedComponent<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery> {

  serviceRequestForm!: FormGroup;

  interestStatus = InterestStatus;
  serviceRequestStatus = ServiceRequestStatus;
  serviceRequestType = ServiceRequestTypes;
  serviceCategories!: ServiceCategory[];
  userAutocomplete!: AutocompleteUser[];

  override tblQuery: ServiceRequestReportPaginationQuery = ServiceRequestReportPaginationQuery.create({
    serviceRequestNumber: null,
    serviceRequestStatus: null,
    providerFirstName: null,
    providerLastName: null,
    providerStatus: null,
    providerAgency: null,
    firstName: null,
    middleName: null,
    lastName: null,
    email: null,
    mci: null,
    serviceRequestType: null,
    startDate: null,
    endDate: null,
    serviceCategory: null,
    serviceSupportCoordinator: null,
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: true,
      sortField: "Id"
    }
  });

  override buildQuery(): ServiceRequestReportPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['serviceRequestNumber', 'providerAgency', 'providerStatus', 'serviceRequestStatus', 'serviceRequestMCI', 'firstName', 'lastName', 'middleName', 'serviceRequestType', 'auditDate', 'categories', 'serviceRequestComments'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceRequest/GetServiceRequestsReport";
  }

  constructor(
    private serviceRequestReportService: ServiceRequestReportService,
    private routerService: RouterService,
    private serviceCategoryService: ServiceCategoryService,
    private userService: UserService,
  ) {
    super(serviceRequestReportService);
  }

  get serviceRequestUrl() {
    return 'upsert-service-request?id=';
  }

  override async ngOnInit(): Promise<void> {
    this.initializeForm();

    this.serviceCategories = await this.serviceCategoryService.getServiceCategory();
  }

  initializeForm(): void {
    this.serviceRequestForm = new FormGroup({
      serviceRequestNumber: new FormControl<number | null>(null),
      providerEmail: new FormControl<string | null>(null),
      providerFirstName: new FormControl<string | null>(null),
      providerLastName: new FormControl<string | null>(null),
      providerAgency: new FormControl<string | null>(null),
      serviceRequestStatus: new FormControl<string | null>(null),
      serviceRequestMci: new FormControl<string | null>(null),
      serviceRequestFirstName: new FormControl<string | null>(null),
      serviceRequestMiddleName: new FormControl<string | null>(null),
      serviceRequestLastName: new FormControl<string | null>(null),
      providerStatus: new FormControl<string | null>(null),
      serviceRequestType: new FormControl<string | null>(null),
      startDate: new FormControl<Date | null>(null),
      endDate: new FormControl<Date | null>(null),
      serviceCategory: new FormControl<Date | null>(null),
      serviceSupportCoordinator: new FormControl<AutocompleteUser | null>(null),
    });
}

  getReport(){
    this.tblQuery.email = this.serviceRequestForm.controls['providerEmail']?.value;
    this.tblQuery.serviceRequestNumber = this.serviceRequestForm.controls['serviceRequestNumber']?.value;
    this.tblQuery.serviceRequestStatus = this.serviceRequestForm.controls['serviceRequestStatus']?.value;
    this.tblQuery.providerFirstName = this.serviceRequestForm.controls['providerFirstName']?.value;
    this.tblQuery.providerLastName = this.serviceRequestForm.controls['providerLastName']?.value;
    this.tblQuery.providerAgency = this.serviceRequestForm.controls['providerAgency']?.value;
    this.tblQuery.mci = this.serviceRequestForm.controls['serviceRequestMci']?.value;
    this.tblQuery.firstName = this.serviceRequestForm.controls['serviceRequestFirstName']?.value;
    this.tblQuery.middleName = this.serviceRequestForm.controls['serviceRequestMiddleName']?.value;
    this.tblQuery.lastName = this.serviceRequestForm.controls['serviceRequestLastName']?.value;
    this.tblQuery.providerStatus = this.serviceRequestForm.controls['providerStatus']?.value;
    this.tblQuery.serviceRequestType = this.serviceRequestForm.controls['serviceRequestType']?.value;
    this.tblQuery.startDate = this.serviceRequestForm.controls['startDate']?.value;
    this.tblQuery.endDate = this.serviceRequestForm.controls['endDate']?.value;
    this.tblQuery.serviceCategory = this.serviceRequestForm.controls['serviceCategory']?.value;
    this.tblQuery.serviceSupportCoordinator = this.serviceRequestForm.controls['serviceSupportCoordinator']?.value?.id;

    this.paginator?.firstPage();

    this.getData(null);
  }

  exportReport(){
    this.serviceRequestReportService.exportReport(this.tblQuery);
  }

  resetFilters(){
    this.serviceRequestForm.reset();
  }

  getDate(createdDate: string){
    return new Date(createdDate + 'Z').toLocaleString();
  }

  displayFn(object: AutocompleteUser): string {
    return object ? object.name : '';
  }

  async updateUserAutocomplete(event: any): Promise<void> {
    let term = event.target.value;

    if(term.length > 1) {
      this.userAutocomplete = await this.userService.autocompleteServiceSupportCoordinator(term);
    } else{
      this.userAutocomplete = [];
    }
  }
}
