<div class="my-4 flex flex-col items-center justify-center">
  <div class="flex flex-row w-10/12">
    <h4>Service Coordinator Groups</h4>

    <button
      mat-flat-button
      class="ml-auto my-auto"
      color="primary"
      (click)="this.routerService.goToUpsertServiceCoordinatorGroupScreen(null)"
    >
      New
    </button>
  </div>
  <div class="w-10/12">
    @if (dataSource !== null) {
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z2 rounded"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let serviceCategory">
            {{ serviceCategory.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let serviceCategory">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editClick(serviceCategory)">
                <span>Edit</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="font-bold text-white bg-table-header-gray color-sort-header-arrow-white"></tr>
        <tr mat-row *matRowDef="let row; let odd = odd; let even = even; columns: displayedColumns;" [ngClass]="{'bg-table-row-odd-gray': odd, 'bg-table-row-even-gray': even}"></tr>
      </table>
    }

    <mat-paginator
      [length]="paginationResult?.totalItems ?? 0"
      [pageSize]="this.pagination.pageSize"
      [pageSizeOptions]="this.pageSizeOptions"
      aria-label="Select page"
      (page)="getData($event)"
      class="mat-elevation-z2"
    ></mat-paginator>
  </div>
</div>
