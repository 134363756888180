export class CoordinatorAdminServiceRequest {
	id: string;
	serviceRequestNumber: Number;
	status: string;
	providerInterestResponseDate: string;
	providerProposalResponseDate: string;
	firstName: string;
	lastName: string;
  	mci: string;
	createdByUserFirstName: string;
	createdByUserLastName: string;
	middleName: string;
	categories: string[];
	createdAt: Date;
	providers: {
		provider: {
			firstName: string;
			lastName: string;
			email: string;
			providerAgency: string;
		},
		status: number;
		acceptedReason: string;
	}[];

	constructor(
		id: string,
		serviceRequestNumber: Number,
		status: string,
		providerInterestResponseDate: string,
		providerProposalResponseDate: string,
		firstName: string,
		lastName: string,
		middleName: string,
    	mci: string,
		createdByUserFirstName: string,
		createdByUserLastName: string,
		categories: string[],
		createdAt: Date,
		providers: {
			provider: {
				firstName: string,
				lastName: string,
				email: string,
				providerAgency: string,
			},
			status: number,
			acceptedReason: string
		}[]
	) {
		this.id = id;
		this.serviceRequestNumber = serviceRequestNumber;
		this.status = status;
		this.providerInterestResponseDate = providerInterestResponseDate;
		this.providerProposalResponseDate = providerProposalResponseDate;
		this.firstName = firstName;
		this.lastName = lastName;
		this.middleName = middleName;
    	this.mci = mci;
		this.createdByUserFirstName = createdByUserFirstName;
		this.createdByUserLastName = createdByUserLastName;
		this.categories = categories;
		this.createdAt = new Date(createdAt + "Z");
		this.providers = providers;
	}
}
