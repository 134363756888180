@if (upsertServiceCategoryForm !== undefined) {
  <div class="items-center justify-center mx-40 my-5">
    <form
      [formGroup]="upsertServiceCategoryForm"
      class="grid grid-cols-5 gap-4"
    >
      <div class="col-span-5">
        <h3 class="mb-0">Service Request</h3>
        <mat-divider class="w-full mb-1"></mat-divider>
      </div>

      <div class="col-span-4">
        <div class="mt-2 border-solid border-2 border-stone-300 rounded">
          <p class="m-1 text-gray-400">Request Info</p>
          <mat-divider class="mx-1"></mat-divider>

          <div class="flex justify-evenly">
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Provider Interest Response Date</mat-label>
              <input
                matInput
                [matDatepicker]="interestDatepicker"
                formControlName="providerInterestResponseDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="interestDatepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #interestDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Provider Proposal Response Date</mat-label>
              <input
                matInput
                [matDatepicker]="proposalDatepicker"
                formControlName="providerProposalResponseDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="proposalDatepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #proposalDatepicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="flex justify-evenly">
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Preferred Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="startDatepicker"
                formControlName="preferredStartDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="startDatepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Frequency & Duration</mat-label>
              <input matInput formControlName="frequencyAndDuration" />
            </mat-form-field>
          </div>

          <div class="flex justify-evenly">
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Service Location</mat-label>
              <input matInput formControlName="serviceLocation" />
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Service Categories</mat-label>
              <mat-select formControlName="serviceCategoryIds" multiple>
                @for (
                  serviceCategory of serviceCategories;
                  track serviceCategory
                ) {
                  <mat-option [value]="serviceCategory.id">
                    {{ serviceCategory.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="mt-2 border-solid border-2 border-stone-300 rounded">
          <p class="m-1 text-gray-400">Personal Info</p>
          <mat-divider class="mx-1"></mat-divider>

          <div class="flex justify-evenly">
            <mat-form-field class="m-1 flex-grow">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" />
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="lastName" />
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Middle Name</mat-label>
              <input matInput formControlName="middleName" />
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>MCI</mat-label>
              <input matInput formControlName="mci" />
            </mat-form-field>
          </div>
        </div>

        <div
          class="grid grid-cols-4 mt-2 border-solid border-2 border-stone-300 rounded"
        >
          <div class="col-span-4">
            <p class="m-1 text-gray-400">Attachments</p>
            <mat-divider class="mx-1 mb-2"></mat-divider>
          </div>

          <div class="col-span-2">
            <app-attachments-list
              [serviceRequestAttachments]="currentAttachments"
              [newAttachments]="newAttachments"
              [disabled]="!isDraft"
              (removeNewAttachmentEmit)="removeFile($event)"
              (removeCurrentAttachmentEmit)="deleteAttachment($event)"
            ></app-attachments-list>
          </div>

          <div class="col-span-2">
            <app-file-uploader
              [disabled]="!isDraft"
              (uploadFileEmit)="this.newAttachments.push($event)"
            ></app-file-uploader>
          </div>
        </div>
      </div>

      <div class="mt-2 border-solid border-2 border-stone-300 rounded">
        <div class="flex justify-between">
          <p class="m-1 text-gray-400">Service Providers</p>
          <p (click)="showExclusionReasons()" class="m-1 text-xs hover:underline hover:cursor-pointer text-blue-500">Exclusion Reasons</p>
        </div>
        <mat-divider class="mx-1"></mat-divider>
        <app-exclude-providers
          [serviceCategoryIds]="serviceCategoriesSelected"
          [includedProviderIds]="providerIds"
          [providersNeedsFiltered]="providerExcludeNeedsFiltered"
          [serviceRequestId]="serviceRequestId"
          [excludedProviders]="excludedProviders"
          [readonly]="!isDraft"
          (providerClickEvent)="updateExcludedProviders($event)"
          (selectedProviderCountChangeEvent)="selectedProviderCountChange($event)"
        ></app-exclude-providers>
      </div>

      <div class="col-span-5">
        <mat-form-field class="w-full">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="isDraft" class="col-span-5">
        <div class="flex flex-row-reverse m-1 gap-2">
          <button mat-flat-button color="primary" type="button" (click)="save(true)" [disabled]="!hasAtLeastOneProvider">
            Save & Notify
          </button>

          <button mat-flat-button color="primary" type="button" (click)="save(false)">
            Save
          </button>

          <button
            mat-flat-button
            color="accent"
            type="button"
            (click)="routerService.goToServiceRequestsScreen()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
}
