export class Proposal {
    serviceRequestProviderId: string;
    firstName: string;
    lastName: string;
    startDate: string;
    frequencyAndDuration: string;
    serviceLocation: string;
    proposedAction: string;
    comments: string;
    routeForEmail: string;

    constructor(
        serviceRequestProviderId: string,
        firstName: string,
        lastName: string,
        startDate: string,
        frequencyAndDuration: string,
        serviceLocation: string,
        proposedAction: string,
        comments: string,
        routeForEmail: string,
    ) {
        this.serviceRequestProviderId = serviceRequestProviderId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.startDate = startDate;
        this.frequencyAndDuration = frequencyAndDuration;
        this.serviceLocation = serviceLocation;
        this.proposedAction = proposedAction;
        this.comments = comments;
        this.routeForEmail = routeForEmail;
    }
}

export class ProposalCoordinatorView {
    id: string;
    firstName: string;
    lastName: string;
    startDate: string;
    frequencyAndDuration: string;
    serviceLocation: string;
    proposedAction: string;
    comments: string | null;
    serviceRequestProvider: {
        providerFirstName: string;
        providerLastName: string;
    };

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        startDate: string,
        frequencyAndDuration: string,
        serviceLocation: string,
        proposedAction: string,
        comments: string | null,
        serviceRequestProvider: {
            providerFirstName: string,
            providerLastName: string,
        }
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.startDate = startDate;
        this.frequencyAndDuration = frequencyAndDuration;
        this.serviceLocation = serviceLocation;
        this.proposedAction = proposedAction;
        this.comments = comments;
        this.serviceRequestProvider = serviceRequestProvider;
    }
}