import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ALLOWED_FILE_TYPES } from '../../../utils/constants/allowed-file-types.constants';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    MatButtonModule,
    MatSnackBarModule
  ],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss'
})
export class FileUploaderComponent {
  allowedFileTypes = ALLOWED_FILE_TYPES;
  @Input() disabled: boolean = false;
  @Output() uploadFileEmit = new EventEmitter<File>();

  constructor (private _snackBar: MatSnackBar) {}

  uploadFile(event: any) {
    const file = event.target.files[0] as File;

    if (this.allowedFileTypes.indexOf(file?.type) === -1) {
      this._snackBar.open("Invalid File Type", "Ok");
      return;
    }

    this.uploadFileEmit.emit(file);
  }
}
