<mat-dialog-content class="pb-0">
  <h4 mat-dialog-title>Provider Exclusion Reasons</h4>

  @for (provider of data.providersToExclude; track provider) {
    <h5>{{provider.providerFirstName}} {{provider.providerLastName}}</h5>
    <div>{{provider.exclusionReason}}</div>
    <mat-divider></mat-divider>
  }

  @if (data.providersToExclude.length < 1) {
    <div class="m-2">No excluded providers available</div>
  }

</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Close</button>
</mat-dialog-actions>
