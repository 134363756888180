import { Injectable } from '@angular/core';
import { PaginatedService } from './paginated.service';
import { CoordinatorAdminServiceRequest } from '../models/coordinator-admin-service-request.model';
import { CoordinatorAdminServiceRequestPaginationQuery } from '../models/query-models/coordinator-admin-service-request-query.model';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CoordinatorAdminServiceRequestsService extends PaginatedService<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery> {
  protected override handleSuccess(data: any): PaginationResult<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery> {
    const result = data as PaginationResult<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery>;
    const serviceRequests: CoordinatorAdminServiceRequest[] =
      result?.items.map(sr => new CoordinatorAdminServiceRequest(sr.id,
        sr.serviceRequestNumber,
        sr.status,
        sr.providerInterestResponseDate,
        sr.providerProposalResponseDate,
        sr.firstName,
        sr.lastName,
        sr.middleName,
        sr.mci,
        sr.createdByUserFirstName,
        sr.createdByUserLastName,
        sr.categories,
        sr.createdAt,
        sr.providers)) ?? [];
    const query: CoordinatorAdminServiceRequestPaginationQuery = CoordinatorAdminServiceRequestPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery>(serviceRequests, result.totalItems, query);
  }
  protected override handleNullOrUndefined(): PaginationResult<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery> {
    return new PaginationResult<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery>([], 0, new CoordinatorAdminServiceRequestPaginationQuery(null));
  }

  constructor(private storageService: StorageService) {
    super(storageService)
  }

  async cancelServiceRequest(id: string, cancelReason: string): Promise<any> {
    return await this.authenticatedClient.POST("/ServiceRequest/CancelServiceRequest", {body: {
      id: id,
      cancelReason: cancelReason
    }});
  }
}
