import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { UserService } from '../../../services/user.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StrongPasswordRegEx } from '../../../utils/constants/password-regex.constant';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AUTH_TOKEN, REFRESH_TOKEN } from '../../../utils/constants/storage-key.constants';
import { StorageService } from '../../../services/storage.service';
import { jwtDecode } from 'jwt-decode';
import { FullJwtPayload } from '../../../utils/extensions/jwt-extensions';
import { RouterService } from '../../../services/router.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';

export interface IChangePasswordInputInterface {
  id: string;
}

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIcon
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {
  userToken!: string;
  isLoading: boolean = false;

  changePasswordForm = new FormGroup({
    id: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(StrongPasswordRegEx)
      ]
    }),
    passwordConfirm: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(StrongPasswordRegEx)
      ]
    })
  });

  passwordVisible: boolean = false;
  passwordVisibleConfirm: boolean = false;

  get passwordConfirmError(): string {
    if (this.changePasswordForm.controls.passwordConfirm.hasError('pattern')) {
      return "Password must contain at least ten characters, one uppercase letter, one lowercase letter, one number and one special character.";
    } else if (this.changePasswordForm.controls.passwordConfirm.hasError('match_error')) {
      return "Passwords do not match";
    }

    return "";
  }

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private routerService: RouterService,
    private snackBar: MatSnackBar
  ) {
    this.route.params.subscribe(params => {
      if(params && params['userToken']) {
        this.userToken = params['userToken'];
      }
    })
  }

  ngOnInit(): void {
    this.storageService.removeItem(AUTH_TOKEN);
    this.storageService.removeItem(REFRESH_TOKEN);

    var userId = jwtDecode<FullJwtPayload>(this.userToken).unique_name;

    this.changePasswordForm.controls.id.setValue(userId);
    this.changePasswordForm.controls.passwordConfirm.addValidators(
      this.createCompareValidator(this.changePasswordForm.controls.password, this.changePasswordForm.controls.passwordConfirm)
    );
    this.setPasswordChangeValidationCheck();
  }

  async okClick() {
    if (this.changePasswordForm.valid) {
      this.isLoading = true;

      await this.storageService.setItem(AUTH_TOKEN, this.userToken);

      const val = this.changePasswordForm.getRawValue();

      await this.userService.changeUserPassword(val.id, val.password);
      await this.storageService.removeItem(AUTH_TOKEN);

      this.snackBar.open("Password Reset!", 'OK', {
        duration: 3000
      });

      setTimeout(() => {
        this.routerService.goToLoginScreen();
      }, 1000)
    }
  }

  createCompareValidator(control: AbstractControl, controlToCompare: AbstractControl) {
    return () => {
      if (control.value !== controlToCompare.value) {
        return { match_error: 'Passwords do not match'};
      }
      return null;
    }
  }

  setPasswordChangeValidationCheck() {
    this.changePasswordForm.controls.password.valueChanges.subscribe(() => {
      this.changePasswordForm.controls.passwordConfirm.updateValueAndValidity();
    });
  }
}
