import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { jwtDecode } from 'jwt-decode';
import { StorageService } from './storage.service';
import { AUTH_TOKEN } from '../utils/constants/storage-key.constants';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedInAuthGuradService {

  constructor(
    private storageService: StorageService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    const token = this.storageService.getItem(AUTH_TOKEN);

    if(token && isTokenValid(token)){
      return true;
    }
    else{
      return this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    }
  }
}

export function isTokenValid(token: string): boolean {
  const decodedToken = jwtDecode(token);
  let exp: number | undefined;

  if (
      decodedToken &&
      typeof decodedToken === "object" &&
      "exp" in decodedToken &&
      typeof decodedToken.exp === "number"
  ) {
      exp = decodedToken.exp;
  }

  return !!decodedToken && !!exp && exp > Date.now() / 1000;
}
