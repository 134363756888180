import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { RouterService } from '../../../../services/router.service';
import { ServiceRequestService } from '../../../../services/service-request.service';
import { ServiceRequest } from '../../../../models/service-request.models';
import { ProposalCoordinatorView } from '../../../../models/proposal.model';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SetAcceptedReasonComponent } from '../../../dialogs/set-accepted-reason/set-accepted-reason.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-view-proposal',
  standalone: true,
  imports: [
    MatDividerModule,
    CommonModule,
    MatButtonModule
  ],
  templateUrl: './view-proposal.component.html',
  styleUrl: './view-proposal.component.scss'
})
export class ViewProposalComponent implements OnInit {
  serviceRequest!: ServiceRequest;
  proposal!: ProposalCoordinatorView;
  proposalStartDate!: Date;

  constructor (
    public activatedRoute: ActivatedRoute,
    public routerService: RouterService,
    private serviceRequestService: ServiceRequestService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      const serviceRequestId = params["serviceRequestId"];
      const serviceRequestProviderId = params["serviceRequestProviderId"];

      this.serviceRequest = await this.serviceRequestService.getServiceRequestById(serviceRequestId);
      this.proposal = this.serviceRequest.proposals.find((proposal) => proposal.serviceRequestProvider.id == serviceRequestProviderId)!;
      this.proposalStartDate = new Date(this.proposal.startDate);
    });
  }

  async acceptProposalClick() {
    if (this.serviceRequest.requestType == "EI") {
      const dialogRef = this.dialog.open(SetAcceptedReasonComponent, {
        width: '30%'
      });
      
      dialogRef.afterClosed().subscribe(async result => {
        if (result !== null && result !== undefined) {
          await this.serviceRequestService.acceptProposal(this.proposal.id, "provider-serivce-requests", result);

          const navigationExtras: NavigationExtras = {
            queryParams: {
              "id": this.serviceRequest.id
            }
          };

          this.routerService.goToServiceRequestProviderList(navigationExtras);
        }
      });
    } else {
      await this.serviceRequestService.acceptProposal(this.proposal.id, "provider-serivce-requests", "Proposal Accepted");

      const navigationExtras: NavigationExtras = {
        queryParams: {
          "id": this.serviceRequest.id
        }
      };

      this.routerService.goToServiceRequestProviderList(navigationExtras);
    }
  }

  cancelClick() {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": this.serviceRequest.id
      }
    };
    
    this.routerService.goToServiceRequestProviderList(navigationExtras);
  }
}
