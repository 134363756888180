@if (createProposalForm !== undefined) {
  <div class="items-center justify-center mx-40 my-5">
    <form [formGroup]="createProposalForm" class="grid grid-cols-5 gap-4">
      <div class="col-span-5">
        <h3 class="mb-0">Service Proposal For Service Request #{{ serviceRequest.serviceRequestNumber + " - " + serviceRequest.firstName + " " + serviceRequest.lastName }}</h3>
        <mat-divider class="w-full mb-1"></mat-divider>
      </div>

      <div class="col-span-5">
        <div class="mt-2 border-solid border-2 border-stone-300 rounded">
          <p class="m-1 text-gray-400">Proposal Info</p>
          <mat-divider class="mx-1"></mat-divider>

          @if (this.showName) {
            <div class="flex justify-evenly">
              <mat-form-field class="m-1 flex-grow">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName" />
              </mat-form-field>

              <mat-form-field class="m-1 flex-grow">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName" />
              </mat-form-field>
            </div>
          }

          <div class="flex justify-evenly">
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="startDatepicker"
                formControlName="startDate"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="startDatepicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDatepicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Frequency & Duration</mat-label>
              <input matInput formControlName="frequencyAndDuration" />
            </mat-form-field>
          </div>

          <div class="flex justify-evenly">
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Service Location</mat-label>
              <input matInput formControlName="serviceLocation" />
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Service Category</mat-label>
              <input matInput formControlName="proposedAction" />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div
        class="grid col-span-5 border-solid border-2 border-stone-300 rounded"
      >
        <div class="col-span-5">
          <p class="m-1 text-gray-400">Attachments</p>
          <mat-divider class="mx-1 mb-2"></mat-divider>
        </div>

        <div class="col-span-3">
          <app-attachments-list
            [serviceRequestProviderAttachments]="providerAttachments"
            [serviceRequestAttachmentsForProviderReadOnly]="serviceRequestAttachments"
            [newAttachments]="newAttachments"
            [disabled]="false"
            (removeNewAttachmentEmit)="removeFile($event)"
          ></app-attachments-list>
        </div>

        <div class="col-span-2">
          <app-file-uploader
            [disabled]="false"
            (uploadFileEmit)="this.newAttachments.push($event)"
          ></app-file-uploader>
        </div>
      </div>

      <div class="col-span-5">
        <mat-form-field class="w-full">
          <mat-label>Comments</mat-label>
          <textarea matInput formControlName="comments"></textarea>
        </mat-form-field>
      </div>

      <div class="col-span-5">
        <div class="flex flex-row-reverse m-1 gap-2">
          <button mat-flat-button color="primary" (click)="save()">Save</button>

          <button
            mat-flat-button
            color="accent"
            (click)="routerService.goToProviderServiceRequestsScreen()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
}
