import { Injectable } from '@angular/core';
import { AuthenticatedAbstractService } from './authenticated-abstract.service';
import { StorageService } from './storage.service';
import { PaginationQueryBase } from '../models/query-models/pagination-query.model';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { paths } from '../../../../../src/clients/backend/backend-openapi-schema'; 

export type PathsWithMethod<T, M extends string> = 
{
  [K in keyof T]: {
    [P in keyof T[K]]: P extends M ? K : never;
  }[keyof T[K]]; 
}[keyof T];

@Injectable({
  providedIn: 'root'
})
export abstract class PaginatedService<TResponseBody, TQueryBody extends PaginationQueryBase> extends AuthenticatedAbstractService {

  constructor(private paginatedServiceStorageService: StorageService) {
    super(paginatedServiceStorageService);
  }
  
  async paginatedGet(body: TQueryBody, route: PathsWithMethod<paths, "post">): Promise<PaginationResult<TResponseBody, TQueryBody>> { 
    const { data, error } = await this.authenticatedClient.POST(route, {
      body: <any>body
    });

    if (data !== null && data !== undefined) {
      return this.handleSuccess(data);
    } else {
      return this.handleNullOrUndefined();
    }
  }

  protected abstract handleSuccess(data: any): PaginationResult<TResponseBody, TQueryBody>

  protected abstract handleNullOrUndefined(): PaginationResult<TResponseBody, TQueryBody>
}
