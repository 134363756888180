<div class="flex flex-row my-8 items-center justify-center">
  <div
    class="change-password-container basis-1/3 border-solid border-2 border-sasmg-green rounded m-8 p-8 bg-slate-100"
  >
    <form [formGroup]="changePasswordForm" class="form">
      <div class="flex flex-col divide-y">
        <div class="text-xl">Change Password</div>

        <mat-form-field class="m-2">
          <mat-label>Password</mat-label>

          <input
            matInput
            cdkFocusInitial
            [type]="passwordVisible ? 'text' : 'password'"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="passwordVisible = !passwordVisible"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="!passwordVisible">
            <mat-icon>{{passwordVisible ? 'visibility' : 'visibility_off'}}</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field class="m-2">
          <mat-label>Confirm Password</mat-label>

          <input
            matInput
            [type]="passwordVisibleConfirm ? 'text' : 'password'"
            formControlName="passwordConfirm"
          />
          <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="passwordVisibleConfirm = !passwordVisibleConfirm"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="!passwordVisibleConfirm">
          <mat-icon>{{passwordVisibleConfirm ? 'visibility' : 'visibility_off'}}</mat-icon>
        </button>
        </mat-form-field>

        <div class="password-error">{{ passwordConfirmError }}</div>

        <button mat-flat-button class="m-2" type="submit" color="primary" (click)="okClick()">
          <div *ngIf="!isLoading">
          Submit
          </div>
          <div *ngIf="isLoading">
            <mat-spinner [diameter]="25" color="accent"></mat-spinner>
          </div>
        </button>
      </div>
    </form>
  </div>
</div>
