export {};

declare global {
    interface String {
        isNotNullEmptyOrUndefined(): boolean;
    }
}

String.prototype.isNotNullEmptyOrUndefined = function (): boolean {
    if (this !== null && this !== undefined && this !== "") {
        return true;
    } 

    return false;
};