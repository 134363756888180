import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { Attachment } from '../../../models/attachment.model';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { FileService } from '../../../services/file.service';

@Component({
  selector: 'app-provider-attachments',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent
  ],
  templateUrl: './provider-attachments.component.html',
  styleUrl: './provider-attachments.component.scss'
})
export class ProviderAttachmentsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {attachments: Attachment[]}, public fileService: FileService) {}
}
