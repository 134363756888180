import { Injectable } from "@angular/core";
import { StorageService } from "./storage.service";
import createClient, { Middleware } from "openapi-fetch";
import { AUTH_TOKEN } from "../utils/constants/storage-key.constants";
import { paths } from "../../../../../src/clients/backend/backend-openapi-schema";
import { environment } from "./../../../../../environments/environment"

@Injectable({
  providedIn: 'root',
})
export abstract class AuthenticatedAbstractService {
  authenticatedClient = createClient<paths>({
    baseUrl: environment.API,
  });
  storage!: StorageService;

  constructor(storageService: StorageService) {
    this.storage = storageService;
    this.authenticatedClient.use(this.buildAuthMiddleware(AUTH_TOKEN));
    this.authenticatedClient.use(this.buildErrorMiddleware());
  }

  private buildAuthMiddleware(tokenRoute: string): Middleware {
    const storageService = this.storage;

    const authMiddleware: Middleware = {
      async onRequest(req) {
        const token = storageService.getItem(tokenRoute);

        req.headers.set('Authorization', `Bearer ${token}`);
        return req;
      },
    };

    return authMiddleware;
  }

  private buildErrorMiddleware(): Middleware {
    const errorMiddleware: Middleware = {
      async onResponse(res) {
        if(res.ok || res.status === 401) {
          return res;
        } else {
          alert("An error occurred, please report this and try again later.")
        }

        return res;
      },
    };

    return errorMiddleware;
  }

  async refreshToken() {
    const refreshTokenClient = createClient<paths>({
      baseUrl: environment.API,
    });

    const REFRESH_TOKEN = "AUTHENTICATED_APP_REFRESH_TOKEN";

		refreshTokenClient.use(this.buildAuthMiddleware(REFRESH_TOKEN));

		var response = await refreshTokenClient.GET("/User/RefreshToken");

		this.storage.setItem(AUTH_TOKEN, <string>response?.data?.accessToken);
		this.storage.setItem(REFRESH_TOKEN, <string>response?.data?.refreshToken);

		this.authenticatedClient = createClient<paths>({
      baseUrl: environment.API,
    });

		this.authenticatedClient.use(this.buildAuthMiddleware(AUTH_TOKEN));
  }
}
