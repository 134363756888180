export interface IPaginatedServiceCoordinatorGroup {
    id: string;
    name: string;
}

export class PaginatedServiceCoordinatorGroup {
    id: string;
    name: string;

    constructor(serviceCoordinatorGroup: IPaginatedServiceCoordinatorGroup) {
        this.id = serviceCoordinatorGroup.id;
        this.name = serviceCoordinatorGroup.name;
    }
}

export class CreateServiceCoordinatorGroup {
    name: string;

    constructor(
        name: string
    ){
        this.name = name;
    }
}

export class UpdateServiceCoordinatorGroup {
    id: string;
    name: string;

    constructor(
        id: string,
        name: string
    ){
        this.id = id;
        this.name = name;
    }
}