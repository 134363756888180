<ng-container>
  <app-navbar></app-navbar>
</ng-container>

<mat-sidenav-container>
  <mat-sidenav *ngIf="this.authService.loggedIn" mode="side" opened>
    <app-sidenav></app-sidenav>
  </mat-sidenav>

  <mat-sidenav-content>
    <router-outlet />
  </mat-sidenav-content>
</mat-sidenav-container>
