@if (dataSource !== null) {
  <div class="my-4 flex flex-col items-center justify-center">
    <div class="flex flex-row w-10/12">
      <h4>Service Requests Report</h4>
    </div>

    <div class="w-10/12 mb-5">
      <form [formGroup]="serviceRequestForm" (ngSubmit)="getReport()">
        <div class="flex flex-row">
          <div class="mr-2">
            <mat-form-field>
              <mat-label>Service Request Id</mat-label>
              <input
                matInput
                type="number"
                formControlName="serviceRequestNumber"
              />
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Provider Agency</mat-label>
              <input matInput formControlName="providerAgency" />
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Service Request Status</mat-label>
              <mat-select
                matNativeControl
                formControlName="serviceRequestStatus"
              >
                @for (status of serviceRequestStatus; track status) {
                  <mat-option [value]="status.nameNoSpace">
                    {{ status.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Audit Start Date</mat-label>
              <input
                matInput
                [matDatepicker]="startDatePicker"
                formControlName="startDate"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="startDatePicker"
              ></mat-datepicker-toggle>

              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Audit End Date</mat-label>
              <input
                matInput
                [matDatepicker]="endDatePicker"
                formControlName="endDate"
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="endDatePicker"
              ></mat-datepicker-toggle>

              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Service Category</mat-label>
              <mat-select
                matNativeControl
                formControlName="serviceCategory"
              >
                @for (category of serviceCategories; track category) {
                  <mat-option [value]="category.id">
                    {{ category.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Service / Support Coordinator</mat-label>
              <input type="text" matInput formControlName="serviceSupportCoordinator" [matAutocomplete]="userAuto" (keyup)="updateUserAutocomplete($event)">
              <mat-autocomplete #userAuto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of userAutocomplete" [value]="option">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="mr-2">
            <mat-form-field>
              <mat-label>First Name</mat-label>
              <input matInput formControlName="serviceRequestFirstName" />
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Middle Name</mat-label>
              <input matInput formControlName="serviceRequestMiddleName" />
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="serviceRequestLastName" />
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Provider Status</mat-label>
              <mat-select matNativeControl formControlName="providerStatus">
                @for (status of interestStatus; track status) {
                  <mat-option [value]="status.nameNoSpace">
                    @if (status.name === "Accepted") {
                      Provider Selected
                    } @else if (status.name === "Pending") {
                      Interest Requested
                    } @else {
                      {{ status.name | prettyConstant }}
                    }
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>Service Request Type</mat-label>
              <mat-select matNativeControl formControlName="serviceRequestType">
                @for (type of serviceRequestType; track type) {
                  <mat-option [value]="type">
                    {{ type }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>

          <div class="mr-2">
            <mat-form-field>
              <mat-label>MCI</mat-label>
              <input matInput formControlName="serviceRequestMci" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="place-items-start">
            <button mat-raised-button>Apply</button>
            <button mat-raised-button class="mx-2" (click)="resetFilters()">
              Reset
            </button>
            <button mat-raised-button (click)="exportReport()">Export</button>
          </div>
        </div>
      </form>
    </div>
    <div class="w-10/12">
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z2 rounded"
      >
        <ng-container matColumnDef="serviceRequestNumber">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.ServiceRequestNumber"
            *matHeaderCellDef
          >
            Service Request Id
          </th>
            <td mat-cell *matCellDef="let serviceRequestReport">
              <a [href]="serviceRequestUrl + serviceRequestReport.serviceRequestId" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                {{ serviceRequestReport.serviceRequestServiceRequestNumber }}
              </a>
            </td>
        </ng-container>

        <ng-container matColumnDef="providerAgency">
          <th
            mat-header-cell
            mat-sort-header="Provider.ProviderAgency"
            *matHeaderCellDef
          >
            Provider Agency
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.providerAgency }}
          </td>
        </ng-container>

        <ng-container matColumnDef="providerStatus">
          <th mat-header-cell mat-sort-header="Status" *matHeaderCellDef>
            Provider Status
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            @if (serviceRequestReport.status === "Accepted") {
              Provider Selected
            } @else if (serviceRequestReport.status === "Pending") {
              Interest Requested
            } @else {
              {{ serviceRequestReport.status | prettyConstant }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceRequestMCI">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.MCI"
            *matHeaderCellDef
          >
            MCI
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestMci }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.LastName"
            *matHeaderCellDef
          >
            Last Name
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestLastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.FirstName"
            *matHeaderCellDef
          >
            First Name
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestFirstName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="middleName">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.MiddleName"
            *matHeaderCellDef
          >
            Middle Initial
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestMiddleName[0] }}
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceRequestStatus">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.Status"
            *matHeaderCellDef
          >
            Service Request Status
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestStatus | prettyConstant }}
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceRequestType">
          <th
            mat-header-cell
            mat-sort-header="ServiceRequest.RequestType"
            *matHeaderCellDef
          >
            Service Request Type
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestRequestType }}
          </td>
        </ng-container>

        <ng-container matColumnDef="auditDate">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header="ServiceRequest.CreatedAt"
            >
            Audit Date
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ getDate(serviceRequestReport.serviceRequestCreatedAt) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="categories">
          <th
            mat-header-cell
            *matHeaderCellDef
            >
            Categories
          </th>
          <td mat-cell *matCellDef="let serviceRequestReport" [matTooltip]="serviceRequestReport.categories">
            @if (serviceRequestReport.categories.length > 1) {
              {{ serviceRequestReport.categories[0] }}...
            } @else {
              {{ serviceRequestReport.categories }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceRequestComments">
          <th mat-header-cell *matHeaderCellDef>Service Request Comments</th>
          <td mat-cell *matCellDef="let serviceRequestReport">
            {{ serviceRequestReport.serviceRequestComments }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="font-bold text-white bg-table-header-gray"></tr>
        <tr mat-row *matRowDef="let row; let odd = odd; let even = even; columns: displayedColumns;" [ngClass]="{'bg-table-row-odd-gray': odd, 'bg-table-row-even-gray': even}"></tr>
      </table>

      <mat-paginator
        [length]="paginationResult?.totalItems ?? 0"
        [pageSize]="this.pagination.pageSize"
        [pageSizeOptions]="this.pageSizeOptions"
        aria-label="Select page"
        (page)="getData($event)"
        class="mat-elevation-z2"
      ></mat-paginator>
    </div>
  </div>
}
