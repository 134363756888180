<mat-dialog-content class="pb-0">
  <div [formGroup]="acceptedReasonForm">
    <h4 mat-dialog-title>Select Provider Reason</h4>

    <mat-form-field class="w-full">
      <mat-label>Accepted Reason</mat-label>
      <mat-select formControlName="acceptedReason">
        @for (option of selectOptions; track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Cancel</button>

  <button
    mat-flat-button
    color="primary"
    (click)="okClick()"
    [disabled]="!acceptedReasonForm.valid"
    class="ml-auto"
  >
    Ok
  </button>
</mat-dialog-actions>
