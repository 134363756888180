import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StrongPasswordRegEx } from '../../../../utils/constants/password-regex.constant';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { RouterService } from '../../../../services/router.service';
import { StorageService } from '../../../../services/storage.service';
import { UserService } from '../../../../services/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

export interface IChangePasswordDialogModel {
  id: string
}

@Component({
  selector: 'app-change-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIcon
  ],
  templateUrl: './change-password-dialog.component.html',
  styleUrl: './change-password-dialog.component.scss'
})
export class ChangePasswordDialogComponent {
  changePasswordForm = new FormGroup({
    id: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required]
    }),
    password: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(StrongPasswordRegEx)
      ]
    }),
    passwordConfirm: new FormControl("", {
      nonNullable: true,
      validators: [
        Validators.required,
        Validators.pattern(StrongPasswordRegEx)
      ]
    })
  });

  passwordVisible: boolean = false;
  passwordVisibleConfirm: boolean = false;

  get passwordConfirmError(): string {
    if (this.changePasswordForm.controls.passwordConfirm.hasError('pattern')) {
      return "Password must contain at least ten characters, one uppercase letter, one lowercase letter, one number and one special character.";
    } else if (this.changePasswordForm.controls.passwordConfirm.hasError('match_error')) {
      return "Passwords do not match";
    }

    return "";
  }

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IChangePasswordDialogModel
  ) {}

  ngOnInit(): void {
    this.changePasswordForm.controls.id.setValue(this.data.id);
    this.changePasswordForm.controls.passwordConfirm.addValidators(
      this.createCompareValidator(this.changePasswordForm.controls.password, this.changePasswordForm.controls.passwordConfirm)
    );
    this.setPasswordChangeValidationCheck();
  }

  async okClick() {
    if (this.changePasswordForm.valid) {
      const val = this.changePasswordForm.getRawValue();

      await this.userService.changeUserPassword(val.id, val.password);

      this.snackBar.open("Password Reset!", 'OK', {
        duration: 3000
      });

      this.dialogRef.close();
    }
  }

  createCompareValidator(control: AbstractControl, controlToCompare: AbstractControl) {
    return () => {
      if (control.value !== controlToCompare.value) {
        return { match_error: 'Passwords do not match'};
      }
      return null;
    }
  }

  setPasswordChangeValidationCheck() {
    this.changePasswordForm.controls.password.valueChanges.subscribe(() => {
      this.changePasswordForm.controls.passwordConfirm.updateValueAndValidity();
    });
  }
}
