import { IPaginationQuery, PaginationQuery, PaginationQueryBase } from "./pagination-query.model";

export interface IServiceRequestPaginationQuery {
    requestNumber: number | null;
    status: string | null;
    pagination: IPaginationQuery;
}

export class ServiceRequestPaginationQuery extends PaginationQueryBase {
    requestNumber: number | null;
    status: string | null;

    constructor (serviceRequest: IServiceRequestPaginationQuery | null) {
        super();
        this.requestNumber = serviceRequest?.requestNumber ?? null;
        this.status = serviceRequest?.status ?? null;
    }

    static create(query: IServiceRequestPaginationQuery): ServiceRequestPaginationQuery {
        let serviceRequestQuery =  new ServiceRequestPaginationQuery(query);
        serviceRequestQuery.pagination = new PaginationQuery(query.pagination);

        return serviceRequestQuery;
    }
}

export interface IServiceRequestForProviderPaginationQuery {
    serviceRequestsAfterDate: Date | null;
    onlyGetWithdrawRequests: boolean;
    pagination: IPaginationQuery;
}

export class ServiceRequestForProviderPaginationQuery extends PaginationQueryBase {
    serviceRequestsAfterDate: Date | null;
    onlyGetWithdrawRequests: boolean;

    constructor(serviceRequestForProvider: IServiceRequestForProviderPaginationQuery | null) {
        super();
        this.serviceRequestsAfterDate = serviceRequestForProvider?.serviceRequestsAfterDate ?? null;
        this.onlyGetWithdrawRequests = serviceRequestForProvider?.onlyGetWithdrawRequests ?? false;
    }

    static create(query: IServiceRequestForProviderPaginationQuery): ServiceRequestForProviderPaginationQuery {
        let serviceRequestForProviderQuery = new ServiceRequestForProviderPaginationQuery(query);
        serviceRequestForProviderQuery.pagination = new PaginationQuery(query.pagination);

        return serviceRequestForProviderQuery;
    }
}

export interface IServiceRequestProvidersWithInterestPaginationQuery {
    serviceRequestId: string | null;
    pagination: IPaginationQuery;
}

export class ServiceRequestProvidersWithInterestPaginationQuery extends PaginationQueryBase {
    serviceRequestId: string | null;

    constructor(serviceRequest: IServiceRequestProvidersWithInterestPaginationQuery | null) {
        super();
        this.serviceRequestId = serviceRequest?.serviceRequestId ?? null;
    }

    static create(query: IServiceRequestProvidersWithInterestPaginationQuery): ServiceRequestProvidersWithInterestPaginationQuery {
        let serviceRequestProvidersWithInterestQuery = new ServiceRequestProvidersWithInterestPaginationQuery(query);
        serviceRequestProvidersWithInterestQuery.pagination = new PaginationQuery(query.pagination);

        return serviceRequestProvidersWithInterestQuery;
    }
}

export interface IAuditServiceRequestPaginationQuery {
  serviceRequestId: string | null;
  pagination: IPaginationQuery;
}

export class AuditServiceRequestPaginationQuery extends PaginationQueryBase {
  serviceRequestId: string | null;

    constructor(serviceRequest: IAuditServiceRequestPaginationQuery | null) {
        super();
        this.serviceRequestId = serviceRequest?.serviceRequestId ?? null;
    }

    static create(query: IAuditServiceRequestPaginationQuery): AuditServiceRequestPaginationQuery {
      let auditServiceRequestPaginationQuery = new AuditServiceRequestPaginationQuery(query);
      auditServiceRequestPaginationQuery.pagination = new PaginationQuery(query.pagination);

      return auditServiceRequestPaginationQuery;
  }
}
