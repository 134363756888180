import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { AutocompleteUser, BlockUnblockUser, ChangeUserPassword, PaginatedUser, ProviderUser, RegisterUser, UpdateUser, User, UserForgotPassword } from '../models/user.models';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { UserPaginationQuery } from '../models/query-models/user-pagination-query.model';
import { PaginatedService } from './paginated.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends PaginatedService<PaginatedUser, UserPaginationQuery> {
  protected override handleSuccess(data: any): PaginationResult<PaginatedUser, UserPaginationQuery> {
    const result = data as PaginationResult<PaginatedUser, UserPaginationQuery>;
    const serviceRequests: PaginatedUser[] = result?.items.map(u => new PaginatedUser(u)) ?? [];
    const query: UserPaginationQuery = UserPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<PaginatedUser, UserPaginationQuery>(serviceRequests, result.totalItems, query);
  }

  protected override handleNullOrUndefined(): PaginationResult<PaginatedUser, UserPaginationQuery> {
    return new PaginationResult<PaginatedUser, UserPaginationQuery>([], 0, new UserPaginationQuery(null, null, null));
  }

  constructor(private storageService: StorageService) {
    super(storageService)
  }

  async getUserById(id: string): Promise<User>  {
    const { data, error } = await this.authenticatedClient.GET("/User/GetUserById/{id}", {
      params: {
        path: {id: id}
      }
    });

    const user = new User(data?.user.id!, data?.user.firstName!, data?.user.lastName!, data?.user.email!, data?.user.status!, data?.user.serviceCoordinatorGroup!, data?.user.roles!, data?.user.serviceCategories!, data?.user.providerAgency!)

    return user;
  }

  async getUsers(): Promise<PaginationResult<PaginatedUser, UserPaginationQuery>> {
    const pagination = UserPaginationQuery.create({
      email: "",
      userRoles: "",
      status: "",
      pagination: {
        page: 0,
        pageSize: 25,
        sortAscending: true,
        sortField: "Id"
      }
    });

    const { data, error } = await this.authenticatedClient.POST("/User/GetUsers", {
      body: pagination,
    });

    if (data !== null && data !== undefined) {
      const users: PaginatedUser[] = data?.items.map(u => new PaginatedUser(u)) ?? [];
      const query: UserPaginationQuery = UserPaginationQuery.create(data.queryExecuted);

      return new PaginationResult<PaginatedUser, UserPaginationQuery>(users, data.totalItems, query);
    } else {
      return new PaginationResult<PaginatedUser, UserPaginationQuery>([], 0, new UserPaginationQuery("", "", ""));
    }
  }

  async updateUser(id: string,
    firstName: string,
    lastName: string,
    email: string,
    roleIds: string[],
    providerAgency: string,
    serviceCoordinatorGroup: string | null,
    serviceCategoryIds: string[] | null,
  ) {
      const user: UpdateUser = new UpdateUser(id, firstName, lastName, email, roleIds, providerAgency, serviceCoordinatorGroup, serviceCategoryIds ?? []);
      const { data, error } = await this.authenticatedClient.POST("/User/UpdateUser", { body: user });
  }

  async registerUser(email: string,
    password: string,
    firstName: string,
    lastName: string,
    roleIds: string[],
    providerAgency: string,
    serviceCoordinatorGroup: string | null,
    serviceCategoryIds: string[] | null
  ): Promise<void> {
    const user: RegisterUser = new RegisterUser(email, password, firstName, lastName, roleIds, providerAgency, serviceCoordinatorGroup, serviceCategoryIds ?? []);

    const { data, error } = await this.authenticatedClient.POST("/User/RegisterUser", { body: user });
  }

  async blockUser(id: string) {
    const user: BlockUnblockUser = new BlockUnblockUser(id);
    const { data, error } = await this.authenticatedClient.POST("/User/BlockUser", { body: user });
  }

  async unblockUser(id: string) {
    const user: BlockUnblockUser = new BlockUnblockUser(id);
    const { data, error } = await this.authenticatedClient.POST("/User/UnblockUser", { body: user });
  }

  async userForgotPassword(email: string) {
    const forgotPassword: UserForgotPassword = new UserForgotPassword(email);
    const {data, error } = await this.authenticatedClient.POST("/User/SendForgotPassword", { body: forgotPassword });
  }

  async changeUserPassword(id: string, password: string) {
    const changePassword: ChangeUserPassword = new ChangeUserPassword(id, password);
    const { data, error } = await this.authenticatedClient.POST("/User/ChangePassword", { body: changePassword});
  }

  async getProvidersWithCategories(categoryIds: string[]): Promise<ProviderUser[]> {
    const { data, error } = await this.authenticatedClient.POST("/User/GetProvidersWithCategories", { body: { categoryIds } });

    const users: ProviderUser[] = data?.items.map(u => new ProviderUser(u.id, u.firstName, u.lastName, u.email, u.providerAgency)) ?? [];
    return users;
  }

  async userHasRole(roleNames: string[]): Promise<boolean> {
    const { data, error } = await this.authenticatedClient.POST("/User/UserHasRole", { body: {roleNames}});
    return data?.hasAtLeastOneRole ?? false;
  }

  async autocompleteServiceSupportCoordinator(queryString: string): Promise<AutocompleteUser[]> {
    const {data, error } = await this.authenticatedClient.POST("/User/AutocompleteServiceSupportCoordinator", { body: { queryString } });

    const users: AutocompleteUser[] = data?.serviceSupportCoordinators.map(u => new AutocompleteUser(u.id, u.name)) ?? [];
    return users;
  }
}
