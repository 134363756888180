import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { AUTH_TOKEN } from '../constants/storage-key.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    storage!: StorageService;

    constructor(storageService: StorageService) {
      this.storage = storageService;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): 
    Observable<HttpEvent<any>> {
        const token = this.storage.getItem(AUTH_TOKEN);
        const cloneReq = req.clone({ headers: req.headers.append('Authorization', `Bearer ${token}`)});

        return next.handle(cloneReq);
    }
}