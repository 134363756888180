import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { loginForm } from '../../../utils/constants/form.constants';
import { Login } from '../../../models/login.model';
import { AuthService } from '../../../services/auth.service';
import { StorageService } from '../../../services/storage.service';
import { AUTH_TOKEN, REFRESH_TOKEN } from '../../../utils/constants/storage-key.constants';
import { RouterService } from '../../../services/router.service';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../../dialogs/forgot-password/forgot-password.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isTokenValid } from '../../../services/is-logged-in-auth-gurad.service';
import { UserService } from '../../../services/user.service';
import { ADMINISTRATOR, SERVICE_PROVIDER } from '../../../utils/constants/role.constants';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIcon
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  enteredIncorrectly = false;
  forgottenEmail: string = "";
  isLoading: boolean = false;
  passwordVisible: boolean = false;
  returnUrl: string | null = null;

  constructor (
    private authService: AuthService,
    private storageService: StorageService,
    private routerService: RouterService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.loginForm = loginForm;
  }

  async ngOnInit() {
    // Get the returnUrl from query parameters (if any)
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;

    const token = this.storageService.getItem(AUTH_TOKEN);

    if (token && isTokenValid(token)) {
      if (await this.userService.userHasRole([SERVICE_PROVIDER])) {
        this.routerService.goToProviderServiceRequestsScreen();
       } else if (await this.userService.userHasRole([ADMINISTRATOR])) {
         this.routerService.goToUsersScreen();
       } else {
         this.routerService.goToServiceRequestsScreen();
       }
    }
  }

  async onLoginClick() {
    this.isLoading = true;

    const loginValues: Login = this.loginForm.value as Login;

    const data = await this.authService.authenticateUser(loginValues);

    if (data != undefined) {
      await this.storageService.setItem(AUTH_TOKEN, data.token.accessToken);
      await this.storageService.setItem(REFRESH_TOKEN, data.token.refreshToken);

      if(this.returnUrl != null) {
        this.router.navigateByUrl(this.returnUrl);
      } else {
        window.location.reload();
      }
    } else {
      this.enteredIncorrectly = true;
    }

    this.isLoading = false;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ForgotPasswordComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      this.forgottenEmail = result;

      if (this.forgottenEmail != null && this.forgottenEmail != undefined && this.forgottenEmail.length > 0) {
        this._snackBar.open("If a user with this email exisits, an email to reset your password has been sent.", "Ok");
      }
    });
  }
}
