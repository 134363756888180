import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUpsertServiceRequestCommand } from '../../../../form-interfaces/upsert-service-request-form.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RouterService } from '../../../../services/router.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ServiceCategory } from '../../../../models/service-category.models';
import { ServiceCategoryService } from '../../../../services/service-category.service';
import { ServiceRequestService } from '../../../../services/service-request.service';
import { MatDividerModule } from '@angular/material/divider';
import { ServiceRequest } from '../../../../models/service-request.models';
import { ActivatedRoute } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import '../../../../utils/extensions/date-extensions';
import '../../../../utils/extensions/array-extensions';
import '../../../../utils/extensions/string-extensions';
import { Attachment } from '../../../../models/attachment.model';
import { ExcludeProvidersComponent } from "../../../shared/exclude-providers/exclude-providers.component";
import { ALLOWED_FILE_TYPES } from '../../../../utils/constants/allowed-file-types.constants';
import { MatIconModule } from '@angular/material/icon';
import { FileUploaderComponent } from "../../../shared/file-uploader/file-uploader.component";
import { AttachmentsListComponent } from "../../../shared/attachments-list/attachments-list.component";
import { ExcludedProvider } from '../../../../models/user.models';
import { UserService } from '../../../../services/user.service';
import { SERVICE_COORDINATOR, SERVICE_COORDINATOR_ADMINISTRATOR, SUPPORT_COORDINATOR } from '../../../../utils/constants/role.constants';
import { ServiceRequestTypes } from '../../../../utils/constants/service-request-type.constants';
import { MatDialog } from '@angular/material/dialog';
import { ExclusionReasonsComponent } from '../../../dialogs/exclusion-reasons/exclusion-reasons/exclusion-reasons.component';
import { DURATION_UNTIS } from '../../../../utils/constants/duration-unit.constants';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-upsert-service-request',
  standalone: true,
  templateUrl: './upsert-service-request.component.html',
  styleUrl: './upsert-service-request.component.scss',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDividerModule,
    DragDropModule,
    ExcludeProvidersComponent,
    MatIconModule,
    FileUploaderComponent,
    AttachmentsListComponent
  ]
})
export class UpsertServiceRequestComponent implements OnInit {
  serviceCategories: ServiceCategory[] = [];
  upsertServiceCategoryForm!: FormGroup<IUpsertServiceRequestCommand>;
  newAttachments: File[] = [];
  currentAttachments: Attachment[] = [];
  providerIds: string[] = [];
  excludedProviders: ExcludedProvider[] = [];
  providerExcludeNeedsFiltered: boolean = false;
  allowedFileTypes = ALLOWED_FILE_TYPES;
  serviceRequestId: string | null = null;
  isDraft: boolean = true;
  durationUnits = DURATION_UNTIS;
  hasAtLeastOneProvider: boolean = false;

  constructor(
    public routerService: RouterService,
    private serviceCategoryService: ServiceCategoryService,
    private serviceRequestService: ServiceRequestService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    this.serviceCategories = await this.serviceCategoryService.getServiceCategory();

    this.activatedRoute.queryParams.subscribe(async params => {
      const serviceRequestId = params["id"];
      this.serviceRequestId = serviceRequestId;

      if (serviceRequestId !== null && serviceRequestId !== undefined) {
        this.providerExcludeNeedsFiltered = true;
        const serviceRequest: ServiceRequest = await this.serviceRequestService.getServiceRequestById(serviceRequestId);
        this.upsertServiceCategoryForm = this.getUpsertServiceRequestForm(serviceRequest);
        this.upsertServiceCategoryForm.controls.serviceCategoryIds.disable();

        if(serviceRequest.status.toLocaleLowerCase() !== 'draft') {
          this.upsertServiceCategoryForm.disable()
          this.isDraft = false;
        }
      } else {
        this.upsertServiceCategoryForm = this.getUpsertServiceRequestForm(null);
      }
    });
  }

  get serviceCategoriesSelected(): string[] {
    return this.upsertServiceCategoryForm?.controls.serviceCategoryIds.value ?? [];
  }

  updateExcludedProviders(excludedProvider: ExcludedProvider) {
    let providersToExclude = this.upsertServiceCategoryForm?.controls.provdersToExclude.value;
    const index = providersToExclude.findIndex(p => p.providerId == excludedProvider.providerId);

    if (index != -1) {
      providersToExclude.splice(index, 1)
    } else {
      providersToExclude.push(new ExcludedProvider(excludedProvider.providerId, excludedProvider.providerFirstName, excludedProvider.providerLastName, excludedProvider.exclusionReason));
    }

    this.upsertServiceCategoryForm?.controls.provdersToExclude.setValue(providersToExclude);
  }

  selectedProviderCountChange(hasAtLeastOneProvider: boolean) {
    this.hasAtLeastOneProvider = hasAtLeastOneProvider;
  }

  removeFile(file: File) {
    const indexToRemove = this.newAttachments.indexOf(file);
    this.newAttachments.splice(indexToRemove, 1);
  }

  deleteAttachment(attachment: Attachment) {
    const indexToRemove = this.currentAttachments.indexOf(attachment);
    this.currentAttachments.splice(indexToRemove, 1);
  }

  getUpsertServiceRequestForm(serviceRequest: ServiceRequest | null): FormGroup<IUpsertServiceRequestCommand> {
    const serviceCategoryIds: string[] = serviceRequest?.getServiceCategoriesIds() ?? [];
    const todaysDate: Date = new Date();
    this.providerIds = serviceRequest?.getProviderIds() ?? [];
    this.excludedProviders = serviceRequest?.excludedProviders ?? [];

    this.currentAttachments = serviceRequest?.attachments ?? [];

    return new FormGroup<IUpsertServiceRequestCommand>({
      id: new FormControl<string | null>(serviceRequest?.id ?? null, {
        nonNullable: false
      }),
      firstName: new FormControl<string>(serviceRequest?.firstName ?? "", {
        nonNullable: true,
        validators: [Validators.required]
      }),
      lastName: new FormControl<string>(serviceRequest?.lastName ?? "", {
        nonNullable: true,
        validators: [Validators.required]
      }),
      middleName: new FormControl<string | null>(serviceRequest?.middleName ?? "", {
        nonNullable: false,
      }),
      mci: new FormControl<string>(serviceRequest?.mci ?? "", {
        nonNullable: true,
        validators: [Validators.required]
      }),
      comments: new FormControl<string>(serviceRequest?.comments ?? "", {
        nonNullable: false,
      }),
      providerInterestResponseDate: new FormControl<Date>(
        serviceRequest?.providerInterestResponseDate !== null && serviceRequest?.providerInterestResponseDate !== undefined ?
          new Date(serviceRequest?.providerInterestResponseDate) :
          todaysDate.addDays(15), {
        nonNullable: true,
        validators: [Validators.required]
      }),
      providerProposalResponseDate: new FormControl<Date>(
        serviceRequest?.providerProposalResponseDate !== null && serviceRequest?.providerProposalResponseDate !== undefined ?
          new Date(serviceRequest?.providerProposalResponseDate) :
          todaysDate.addDays(30), {
        nonNullable: true,
        validators: [Validators.required]
      }),
      serviceCategoryIds: new FormControl<string[]>(serviceCategoryIds, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      frequencyAndDuration: new FormControl<string>(serviceRequest?.frequencyAndDuration ?? "", {
        nonNullable: true,
        validators: [Validators.required]
      }),
      serviceLocation: new FormControl<string | null>(serviceRequest?.serviceLocation ?? "", {
        nonNullable: false,
      }),
      preferredStartDate: new FormControl<Date | null>(
        serviceRequest?.preferredStartDate !== null && serviceRequest?.preferredStartDate !== undefined ?
          new Date(serviceRequest?.preferredStartDate) :
          null, {
        nonNullable: false,
      }),
      provdersToExclude: new FormControl<ExcludedProvider[]>([], {
        nonNullable: true
      })
    });
  }

  async save(notify: boolean): Promise<string[] | null> {
    let serviceRequestIds: string[] = [];

    if (this.upsertServiceCategoryForm.valid) {
      const value = this.upsertServiceCategoryForm.getRawValue();
      let serviceRequestId = value.id;

      if (serviceRequestId?.isNotNullEmptyOrUndefined()) {
        const response = await this.serviceRequestService.updateServiceRequest(serviceRequestId, value.firstName, value.lastName, value.middleName, value.mci, value.comments ?? "", value.providerInterestResponseDate.toISOString(), value.providerProposalResponseDate.toISOString(), value.serviceCategoryIds, value.preferredStartDate?.toISOString() ?? null, value.frequencyAndDuration, value.serviceLocation, value.provdersToExclude)
        
        if (response?.Message?.isNotNullEmptyOrUndefined()){
          this._snackBar.open(response.Message, "Ok");
          return null;
        }
        
        serviceRequestIds.push(serviceRequestId);
      } else {
        const response = await this.serviceRequestService.createServiceRequest(value.firstName, value.lastName, value.middleName, value.mci, value.comments ?? "", value.serviceLocation ?? "", value.frequencyAndDuration, value.preferredStartDate?.toISOString() ?? null, value.providerInterestResponseDate.toISOString(), value.providerProposalResponseDate.toISOString(), value.serviceCategoryIds, value.provdersToExclude);

        if (response?.Message?.isNotNullEmptyOrUndefined()){
          this._snackBar.open(response.Message, "Ok");
          return null;
        }

        serviceRequestIds = response;
      }

      if (this.newAttachments.length > 0) {
        for (const id of serviceRequestIds) {
          await this.serviceRequestService.uploadFiles(id!, this.newAttachments).subscribe(() => { });
        }
      }

      if (notify) {
        for (const id of serviceRequestIds) {
          await this.serviceRequestService.requestInterest(id!, "serivce-requests");
        }
      }

      this.routerService.goToServiceRequestsScreen();
    }

    return serviceRequestIds!;
  }

  async showExclusionReasons() {
    this.dialog.open(ExclusionReasonsComponent, {
      data: {
        providersToExclude: this.upsertServiceCategoryForm?.controls.provdersToExclude.value,
      }
    });
  }
}
