export interface IPaginationQuery {
    page: number;
    pageSize: number;
    sortAscending: boolean;
    sortField: string;
}

export class PaginationQuery {
    page: number;
    pageSize: number;
    sortAscending: boolean;
    sortField: string;

    constructor (paginationQuery: IPaginationQuery | null) {
        this.page = paginationQuery?.page ?? 0;
        this.pageSize = paginationQuery?.pageSize ?? 25;
        this.sortAscending = paginationQuery?.sortAscending ?? true;
        this.sortField = paginationQuery?.sortField ?? "Id";
    }
    
    static create(page: number, pageSize: number, sortAscending: boolean, sortField: string): PaginationQuery {
        const body: IPaginationQuery = {
            page: page,
            pageSize: pageSize,
            sortAscending: sortAscending,
            sortField: sortField
        }
        return new PaginationQuery(body);
    }
}

export interface IPaginationQueryBase {
    pagination: IPaginationQuery;
}

export class PaginationQueryBase {
    pagination: PaginationQuery;

    constructor () {
        this.pagination = new PaginationQuery(null);
    }
}