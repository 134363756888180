import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUpsertServiceCategoryCommand } from '../../../../form-interfaces/upsert-service-category-form.interface';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterService } from '../../../../services/router.service';
import { IUpsertServiceCoordinatorGroupCommand } from '../../../../form-interfaces/upsert-service-coordinator-group-form.interface';
import { ServiceCoordinatorGroupService } from '../../../../services/service-coordinator-group.service';

@Component({
  selector: 'app-upsert-service-coordinator-group',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDividerModule,
    CommonModule,
    MatButtonModule,
    MatInputModule,
    FormsModule, 
    ReactiveFormsModule
  ],
  templateUrl: './upsert-service-coordinator-group.component.html',
  styleUrl: './upsert-service-coordinator-group.component.scss'
})
export class UpsertServiceCoordinatorGroupComponent implements OnInit {
  upsertServiceCoordinatorGroupForm!: FormGroup<IUpsertServiceCoordinatorGroupCommand>;

  constructor(
    public activatedRoute: ActivatedRoute,
    private serviceCoordinatorGroupService: ServiceCoordinatorGroupService,
    private routerService: RouterService
  ){}
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async params => {
      const serviceCoordinatorGroupId = params["id"];
      const serviceCoordinatorGroupName = params["name"];

      this.upsertServiceCoordinatorGroupForm = this.getUpsertServiceCoordinatorGroupForm(serviceCoordinatorGroupId, serviceCoordinatorGroupName);
    });
  }

  getUpsertServiceCoordinatorGroupForm(serviceCoordinatorGroupId: string | null, serviceCoordinatorGroupName: string | null): FormGroup<IUpsertServiceCategoryCommand> {
    return new FormGroup<IUpsertServiceCategoryCommand>({
      id: new FormControl<string>(serviceCoordinatorGroupId ?? "", {
        nonNullable: false,
      }),
      name: new FormControl<string>(serviceCoordinatorGroupName ?? "", {
        nonNullable: true,
        validators: [Validators.required],
      })
    });
  }

  async onSaveClick() {
    if (this.upsertServiceCoordinatorGroupForm?.valid) {
      let value = this.upsertServiceCoordinatorGroupForm.getRawValue();
      
      if (value.id?.isNotNullEmptyOrUndefined()) {
        await this.serviceCoordinatorGroupService.updateServiceCoordinatorGroup(value.id!, value.name);
      } else {
        await this.serviceCoordinatorGroupService.createServiceCoordinatorGroup(value.name);
      }
      
      this.routerService.goToServiceCoordinatorGroupsScreen();
    }
  }

  onCancelClick() {
    this.upsertServiceCoordinatorGroupForm?.reset();
    this.routerService.goToServiceCoordinatorGroupsScreen();
  }
}
