import { Component, OnInit } from '@angular/core';
import { ServiceRequestService } from '../../../../services/service-request.service';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { RouterService } from '../../../../services/router.service';
import { PaginatedServiceRequestForProvider, ServiceRequest } from '../../../../models/service-request.models';
import { ProviderUser } from '../../../../models/user.models';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { PaginatedComponent } from '../../../../models/paginated.component';
import { ServiceRequestProvidersWithInterestPaginationQuery } from '../../../../models/query-models/service-request-pagination-query.model';
import { paths } from '../../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../../services/paginated.service';
import { ServiceRequestProvidersService } from '../../../../services/service-request-providers.servive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { ProviderCommentsComponent } from '../../../dialogs/provider-comments/provider-comments/provider-comments.component';
import { PrettyConstantPipe } from '../../../../utils/extensions/pretty-constant-pipe';
import { Attachment } from '../../../../models/attachment.model';
import { ProviderAttachmentsComponent } from '../../../dialogs/provider-attachments/provider-attachments.component';

@Component({
  selector: 'app-list-providers',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    PrettyConstantPipe
  ],
  templateUrl: './list-providers.component.html',
  styleUrl: './list-providers.component.scss'
})
export class ListProvidersComponent extends PaginatedComponent<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery> {
  serviceRequestId!: string;
  serviceRequest!: ServiceRequest;
  canBeClosed: boolean = false;

  override tblQuery: ServiceRequestProvidersWithInterestPaginationQuery = ServiceRequestProvidersWithInterestPaginationQuery.create({
    serviceRequestId: this.serviceRequestId,
      pagination: {
        page: 0,
        pageSize: 25,
        sortAscending: true,
        sortField: "Id"
      }
  });

  override buildQuery(): ServiceRequestProvidersWithInterestPaginationQuery {
    this.tblQuery.serviceRequestId = this.serviceRequestId;
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['providerAgency', 'email', 'categories', 'status', 'comments', 'acceptedReason', 'options'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceRequest/GetRequestsWithInterestedProvidersByServiceRequestId";
  }

  constructor (
    public serviceRequestProvidersService: ServiceRequestProvidersService,
    public serviceRequestService: ServiceRequestService,
    public activatedRoute: ActivatedRoute,
    public routerService: RouterService,
    private dialog: MatDialog,
  ) {
    super(serviceRequestProvidersService);
  }

  override async ngOnInit(): Promise<void> {
    this.activatedRoute.queryParams.subscribe(async params => {
      this.serviceRequestId = params["id"];
      this.serviceRequest = await this.serviceRequestService.getServiceRequestById(this.serviceRequestId);
      this.canBeClosed = this.serviceRequest.canBeClosed;

      this.serviceRequest.providers.forEach(provider => {
        if (provider.serviceRequestAttachments.length > 0) {
          this.dataSource.data.forEach(displayedProvider => {
            if (displayedProvider.id == provider.id) {
              displayedProvider.providerAttachments = provider.serviceRequestAttachments;
            }
          })
        }
      });
    });
  }

  requestProposalsClick(serviceRequestProviderId: string) {
    this.serviceRequestProvidersService.requestProposal(serviceRequestProviderId, "provider-service-requests");

    window.location.reload();
  }

  viewProposalClick(serviceRequestProviderId: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "serviceRequestId": this.serviceRequestId,
        "serviceRequestProviderId": serviceRequestProviderId
      }
    };

    this.routerService.goToViewProposalScreen(navigationExtras)
  }

  async selectProviderEarlyClick(serviceRequestProviderId: string) {
    await this.serviceRequestService.selectProviderWithoutProposal(serviceRequestProviderId, "Selected without proposal");
        
    window.location.reload();
  }

  viewAttachments(attachments: Attachment[]) {
    this.dialog.open(ProviderAttachmentsComponent, {
      data: {
        attachments: attachments
      }
    });
  }

  viewComments(interestComment: string, proposalComment: string) {
    this.dialog.open(ProviderCommentsComponent, {
      data: {
        interestComment: interestComment,
        proposalComment: proposalComment
      }
    });
  }

  async closeServiceRequestClick() {
    await this.serviceRequestService.closeServiceRequest(this.serviceRequestId);

    this.routerService.goToServiceRequestsScreen();
  }
}
