import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceCategory } from '../../../../models/service-category.models';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IUpsertServiceCategoryCommand } from '../../../../form-interfaces/upsert-service-category-form.interface';
import { ServiceCategoryService } from '../../../../services/service-category.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RouterService } from '../../../../services/router.service';

@Component({
  selector: 'app-upsert-service-category',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDividerModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
  ],
  templateUrl: './upsert-service-category.component.html',
  styleUrl: './upsert-service-category.component.scss'
})
export class UpsertServiceCategoryComponent implements OnInit {
  upsertServiceCategoryForm!: FormGroup<IUpsertServiceCategoryCommand>;

  constructor(
    public activatedRoute: ActivatedRoute,
    private serviceCategoryService: ServiceCategoryService,
    private routerService: RouterService
  ){}
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async params => {
      const serviceCategoryId = params["id"];

      const serviceCategory: ServiceCategory | null = await this.getServiceCategory(serviceCategoryId);
      this.upsertServiceCategoryForm = this.getUpsertServiceCategoryForm(serviceCategory);
    });
  }

  getUpsertServiceCategoryForm(serviceCategory: ServiceCategory | null): FormGroup<IUpsertServiceCategoryCommand> {
    return new FormGroup<IUpsertServiceCategoryCommand>({
      id: new FormControl<string>(serviceCategory?.id ?? "", {
        nonNullable: false,
      }),
      name: new FormControl<string>(serviceCategory?.name ?? "", {
        nonNullable: true,
        validators: [Validators.required],
      })
    });
  }

  async getServiceCategory(id: string | null): Promise<ServiceCategory | null> {
    if(id?.isNotNullEmptyOrUndefined()) {
      return await this.serviceCategoryService.getServiceCategoryById(id);
    }

    return null;
  }

  async onSaveClick() {
    if (this.upsertServiceCategoryForm?.valid) {
      let value = this.upsertServiceCategoryForm.getRawValue();
      
      if (value.id?.isNotNullEmptyOrUndefined()) {
        await this.serviceCategoryService.updateServiceCategory(value.id!, value.name);
      } else {
        await this.serviceCategoryService.createServiceCategory(value.name);
      }
      
      this.routerService.goToServiceCategoriesScreen();
    }
  }

  onCancelClick() {
    this.upsertServiceCategoryForm?.reset();
    this.routerService.goToServiceCategoriesScreen();
  }
}
