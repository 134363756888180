import { Component } from '@angular/core';
import { RouterService } from '../../../services/router.service';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { PaginatedUser } from '../../../models/user.models';
import { ConfirmationComponent } from '../../dialogs/confirmation/confirmation.component';
import { PaginatedComponent } from '../../../models/paginated.component';
import { UserPaginationQuery } from '../../../models/query-models/user-pagination-query.model';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Role } from '../../../models/role.model';
import { RoleService } from '../../../services/role.service';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChangePasswordDialogComponent } from '../../dialogs/change-password-dialog/change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'app-user',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.scss'
})
export class UsersComponent extends PaginatedComponent<PaginatedUser, UserPaginationQuery> {
  filterUserForm!: FormGroup;
  roles: Role[] = [];
  showingActive: boolean = true;

  override tblQuery: UserPaginationQuery = UserPaginationQuery.create({
    email: null,
    userRoles: null,
    status: "Active",
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: true,
      sortField: "Id",
    }
  })

  override buildQuery(): UserPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['name', 'email', 'roles', 'categories', 'providerAgency', 'status', 'lastLogin', 'options'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/User/GetUsers"
  }

  constructor(
    private routerService: RouterService,
    private userService: UserService,
    private roleService: RoleService,
    public dialog: MatDialog
  ) {
    super(userService)
  }

  override ngOnInit(): void {
    this.filterUserForm = new FormGroup({
      email: new FormControl<string>(""),
      roleNames: new FormControl<string>("")
    })

    this.getRoles();
  }

  async getRoles() {
    this.roles = await this.roleService.getRoles();
    this.roles = this.roles.filter(r => r.name != 'Site Maintainer');
  }

  onFilterClick() {
    this.tblQuery.email = this.filterUserForm.controls['email']?.value;
    this.tblQuery.userRoles = this.filterUserForm.controls['roleNames']?.value;

    this.getData(null);
  }

  editClick(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToUpsertUserScreen(navigationExtras);
  }

  createClick() {
    this.routerService.goToUpsertUserScreen(null);
  }

  async blockUserClick(id: string, firstName: string, lastName: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: "Are you sure?",
        question: "Block " + firstName + " " + lastName
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        await this.userService.blockUser(id);
        this.getData(null);
      }
    });
  }

  async unblockUserClick(id: string, firstName: string, lastName: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        title: "Are you sure?",
        question: "Unblock " + firstName + " " + lastName
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      await this.userService.unblockUser(id);
      this.getData(null);
    });
  }

  async changeUserPasswordClick(id: string) {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      data: {
        id: id
      },
      width: '45%'
    });
  }

  showBlockedClick() {
    this.tblQuery.status = "Blocked";
    this.showingActive = false;
    this.getData(null);
  }

  showActiveClick() {
    this.tblQuery.status = "Active";
    this.showingActive = true;
    this.getData(null);
  }
}
