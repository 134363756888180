import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { withdrawOptions } from '../../../utils/constants/provider-withdraw-options.constants';

@Component({
  selector: 'app-provider-withdrawl',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormFieldModule,
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  templateUrl: './provider-withdrawl.component.html',
  styleUrl: './provider-withdrawl.component.scss'
})

export class ProviderWithdrawlComponent implements OnInit {
  providerWithdrawlForm = new FormGroup({
    comments: new FormControl("", {
      nonNullable: false,
      validators: [Validators.required],
    }),
    openEnded: new FormControl("", {
      nonNullable: false,
    })
  });

  withdrawOptions = withdrawOptions;

  get otherOptionSelected(): boolean {
    const comment = this.providerWithdrawlForm?.controls.comments.value;
    if (comment == "Other; Please Specify") { 
      return true;
    } else {
      return false;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ProviderWithdrawlComponent>
  ) {}

  ngOnInit(): void {
    this.providerWithdrawlForm.controls['comments'].valueChanges.subscribe(value => {
      if (value == "Other; Please Specify") { 
        this.providerWithdrawlForm?.controls.openEnded.setValidators(
          Validators.required
        );
        this.providerWithdrawlForm?.controls.openEnded.updateValueAndValidity();
      } else {
        this.providerWithdrawlForm?.controls.openEnded.setValue(null);
        this.providerWithdrawlForm?.controls.openEnded.clearValidators();
        this.providerWithdrawlForm?.controls.openEnded.updateValueAndValidity();
      }
    });
  }

  okClick() {
    if (this.providerWithdrawlForm.valid) {
      if (this.otherOptionSelected) {
        this.dialogRef.close(this.providerWithdrawlForm.controls.openEnded.value);
      } else {
        this.dialogRef.close(this.providerWithdrawlForm.controls.comments.value);
      }
    }
  }
}
