import { IPaginationQuery, PaginationQuery, PaginationQueryBase } from "./pagination-query.model";

export interface IServiceCategoryPaginationQuery {
    name: string | null;
    status: string | null;
    pagination: IPaginationQuery;
}

export class ServiceCategoryPaginationQuery extends PaginationQueryBase {
    name: string | null;
    status: string | null;

    constructor (
        name: string | null,
        status: string | null
    ) {
        super();
        this.name = name;
        this.status = status;
    }

    static create(query: IServiceCategoryPaginationQuery): ServiceCategoryPaginationQuery {
        let serviceCategoryQuery =  new ServiceCategoryPaginationQuery(query.name ?? "", query.status ?? "");
        serviceCategoryQuery.pagination = new PaginationQuery(query.pagination);

        return serviceCategoryQuery;
    }
}