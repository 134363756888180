import { Component } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { PaginatedComponent } from '../../../models/paginated.component';
import { CoordinatorAdminServiceRequest } from '../../../models/coordinator-admin-service-request.model';
import { CoordinatorAdminServiceRequestPaginationQuery } from '../../../models/query-models/coordinator-admin-service-request-query.model';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterService } from '../../../services/router.service';
import { CoordinatorAdminServiceRequestsService } from '../../../services/coordinator-admin-service-requests.service';
import { NavigationExtras } from '@angular/router';
import { PrettyConstantPipe } from "../../../utils/extensions/pretty-constant-pipe";
import { jwtDecode } from 'jwt-decode';
import { FullJwtPayload } from '../../../utils/extensions/jwt-extensions';
import { StorageService } from '../../../services/storage.service';
import { AUTH_TOKEN } from '../../../utils/constants/storage-key.constants';
import { CancelServiceRequestComponent } from '../../dialogs/cancel-service-request/cancel-service-request.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AcceptedReasonsComponent } from '../../dialogs/accepted-reasons/accepted-reasons.component';

@Component({
  selector: 'app-coordinator-admin-service-requests',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    MatPaginatorModule,
    PrettyConstantPipe,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
],
  templateUrl: './coordinator-admin-service-requests.component.html',
  styleUrl: './coordinator-admin-service-requests.component.scss'
})
export class CoordinatorAdminServiceRequestsComponent extends PaginatedComponent<CoordinatorAdminServiceRequest, CoordinatorAdminServiceRequestPaginationQuery> {
  filterServiceRequestForm!: FormGroup;
  
  override tblQuery: CoordinatorAdminServiceRequestPaginationQuery = CoordinatorAdminServiceRequestPaginationQuery.create({
    requestNumber: null,
    status: null,
    serviceRequestType: null,
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: false,
      sortField: "createdDate"
    }
  });

  coordinatorGroup!: string;

  constructor(private coordinatorAdminServiceRequestService: CoordinatorAdminServiceRequestsService, private routerService: RouterService, private storageService: StorageService, private dialog: MatDialog) {
    super(coordinatorAdminServiceRequestService)
  }

  override buildQuery(): CoordinatorAdminServiceRequestPaginationQuery {
    return this.tblQuery;
  }
  override buildColumnHeaders(): string[] {
    return ['serviceRequestNumber', 'createdDate', 'mci', 'firstName', 'lastName', 'service', 'selectedProviders', 'selectedProvidersReason', 'status', 'createdByUser', 'options'];
  }
  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceRequest/GetServiceRequestsForGroup"
  }

  override ngOnInit(): void {
    var token = this.storageService.getItem(AUTH_TOKEN)!;
    this.coordinatorGroup = jwtDecode<FullJwtPayload>(token).coordinatorGroup;

    this.filterServiceRequestForm = new FormGroup({
      serviceRequestNumber: new FormControl<number | null>(null)
    });
  }

  onFilterClick() {
    this.tblQuery.requestNumber = this.filterServiceRequestForm.controls['serviceRequestNumber']?.value;

    if (this.tblQuery.requestNumber?.toString() == "") {
      this.tblQuery.requestNumber = null;
    }

    this.getData(null);
  }

  createClick() {
    this.routerService.goToUpsertServiceRequestScreen(null);
  }

  editClick(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToUpsertServiceRequestScreen(navigationExtras);
  }

  viewProvidersClick(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToServiceRequestProviderList(navigationExtras);
  }
  
  async cancelServiceRequestClick(id: string) {
    const dialogRef = this.dialog.open(CancelServiceRequestComponent, {});

    dialogRef.afterClosed().subscribe(async result => {
      if (result !== null && result !== undefined) {
        await this.coordinatorAdminServiceRequestService.cancelServiceRequest(id, result);

        window.location.reload();
      }
    });    
  }

  viewAuditTable(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id
      }
    };

    this.routerService.goToServiceRequestAuditTable(navigationExtras);
  }

  viewAcceptedReason(providers: any) {
    this.dialog.open(AcceptedReasonsComponent, {
      data: {
        providers: providers
      }
    });
  }

  getFirstSelectedProvider (providers: any): string {
    let providerName = "";

    for (const provider of providers) {
      if (provider.status === 7) {
        providerName = provider.provider.providerAgency + "...";
        break;
      }
    }

    return providerName;
  }

  getFirstSelectedProviderAcceptedReason (providers: any): string {
    let acceptedReason = "";

    for (const provider of providers) {
      if (provider.status === 7) {
        if (provider.acceptedReason != null) {
          acceptedReason = provider.acceptedReason + "...";
        }
        break;
      }
    }

    return acceptedReason;
  }
}
