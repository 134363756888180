import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { routeDefinitions } from '../app.routes';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  constructor(private router: Router) { }

  getCurrentRoute() {
    const currentRoutePath = this.router.url.replace(/^\//, '');
    return Object.values(routeDefinitions).find((route) => {
      return route.path === currentRoutePath;
    });
  }

  async goToLoginScreen() {
    return this.router.navigate([`/${routeDefinitions.login.path}`]);
  }

  async goToUsersScreen() {
    return this.router.navigate([`/${routeDefinitions.users.path}`]);
  }

  async goToUpsertUserScreen(navigationExtras: NavigationExtras | null) {
    if (navigationExtras != null && navigationExtras != undefined) {
      return this.router.navigate([`/${routeDefinitions.upsertUser.path}`], navigationExtras);
    } else {
      return this.router.navigate([`/${routeDefinitions.upsertUser.path}`]);
    }
  }

  async goToServiceRequestsScreen() {
    return this.router.navigate([`/${routeDefinitions.serviceRequests.path}`]);
  }

  async goToAdminServiceRequestsScreen() {
    return this.router.navigate([`/${routeDefinitions.adminServiceRequests.path}`]);
  }

  async goToUpsertServiceRequestScreen(navigationExtras: NavigationExtras | null) {
    if (navigationExtras != null && navigationExtras != undefined) {
      return this.router.navigate([`/${routeDefinitions.upsertServiceRequest.path}`], navigationExtras);
    } else {
      return this.router.navigate([`/${routeDefinitions.upsertServiceRequest.path}`]);
    }
  }

  async goToServiceCategoriesScreen() {
    this.router.navigate([`/${routeDefinitions.serviceCategories.path}`]);
  }

  async goToUpsertServiceCategoryScreen(navigationExtras: NavigationExtras | null) {
    if (navigationExtras != null && navigationExtras != undefined) {
      return this.router.navigate([`/${routeDefinitions.upsertServiceCategory.path}`], navigationExtras);
    } else {
      return this.router.navigate([`/${routeDefinitions.upsertServiceCategory.path}`]);
    }
  }

  async goToServiceCoordinatorGroupsScreen() {
    this.router.navigate([`/${routeDefinitions.serviceCoordinatorGroups.path}`]);
  }

  async goToUpsertServiceCoordinatorGroupScreen(navigationExtras: NavigationExtras | null) {
    if (navigationExtras != null && navigationExtras != undefined) {
      return this.router.navigate([`/${routeDefinitions.upsertServiceCoordinatorGroup.path}`], navigationExtras);
    } else {
      return this.router.navigate([`/${routeDefinitions.upsertServiceCoordinatorGroup.path}`]);
    }
  }

  async goToProviderServiceRequestsScreen() {
    return this.router.navigate([`/${routeDefinitions.providerServiceRequests.path}`]);
  }

  async goToCoordinatorAdminServiceRequestsScreen() {
    return this.router.navigate([`/${routeDefinitions.coordinatorAdminServiceRequests.path}`]);
  }

  async goToProviderInterestViewServiceRequestScreen(navigationExtras: NavigationExtras | null) {
    if (navigationExtras != null && navigationExtras != undefined) {
      return this.router.navigate([`/${routeDefinitions.providerInterestViewServiceRequests.path}`], navigationExtras);
    } else {
      return this.router.navigate([`/${routeDefinitions.providerInterestViewServiceRequests.path}`]);
    }
  }

  async goToServiceRequestProviderList(navigationExtras: NavigationExtras) {
    return this.router.navigate([`/${routeDefinitions.serviceRequestProviderList.path}`], navigationExtras);
  }

  async goToServiceRquestReport() {
    return this.router.navigate([`/${routeDefinitions.serviceRequestsReport.path}`]);
  }
  
  async goToServiceRequestAuditTable(navigationExtras: NavigationExtras) {
    return this.router.navigate([`/${routeDefinitions.auditServiceRequest.path}`], navigationExtras);
  }

  async goToProviderCreateProposalScreen(navigationExtras: NavigationExtras) {
    return this.router.navigate([`/${routeDefinitions.providerCreateProposal.path}`], navigationExtras);
  }

  async goToViewProposalScreen(navigationExtras: NavigationExtras) {
    return this.router.navigate([`/${routeDefinitions.serviceRequestProposalView.path}`], navigationExtras);
  }
}
