export class ServiceCoordinatorGroupPrettyName {
    name: string;
    nameNoSpace: string;

    constructor(
        name: string,
        nameNoSpace: string    
    ){
        this.name = name;
        this.nameNoSpace = nameNoSpace;
    }
}

export interface IPaginatedServiceCategory {
    id: string;
    name: string;
    status: string;
}

export class PaginatedServiceCategory {
    id: string;
    name: string;
    status: string;

    constructor(serviceCategory: IPaginatedServiceCategory) {
        this.id = serviceCategory.id;
        this.name = serviceCategory.name;
        this.status = serviceCategory.status;
    }
}