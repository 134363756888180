import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { PaginatedService } from './paginated.service';
import { ServiceRequestReportPaginationQuery } from '../models/query-models/service-request-report-pagination-query.model';
import { PaginatedServiceRequestReport } from '../models/service-request-report.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
@Injectable({
    providedIn: 'root'
  })

export class ServiceRequestReportService extends PaginatedService<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery> {
    baseUrl = environment.API
    constructor(private storageService: StorageService, private httpClient: HttpClient) {
        super(storageService)
    }

    protected override handleSuccess(data: any): PaginationResult<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery> {
        const result = data as PaginationResult<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery>;
        const serviceRequestReport: PaginatedServiceRequestReport[] = result?.items.map(sr => new PaginatedServiceRequestReport(
            sr.id,
            sr.serviceRequestId,
            sr.status,
            sr.serviceRequestServiceRequestNumber,
            sr.serviceRequestFirstName,
            sr.serviceRequestMiddleName,
            sr.serviceRequestLastName,
            sr.serviceRequestMci,
            sr.categories,
            sr.serviceRequestStatus,
            sr.providerEmail,
            sr.providerFirstName,
            sr.providerLastName,
            sr.providerAgency,
            sr.serviceRequestRequestType,
            sr.serviceRequestCreatedAt,
            sr.serviceRequestUpdatedAt,
            sr.serviceRequestComments
        )) ?? [];
        const query: ServiceRequestReportPaginationQuery = ServiceRequestReportPaginationQuery.create(result.queryExecuted);

        return new PaginationResult<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery>(serviceRequestReport, result.totalItems, query);
    }

    protected override handleNullOrUndefined(): PaginationResult<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery> {
        return new PaginationResult<PaginatedServiceRequestReport, ServiceRequestReportPaginationQuery>([], 0, new ServiceRequestReportPaginationQuery(null));
    }

    exportReport(query: ServiceRequestReportPaginationQuery) {
        this.httpClient.post(this.baseUrl + "/ServiceRequest/ExportServiceRequestsReportCSV", query, {"responseType": "blob"}).subscribe(response => {
            const data = window.URL.createObjectURL(response);
            const link = document.createElement("a");
            link.href = data;
            link.download = "ServiceRequestsReport.csv";
            link.click();
        });
    }
}
