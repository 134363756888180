import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormFieldModule,
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  forgotPasswordForm = new FormGroup({
    email: new FormControl("", {
      nonNullable: true,
      validators: [Validators.required, Validators.email],
    })
  });

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private userService: UserService
  ) {}

  async okClick() {
    if (this.forgotPasswordForm.valid) {
      this.userService.userForgotPassword(this.forgotPasswordForm.controls['email'].value)

      this.dialogRef.close(this.forgotPasswordForm.controls.email.value);
    }
  }
}
