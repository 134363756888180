import { PaginationQueryBase } from "./pagination-query.model";

export class PaginationResult<TItemList, TQuery extends PaginationQueryBase> {
    items: TItemList[];
    totalItems: number;
    queryExecuted: TQuery;

    constructor (
        items: TItemList[],
        totalItems: number,
        queryExecuted: TQuery
    ) {
        this.items = items;
        this.totalItems = totalItems;
        this.queryExecuted = queryExecuted;
    }
}