import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { providerExclusionReasons } from '../../../../utils/constants/provider-exclusion-reasons.constants';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-provider-exclude',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormFieldModule,
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  templateUrl: './provider-exclude.component.html',
  styleUrl: './provider-exclude.component.scss'
})
export class ProviderExcludeComponent implements OnInit {
  providerExcludeForm = new FormGroup({
    comments: new FormControl("", {
      nonNullable: false,
      validators: [Validators.required],
    }),
    openEnded: new FormControl("", {
      nonNullable: false,
    })
  });

  exclusionReasons = providerExclusionReasons;

  get otherOptionSelected(): boolean {
    const comment = this.providerExcludeForm?.controls.comments.value;
    if (comment == "Other; Please Specify") { 
      return true;
    } else {
      return false;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<ProviderExcludeComponent>
  ) {}

  ngOnInit(): void {
    this.providerExcludeForm.controls['comments'].valueChanges.subscribe(value => {
      if (value == "Other; Please Specify") { 
        this.providerExcludeForm?.controls.openEnded.setValidators(
          Validators.required
        );
        this.providerExcludeForm?.controls.openEnded.updateValueAndValidity();
      } else {
        this.providerExcludeForm?.controls.openEnded.setValue(null);
        this.providerExcludeForm?.controls.openEnded.clearValidators();
        this.providerExcludeForm?.controls.openEnded.updateValueAndValidity();
      }
    });
  }

  okClick() {
    if (this.providerExcludeForm.valid) {
      if (this.otherOptionSelected) {
        this.dialogRef.close(this.providerExcludeForm.controls.openEnded.value);
      } else {
        this.dialogRef.close(this.providerExcludeForm.controls.comments.value);
      }
    }
  }

  cancelClick() {
    this.dialogRef.close(false);
  }
}
