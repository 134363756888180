import { Injectable } from "@angular/core";
import { PaginatedService } from "./paginated.service";
import { PaginatedServiceRequestForProvider } from "../models/service-request.models";
import { ServiceRequestProvidersWithInterestPaginationQuery } from "../models/query-models/service-request-pagination-query.model";
import { PaginationResult } from "../models/query-models/pagination-result.model";

@Injectable({
    providedIn: 'root'
  })
  export class ServiceRequestProvidersService extends PaginatedService<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery> {
    protected override handleSuccess(data: any): PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery> {
        const result = data as PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery>;
        const serviceRequests: PaginatedServiceRequestForProvider[] = result?.items.map(sr => new PaginatedServiceRequestForProvider(sr)) ?? [];
        const query: ServiceRequestProvidersWithInterestPaginationQuery = ServiceRequestProvidersWithInterestPaginationQuery.create(result.queryExecuted);
    
        return new PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery>(serviceRequests, result.totalItems, query);
    }

    protected override handleNullOrUndefined(): PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery> {
        return new PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestProvidersWithInterestPaginationQuery>([], 0, new ServiceRequestProvidersWithInterestPaginationQuery(null));
    }

    requestProposal(id: string, routeForEmail: string) {
      this.authenticatedClient.POST("/ServiceRequest/RequestProposal", { body: { "serviceRequestProviderId": id, "routeForEmail": routeForEmail}});
    }
  }