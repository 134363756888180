import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../../../services/router.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ICreateProposalCommand } from '../../../../form-interfaces/create-proposal-form.interface';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { DURATION_UNTIS } from '../../../../utils/constants/duration-unit.constants';
import { ServiceRequestService } from '../../../../services/service-request.service';
import { ServiceRequest } from '../../../../models/service-request.models';
import { FileService } from '../../../../services/file.service';
import { AttachmentsListComponent } from '../../../shared/attachments-list/attachments-list.component';
import { FileUploaderComponent } from '../../../shared/file-uploader/file-uploader.component';
import { Attachment } from '../../../../models/attachment.model';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-proposal',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule,
    MatSelectModule,
    FileUploaderComponent,
    AttachmentsListComponent,
  ],
  templateUrl: './create-proposal.component.html',
  styleUrl: './create-proposal.component.scss'
})
export class CreateProposalComponent implements OnInit {
  createProposalForm!: FormGroup<ICreateProposalCommand>;
  durationUnits = DURATION_UNTIS;
  serviceRequest!: ServiceRequest;
  showName: boolean = false;
  newAttachments: File[] = [];
  
  providerAttachments: Attachment[] = [];
  serviceRequestAttachments: Attachment[] = [];

  constructor(
    public routerService: RouterService,
    private activatedRoute: ActivatedRoute,
    private serviceRequestService: ServiceRequestService,
    private fileService: FileService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      const serviceRequestProviderId = params["serviceRequestProviderId"];
      const serviceRequestId = params["serviceRequestId"];

      this.serviceRequest = await this.serviceRequestService.getServiceRequestById(serviceRequestId);
      this.showName = this.serviceRequest.requestType == "IDA";

      this. serviceRequestAttachments = this.serviceRequest.attachments;
      this.serviceRequest.providers.forEach(provder => {
        this.providerAttachments = provder.serviceRequestAttachments;
      })

      this.createProposalForm = this.getCreateProposalForm(serviceRequestProviderId);

      this.createProposalForm.controls.proposedAction.disable();
    });
  }

  getCreateProposalForm(serviceRequestProposalId: string): FormGroup<ICreateProposalCommand> {
    return new FormGroup<ICreateProposalCommand>({
      serviceRequestProviderId: new FormControl<string>(serviceRequestProposalId, {
        nonNullable: true
      }),
      firstName: new FormControl<string>(this.serviceRequest?.firstName, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      lastName: new FormControl<string>(this.serviceRequest?.lastName, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      startDate: new FormControl<Date | null>(
        this.serviceRequest?.preferredStartDate !== null && this.serviceRequest?.preferredStartDate !== undefined ?
          new Date(this.serviceRequest?.preferredStartDate) : null, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      frequencyAndDuration: new FormControl<string>(this.serviceRequest?.frequencyAndDuration, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      serviceLocation: new FormControl<string>(this.serviceRequest.serviceLocation, {
        nonNullable: true,
        validators: [Validators.required]
      }),
      proposedAction: new FormControl<string>(this.serviceRequest.categories[0]?.name ?? "", {
        nonNullable: true,
        validators: [Validators.required]
      }),
      comments: new FormControl<string>("", {
        nonNullable: true,
      }),
      routeForEmail: new FormControl<string>("provider-service-requests", {
        nonNullable: true,
        validators: [Validators.required]
      })
    });
  }

  removeFile(file: File) {
    const indexToRemove = this.newAttachments.indexOf(file);
    this.newAttachments.splice(indexToRemove, 1);
  }

  async save() {
    if (this.createProposalForm.valid) {
      const value = this.createProposalForm.getRawValue();
      
      const response = await this.serviceRequestService.createProposal(value.serviceRequestProviderId, value.firstName, value.lastName, value.startDate!.toISOString(), value.frequencyAndDuration, value.serviceLocation, value.proposedAction, value.comments, value.routeForEmail);
       
      if (response?.Message?.isNotNullEmptyOrUndefined()){
        this._snackBar.open("Unable to create proposal at this time. Try again later or contact the coordinator.", "Ok");
        return;
      }
        

      if (this.newAttachments.length > 0) {
        await this.fileService.uploadProviderAttachment(value.serviceRequestProviderId, this.newAttachments).subscribe(() => { });
      }
      
      this.routerService.goToProviderServiceRequestsScreen();
    }
  }
}
