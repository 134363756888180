<div class="flex justify-evenly">
  <div
    class="relative flex flex-col outline-stone-300 outline-2 -outline-offset-4 outline-dashed w-full h-[250px] rounded-2xl items-center justify-center"
  >
    <input
      #fileInput
      type="file"
      (change)="uploadFile($event)"
      [accept]="allowedFileTypes"
      class="absolute w-full h-full cursor-pointer opacity-0 top-0 left-0"
      [disabled]="disabled"
    />

    <span class="mb-6">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        class="w-10 h-10"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
        />
      </svg>
    </span>

    <p class="text-center text-base max-w-[90%]">
      Drag and drop
      <br />
      file here to upload.
      <br />
      (.txt, .docx, .pdf)
    </p>

    <button
      type="button"
      mat-flat-button
      color="accent"
      (click)="fileInput.click()"
      [disabled]="disabled"
    >
      Browse Files
    </button>
  </div>
</div>
