@if (upsertUserForm !== undefined) {
  <form
    [formGroup]="upsertUserForm"
    class="flex flex-col items-center justify-center mx-10"
    (ngSubmit)="onSaveClick()"
  >
    <div class="flex-row w-3/5 justify-start m-1">
      <h3 class="mb-0">{{ pageTitle }}</h3>
      <mat-divider class="w-full mb-1"></mat-divider>
    </div>

    <div class="flex flex-col justify-center w-3/5 m-1">
      <div class="m-1 border-solid border-2 border-stone-300 rounded">
        <p class="m-1 text-gray-400">Basic Info</p>
        <mat-divider class="mx-1"></mat-divider>

        <div class="flex justify-evenly">
          <mat-form-field class="m-1 flex-grow">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" />
          </mat-form-field>

          <mat-form-field class="m-1 flex-grow">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" />
          </mat-form-field>
        </div>

        <div class="flex justify-evenly">
          <mat-form-field class="m-1 flex-grow">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            @if (upsertUserForm.controls.email.invalid) {
              <mat-error>{{ emailError }}</mat-error>
            }
          </mat-form-field>

          @if (
            upsertUserForm.controls.id === null ||
            upsertUserForm.controls.id === undefined ||
            upsertUserForm.controls.id.value === ""
          ) {
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Password</mat-label>
              <input [type]="'password'" matInput formControlName="password" />
              @if (upsertUserForm.controls.password.invalid) {
                <mat-error>{{ passwordError }}</mat-error>
              }
            </mat-form-field>
          }
        </div>
      </div>

      <div class="m-1 border-solid border-2 border-stone-300 rounded">
        <p class="m-1 text-gray-400">Administration</p>
        <mat-divider class="mx-1"></mat-divider>

        <div class="flex justify-evenly">
          <mat-form-field class="m-1 flex-grow">
            <mat-label>Roles</mat-label>
            <mat-select formControlName="roleIds" multiple>
              @for (role of roles; track role) {
                @if (role.name !== "Site Maintainer") {
                  <mat-option
                    [value]="role.id"
                    [disabled]="disableRoleSelection(role.id)"
                  >
                    {{ role.name }}
                  </mat-option>
                }
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex justify-evenly">
          @if (serviceCategoriesVisible) {
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Service Categories</mat-label>
              <mat-select formControlName="serviceCategoryIds" multiple>
                @for (
                  serviceCategory of serviceCategories;
                  track serviceCategory
                ) {
                  <mat-option [value]="serviceCategory.id">
                    {{ serviceCategory.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field class="m-1 flex-grow">
              <mat-label>Provider Agency Name</mat-label>
              <input matInput formControlName="providerAgency" />
            </mat-form-field>
          }

          @if (serviceCoordinatorGroupVisible) {
            <mat-form-field class="m-1 flex-grow">
              <mat-label>Service Coordinator Group</mat-label>
              <mat-select formControlName="serviceCoordinatorGroup">
                @for (
                  serviceCoordinatorGroup of serviceCoordinatorGroups;
                  track serviceCoordinatorGroup
                ) {
                  <mat-option [value]="serviceCoordinatorGroup.id">
                    {{ serviceCoordinatorGroup.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </div>
      </div>
    </div>

    <div class="flex flex-row-reverse w-3/5 m-1 gap-2">
      <button mat-flat-button color="primary" type="submit" [disabled]="isSaving">Save</button>

      <button mat-flat-button color="accent" type="button" (click)="onCancelClick()">
        Cancel
      </button>
    </div>
  </form>
}
