export class ServiceRequestStatusModel {
    name: string;
    nameNoSpace: string;

    constructor(
        name: string,
        nameNoSpace: string    
    ){
        this.name = name;
        this.nameNoSpace = nameNoSpace;
    }
}