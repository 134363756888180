import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterService } from '../../services/router.service';
import { MatListModule } from '@angular/material/list';
import { AuthService } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UserService } from '../../services/user.service';
import { ADMINISTRATOR, SERVICE_COORDINATOR, SERVICE_COORDINATOR_ADMINISTRATOR, SERVICE_PROVIDER, SUPPORT_COORDINATOR, SUPPORT_COORDINATOR_ADMINISTRATOR } from '../../utils/constants/role.constants';


interface INavLink {
  icon: string;
  tooltipText: string;
  click: () => void;
  isVisible: boolean;
}

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent implements OnInit {
  navLinks: INavLink[] = []


  constructor(
    private routerService: RouterService,
    private authService: AuthService,
    private userService: UserService
  ) { }


  async ngOnInit(): Promise<void> {
    this.navLinks = [
      {
        icon: 'manage_accounts',
        tooltipText: 'Users',
        click: () => this.routerService.goToUsersScreen(),
        isVisible: await this.userService.userHasRole([ADMINISTRATOR])
      },
      {
        icon: 'list',
        tooltipText: 'Service Categories',
        click: () => this.routerService.goToServiceCategoriesScreen(),
        isVisible: await this.userService.userHasRole([ADMINISTRATOR])
      },
      {
        icon: 'group',
        tooltipText: 'Service Coordinator Groups',
        click: () => this.routerService.goToServiceCoordinatorGroupsScreen(),
        isVisible: await this.userService.userHasRole([ADMINISTRATOR])
      },
      {
        icon: 'apps',
        tooltipText: 'Service Requests',
        click: () => this.routerService.goToServiceRequestsScreen(),
        isVisible: await this.userService.userHasRole([SERVICE_COORDINATOR, SERVICE_COORDINATOR_ADMINISTRATOR, SUPPORT_COORDINATOR, SUPPORT_COORDINATOR_ADMINISTRATOR])
      },
      {
        icon: 'apps',
        tooltipText: 'Administrator View of Service Requests',
        click: () => this.routerService.goToAdminServiceRequestsScreen(),
        isVisible: await this.userService.userHasRole([ADMINISTRATOR])
      },
      {
        icon: 'event note',
        tooltipText: 'Provider Service Requests',
        click: () => this.routerService.goToProviderServiceRequestsScreen(),
        isVisible: await this.userService.userHasRole([SERVICE_PROVIDER])
      },
      {
        icon: 'manage_accounts',
        tooltipText: "Coordinator Administrator Service Requests",
        click: () => this.routerService.goToCoordinatorAdminServiceRequestsScreen(),
        isVisible: await this.userService.userHasRole([SERVICE_COORDINATOR_ADMINISTRATOR, SUPPORT_COORDINATOR_ADMINISTRATOR])
      },
      {
        icon: 'summarize',
        tooltipText: 'Service Requests Report',
        click: () => this.routerService.goToServiceRquestReport(),
        isVisible: await this.userService.userHasRole([ADMINISTRATOR])
      },
    ];
  }

  logoutClick() {
    this.authService.logoutUser();
    this.routerService.goToLoginScreen();
  }
}
