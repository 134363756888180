import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExcludedProvider, ProviderUser } from '../../../models/user.models';
import { UserService } from '../../../services/user.service';
import { MatListModule } from '@angular/material/list';
import { ProviderExcludeComponent } from '../../dialogs/provider-exclude/provider-exclude/provider-exclude.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-exclude-providers',
  standalone: true,
  imports: [
    MatListModule
  ],
  templateUrl: './exclude-providers.component.html',
  styleUrl: './exclude-providers.component.scss'
})
export class ExcludeProvidersComponent implements OnChanges{
  @Input() serviceCategoryIds: string[] = [];
  @Input() includedProviderIds: string[] = [];
  @Input() providersNeedsFiltered: boolean = false;
  @Input() serviceRequestId: string | null = '';
  @Input() excludedProviders: ExcludedProvider[] = [];
  @Input() readonly: boolean = false;

  selectedProviderCount: number = 0;

  @Output() providerClickEvent = new EventEmitter<ExcludedProvider>();
  @Output() selectedProviderCountChangeEvent = new EventEmitter<boolean>();

  serviceProviders: ProviderUser[] = [];

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
  ) {}

  async ngOnChanges(): Promise<void> {
    this.serviceProviders = await this.userService.getProvidersWithCategories(this.serviceCategoryIds);
    this.filterProviders();
  }

  filterProviders() {
    if (this.providersNeedsFiltered) {
      this.serviceProviders.forEach(sp => {
        if (!this.includedProviderIds.includes(sp.id)) {
          var oldExclusionReason = this.excludedProviders.find(ep => ep.providerId == sp.id)?.exclusionReason ?? '';

          sp.selected = false;

          this.providerClickEvent.emit(new ExcludedProvider(sp.id, sp.firstName, sp.lastName, oldExclusionReason));
        }
      });
    }

    this.selectedProviderCount = 0;
    this.serviceProviders.forEach(provider => {
      if (provider.selected) {
        this.selectedProviderCount++;
      }
    });
    this.selectedProviderCountChangeEvent.emit(this.selectedProviderCount > 0);
  }

  providerClicked(providerId: string) {
    var provider = this.serviceProviders.find(sp => sp.id == providerId)!;
    provider.selected = !provider?.selected;

    if(!provider.selected) {
      const dialogRef = this.dialog.open(ProviderExcludeComponent, {disableClose: true, width: '30%'});

      dialogRef.afterClosed().subscribe(result => {
        if (result !== null && result !== undefined && result !== false) {
          this.providerClickEvent.emit(new ExcludedProvider(providerId, provider.firstName, provider.lastName, result));
        } else{
          provider.selected = !provider?.selected;
        }
      });
    } else {
      this.providerClickEvent.emit(new ExcludedProvider(providerId, provider.firstName, provider.lastName, null));
    }

    this.selectedProviderCount = 0;
    this.serviceProviders.forEach(provider => {
      if (provider.selected) {
        this.selectedProviderCount++;
      }
    });
    this.selectedProviderCountChangeEvent.emit(this.selectedProviderCount > 0);
  }
}
