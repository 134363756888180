import { Component } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { PaginatedComponent } from '../../../models/paginated.component';
import { PaginatedServiceRequestForProvider } from '../../../models/service-request.models';
import { ServiceRequestForProviderPaginationQuery } from '../../../models/query-models/service-request-pagination-query.model';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterService } from '../../../services/router.service';
import { ProviderServiceRequestService } from '../../../services/provider-service-request.service';
import { NavigationExtras } from '@angular/router';
import { ProviderWithdrawlComponent } from '../../dialogs/provider-withdrawl/provider-withdrawl.component';
import { MatDialog } from '@angular/material/dialog';
import { PrettyConstantPipe } from '../../../utils/extensions/pretty-constant-pipe';

@Component({
  selector: 'app-provider-service-requests',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatTooltipModule,
    MatPaginatorModule,
    PrettyConstantPipe
  ],
  templateUrl: './provider-service-requests.component.html',
  styleUrl: './provider-service-requests.component.scss'
})

export class ProviderServiceRequestsComponent extends PaginatedComponent<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery> {
  showingWithdraws: boolean = false;

  date: Date = new Date();

  override tblQuery: ServiceRequestForProviderPaginationQuery = ServiceRequestForProviderPaginationQuery.create({
      // ToDo: Date not going to be hard-coded as today
      serviceRequestsAfterDate: new Date(this.date.getDate() - 1),
      onlyGetWithdrawRequests: false,
      pagination: {
        page: 0,
        pageSize: 25,
        sortAscending: false,
        sortField: "CreatedAt"
      }
  });

  override buildQuery(): ServiceRequestForProviderPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['mci', 'lastName', 'coordinatorEmail', 'status', 'categories', 'createdAtDate', 'options'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceRequest/GetRequestsForProvider"
  }

  constructor(
    public providerServiceRequestService: ProviderServiceRequestService,
    public routerService: RouterService,
  ) {
    super(providerServiceRequestService);
  }

  viewClick(id: string, interestId: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        "id": id,
        "interestId": interestId
      }
    };

    this.routerService.goToProviderInterestViewServiceRequestScreen(navigationExtras);
  }

  showWithdrawsClick() {
    this.tblQuery.onlyGetWithdrawRequests = true;
    this.showingWithdraws = true;
    this.getData(null);
  }

  showActiveClick() {
    this.tblQuery.onlyGetWithdrawRequests = false;
    this.showingWithdraws = false;
    this.getData(null);
  }
}
