import { IPaginationQuery, PaginationQuery, PaginationQueryBase } from "./pagination-query.model";

export interface IServiceCoordinationGroupPaginationQuery {
    name: string | null;
    pagination: IPaginationQuery;
}

export class ServiceCoordinationGroupPaginationQuery extends PaginationQueryBase {
    name: string | null;

    constructor (
        name: string | null
    ) {
        super();
        this.name = name;
    }

    static create(query: IServiceCoordinationGroupPaginationQuery): ServiceCoordinationGroupPaginationQuery {
        let serviceCategoryQuery =  new ServiceCoordinationGroupPaginationQuery(query.name ?? "");
        serviceCategoryQuery.pagination = new PaginationQuery(query.pagination);

        return serviceCategoryQuery;
    }
}