export class User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    status: string;
    serviceCoordinatorGroup: {
        id: string;
        name: string;
    };
    roles: {
        id: string;
        name: string;
    }[];
    serviceCategories: {
        id: string;
        name: string;
    }[];
    providerAgency: string;

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        status: string,
        serviceCoordinatorGroup: {
            id: string,
            name: string
        },
        roles: {
            id: string,
            name: string
        }[],
        serviceCategories: {
            id: string,
            name: string
        }[],
        providerAgency: string,
    ){
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.status = status;
        this.serviceCoordinatorGroup = serviceCoordinatorGroup;
        this.roles = roles;
        this.serviceCategories = serviceCategories;
        this.providerAgency = providerAgency;
    }

    getRoleIds(): string[] {
        return this.roles.map(r => r.id) ?? [];
    }

    getServiceCategories(): string[] {
        return this.serviceCategories.map(sc => sc.id) ?? [];
    }
};

export interface IPaginatedUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userRoles: string[];
    providerAgency: string | null;
    status: string;
    lastLogin: string | null;
    userCategories: string[];
}

export class PaginatedUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userRoles: string[];
    providerAgency: string | null;
    status: string;
    lastLogin: string | null;
    lastLoginDate: Date | null;
    userCategories: string[];

    constructor(user: IPaginatedUser) {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.email = user.email;
        this.userRoles = user.userRoles;
        this.providerAgency = user.providerAgency;
        this.status = user.status;
        this.lastLogin = user.lastLogin !== null ? user.lastLogin + "Z" : null;
        this.lastLoginDate = this.lastLogin !== null ? new Date(this.lastLogin) : null;
        this.userCategories = user.userCategories;
    }
}

export class UpdateUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    roleIds: string[];
    providerAgency: string;
    serviceCoordinatorGroupId: string | null;
    serviceCategoryIds: string[];

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        roleIds: string[],
        providerAgency: string,
        serviceCoordinatorGroupId: string | null,
        serviceCategoryIds: string[],
    ){
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.roleIds = roleIds;
        this.providerAgency = providerAgency;
        this.serviceCoordinatorGroupId = serviceCoordinatorGroupId;
        this.serviceCategoryIds = serviceCategoryIds;
    }
}

export class RegisterUser {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    providerAgency: string;
    serviceCoordinatorGroupId: string | null;
    serviceCategoryIds: string[];
    roleIds: string[];

    constructor(
        email: string,
        password: string,
        firstName: string,
        lastName: string,
        roleIds: string[],
        providerAgency: string,
        serviceCoordinatorGroupId: string | null,
        serviceCategoryIds: string[],
    ){
        this.email = email;
        this.password = password;
        this.firstName = firstName;
        this.lastName = lastName;
        this.roleIds = roleIds;
        this.providerAgency = providerAgency;
        this.serviceCoordinatorGroupId = serviceCoordinatorGroupId;
        this.serviceCategoryIds = serviceCategoryIds;
    }
}

export class BlockUnblockUser {
    id: string;

    constructor(id: string) {
        this.id = id;
    }
}

export class UserForgotPassword {
  email: string;

  constructor(email: string) {
    this.email = email;
  }
}

export class ChangeUserPassword {
    id: string;
    password: string;

    constructor(id: string, password: string) {
        this.id = id;
        this.password = password;
    }
}

export class ProviderUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    providerAgency: string;
    selected: boolean;

    constructor(id: string, firstName: string, lastName: string, email: string, providerAgency: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.providerAgency = providerAgency;
        this.selected = true;
    }
}

export class ExcludedProvider {
    providerId: string;
    providerFirstName: string | null;
    providerLastName: string | null;
    exclusionReason: string | null;

    constructor(providerId: string, providerFirstName: string | null, providerLastName: string | null, exclusionReason: string | null) {
        this.providerId = providerId;
        this.providerFirstName = providerFirstName;
        this.providerLastName = providerLastName;
        this.exclusionReason = exclusionReason;
    }
}

export class AutocompleteUser {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}
