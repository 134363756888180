import { Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { selectProviderReasons } from '../../../utils/constants/select-provider-reasons.constants';

@Component({
  selector: 'app-set-accepted-reason',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormFieldModule,
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  templateUrl: './set-accepted-reason.component.html',
  styleUrl: './set-accepted-reason.component.scss'
})
export class SetAcceptedReasonComponent {
  acceptedReasonForm = new FormGroup({
    acceptedReason: new FormControl("", {
      nonNullable: false,
      validators: [Validators.required],
    })
  });

  selectOptions = selectProviderReasons;

  constructor(
    public dialogRef: MatDialogRef<SetAcceptedReasonComponent>
  ) {}

  okClick() {
    if (this.acceptedReasonForm.valid) {
      this.dialogRef.close(this.acceptedReasonForm.controls.acceptedReason.value);
    }
  }
}
