@if (dataSource !== null) {
  <div class="my-4 flex flex-col items-center justify-center">
    <div class="flex flex-row w-10/12">
      <h4>Requested Services For {{coordinatorGroup}}</h4>

      <button
        mat-flat-button
        class="ml-auto my-auto"
        color="primary"
        (click)="createClick()"
      >
        New
      </button>
    </div>
    <div class="flex flex-row w-10/12">
      <form
        [formGroup]="filterServiceRequestForm"
        class="flex flex-col justify-center"
        (ngSubmit)="onFilterClick()"
      >
        <div class="flex justify-evenly items-center my-auto">
          <mat-form-field class="m-1 my-auto flex-grow">
            <mat-label>Service Request Id</mat-label>
            <input matInput formControlName="serviceRequestNumber" />
          </mat-form-field>
          <button mat-flat-button class="mb-4" color="primary">Filter</button>
        </div>
      </form>
    </div>
    <div class="w-10/12">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z2 rounded"
      >
        <ng-container matColumnDef="serviceRequestNumber">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Service Request Id
          </th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.serviceRequestNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Created Date
          </th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.createdAt.toLocaleString() }}
          </td>
        </ng-container>

        <ng-container matColumnDef="mci">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MCI</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.mci }}
          </td>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.firstName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.lastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="service">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
          <td
            mat-cell
            *matCellDef="let serviceRequest"
            [matTooltip]="serviceRequest.categories"
          >
            @if (serviceRequest.categories.length > 1) {
              {{ serviceRequest.categories[0] }}...
            } @else {
              {{ serviceRequest.categories }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="selectedProviders">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Selected Providers</th>
          <td
            mat-cell
            *matCellDef="let serviceRequest"
            class="cursor-pointer"
            (click)="viewAcceptedReason(serviceRequest.providers)"
          >
            {{ getFirstSelectedProvider(serviceRequest.providers) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="selectedProvidersReason">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Accepted Reason</th>
          <td
            mat-cell
            *matCellDef="let serviceRequest"
            class="cursor-pointer"
            (click)="viewAcceptedReason(serviceRequest.providers)"
          >
            {{ getFirstSelectedProviderAcceptedReason(serviceRequest.providers) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let serviceRequest">
            @if (serviceRequest.status === "Closed") {
              Provider Selected
            } @else {
              {{ serviceRequest.status | prettyConstant }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="createdByUser">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.createdByUserFirstName }}
            {{ serviceRequest.createdByUserLastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let serviceRequest">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              @if (serviceRequest.status === "Draft") {
                <button mat-menu-item (click)="editClick(serviceRequest.id)">
                  <span>Edit</span>
                </button>
              } @else {
                <button mat-menu-item (click)="editClick(serviceRequest.id)">
                  <span>View</span>
                </button>
              }
              <button
                mat-menu-item
                (click)="viewProvidersClick(serviceRequest.id)"
              >
                <span>View Providers</span>
              </button>
              @if (serviceRequest.status !== "Closed") {
                <button
                  mat-menu-item
                  (click)="cancelServiceRequestClick(serviceRequest.id)"
                >
                  <span>Cancel Service Request</span>
                </button>
              }
              <button mat-menu-item (click)="viewAuditTable(serviceRequest.id)">
                <span>Audit</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="font-bold text-white bg-table-header-gray color-sort-header-arrow-white"></tr>
        <tr mat-row *matRowDef="let row; let odd = odd; let even = even; columns: displayedColumns;" [ngClass]="{'bg-table-row-odd-gray': odd, 'bg-table-row-even-gray': even}"></tr>
      </table>

      <mat-paginator
        [length]="paginationResult?.totalItems ?? 0"
        [pageSize]="this.pagination.pageSize"
        [pageSizeOptions]="this.pageSizeOptions"
        aria-label="Select page"
        (page)="getData($event)"
        class="mat-elevation-z2"
      ></mat-paginator>
    </div>
  </div>
}
