<div class="grid grid-cols-2 gap-4">
  <div>
    <p class="m-1 text-gray-400">Saved</p>
    <mat-divider class="mx-1"></mat-divider>
    @if (
      this.serviceRequestAttachments.length > 0 ||
      this.serviceRequestProviderAttachments.length > 0 ||
      this.serviceRequestAttachmentsForProviderReadOnly.length > 0
    ) {
      <div
        *ngFor="let attachment of serviceRequestAttachments; let i = index"
        class="m-4"
      >
        <button
          type="button"
          mat-icon-button
          (click)="this.fileService.downloadAttachment(attachment)"
        >
          <mat-icon>download</mat-icon>
        </button>
        <button
          type="button"
          mat-icon-button
          (click)="deleteAttachment(attachment)"
          *ngIf="!disabled"
        >
          <mat-icon>delete</mat-icon>
        </button>
        {{ attachment.displayName }}
        <mat-divider></mat-divider>
      </div>

      <div
        *ngFor="
          let attachment of serviceRequestProviderAttachments;
          let i = index
        "
        class="m-4"
      >
        <button
          type="button"
          mat-icon-button
          (click)="this.fileService.downloadProviderAttachment(attachment)"
        >
          <mat-icon>download</mat-icon>
        </button>
        {{ attachment.displayName }}
        <mat-divider></mat-divider>
      </div>

      <div
        *ngFor="
          let attachment of serviceRequestAttachmentsForProviderReadOnly;
          let i = index
        "
        class="m-4"
      >
        <button
          type="button"
          mat-icon-button
          (click)="this.fileService.downloadAttachment(attachment)"
        >
          <mat-icon>download</mat-icon>
        </button>
        {{ attachment.displayName }}
        <mat-divider></mat-divider>
      </div>
    } @else {
      <div class="m-2">None Saved</div>
    }
  </div>

  <div>
    <p class="m-1 text-gray-400">Uploading on save</p>
    <mat-divider class="mx-1"></mat-divider>
    @if (this.newAttachments.length > 0) {
      <div *ngFor="let file of newAttachments; let i = index" class="m-4">
        <button
          type="button"
          mat-icon-button
          (click)="this.removeNewAttachmentEmit.emit(file)"
        >
          <mat-icon>delete</mat-icon>
        </button>
        {{ file.name }}
        <mat-divider></mat-divider>
      </div>
    } @else {
      <div class="m-2">None Uploading</div>
    }
  </div>
</div>
