import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    removeItem(key: string): void {
        window.localStorage.removeItem(key);
    }

    async setItem(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }

    getItem(key: string): string | null {
        return window.localStorage.getItem(key);
    }
}
