import { Component } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { PaginatedComponent } from '../../../models/paginated.component';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { RouterService } from '../../../services/router.service';
import { ServiceCategoryService } from '../../../services/service-category.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { NavigationExtras } from '@angular/router';
import { PaginatedServiceCoordinatorGroup } from '../../../models/service-coordinator-group.models';
import { ServiceCoordinationGroupPaginationQuery } from '../../../models/query-models/service-coordinator-group-pagination-query.model';
import { ServiceCoordinatorGroupService } from '../../../services/service-coordinator-group.service';

@Component({
  selector: 'app-service-coordinator-groups',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatPaginatorModule
  ],
  templateUrl: './service-coordinator-groups.component.html',
  styleUrl: './service-coordinator-groups.component.scss'
})
export class ServiceCoordinatorGroupsComponent extends PaginatedComponent<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery>{
  override tblQuery: ServiceCoordinationGroupPaginationQuery = ServiceCoordinationGroupPaginationQuery.create({
    name: null,
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: true,
      sortField: "Id"
    }
  });

  override buildQuery(): ServiceCoordinationGroupPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['name', 'options'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceCoordinatorGroup/GetServiceCoordinatorGroups"
  }

  constructor(
    public routerService: RouterService,
    private serviceCoordinatorGroupService: ServiceCoordinatorGroupService
  ){
    super(serviceCoordinatorGroupService)
  }

  editClick(serviceCoordinatorGroup: PaginatedServiceCoordinatorGroup) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
          "id": serviceCoordinatorGroup.id,
          "name": serviceCoordinatorGroup.name
      }
    };

    this.routerService.goToUpsertServiceCoordinatorGroupScreen(navigationExtras);
  }
}
