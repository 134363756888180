import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { PaginatedAuditServiceRequest } from '../models/service-request.models';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { AuditServiceRequestPaginationQuery } from '../models/query-models/service-request-pagination-query.model';
import { PaginatedService } from './paginated.service';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceRequestAuditService extends PaginatedService<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery> {
  constructor(private storageService: StorageService, private fileService: FileService) {
    super(storageService)
  }

  protected override handleSuccess(data: any): PaginationResult<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery> {
    const result = data as PaginationResult<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery>;
    const serviceRequests: PaginatedAuditServiceRequest[] = result?.items.map(sr => new PaginatedAuditServiceRequest(sr)) ?? [];
    const query: AuditServiceRequestPaginationQuery = AuditServiceRequestPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery>(serviceRequests, result.totalItems, query);
  }

  protected override handleNullOrUndefined(): PaginationResult<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery> {
    return new PaginationResult<PaginatedAuditServiceRequest, AuditServiceRequestPaginationQuery>([], 0, new AuditServiceRequestPaginationQuery(null));
  }
}
