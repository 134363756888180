import { Route, Routes } from '@angular/router';
import { LoginComponent } from './components/pages/login/login.component';
import { UsersComponent } from './components/pages/user/users.component';
import { UpsertUserComponent } from './components/pages/user/upsert-user/upsert-user.component';
import { ServiceRequestsComponent } from './components/pages/service-requests/service-requests.component';
import { UpsertServiceRequestComponent } from './components/pages/service-requests/upsert-service-request/upsert-service-request.component';
import { ServiceCategoriesComponent } from './components/pages/service-categories/service-categories.component';
import { UpsertServiceCategoryComponent } from './components/pages/service-categories/upsert-service-category/upsert-service-category.component';

import { ProviderServiceRequestsComponent } from './components/pages/provider-service-requests/provider-service-requests.component';
import { HasRoleAuthGuardService } from './services/has-role-auth-guard.service';
import { ADMINISTRATOR, SERVICE_COORDINATOR, SERVICE_COORDINATOR_ADMINISTRATOR, SERVICE_PROVIDER, SITE_MAINTAINER, SUPPORT_COORDINATOR, SUPPORT_COORDINATOR_ADMINISTRATOR } from './utils/constants/role.constants';
import { IsLoggedInAuthGuradService } from './services/is-logged-in-auth-gurad.service';
import { CoordinatorAdminServiceRequestsComponent } from './components/pages/coordinator-admin-service-requests/coordinator-admin-service-requests.component';
import { InterestViewServiceRequestComponent } from './components/pages/provider-service-requests/interest-view-service-request/interest-view-service-request.component';
import { ListProvidersComponent } from './components/pages/service-requests/list-providers/list-providers.component';
import { ChangePasswordComponent } from './components/dialogs/change-password/change-password.component';
import { ServiceRequestsReportComponent } from './components/pages/service-requests-report/service-requests-report.component';
import { AuditTableComponent } from './components/pages/service-requests/audit-table/audit-table/audit-table.component';
import { CreateProposalComponent } from './components/pages/provider-service-requests/create-proposal/create-proposal.component';
import { ViewProposalComponent } from './components/pages/service-requests/view-proposal/view-proposal.component';
import { AdminServiceRequestsComponent } from './components/pages/admin-service-requests/admin-service-requests.component';
import { ServiceCoordinatorGroupsComponent } from './components/pages/service-coordinator-groups/service-coordinator-groups.component';
import { UpsertServiceCoordinatorGroupComponent } from './components/pages/service-coordinator-groups/upsert-service-coordinator-group/upsert-service-coordinator-group.component';

interface IRouteDefinitions {
    [index: string]: Route;

    login: Route;
    users: Route;
    upsertUser: Route;
    serviceRequests: Route;
    upsertServiceRequest: Route;
    auditServiceRequest: Route;
    serviceCategories: Route;
    upsertServiceCategory: Route;
    providerServiceRequests: Route;
    coordinatorAdminServiceRequests: Route;
    providerInterestViewServiceRequests: Route;
    serviceRequestProviderList: Route;
    serviceRequestsReport: Route;
    providerCreateProposal: Route;
    serviceRequestProposalView: Route;
    adminServiceRequests: Route;
    serviceCoordinatorGroups: Route;
    upsertServiceCoordinatorGroup: Route;
}

export const routeDefinitions: IRouteDefinitions = {
    login: {
        path: "login",
        component: LoginComponent
    },
    users: {
        path: "users",
        component: UsersComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    upsertUser: {
        path: "upsert-user",
        component: UpsertUserComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    serviceRequests: {
        path: "service-requests",
        component: ServiceRequestsComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                SUPPORT_COORDINATOR,
                SERVICE_COORDINATOR,
                SERVICE_COORDINATOR_ADMINISTRATOR,
                SUPPORT_COORDINATOR_ADMINISTRATOR
            ]
        }
    },
    adminServiceRequests: {
        path: "admin-service-requests",
        component: AdminServiceRequestsComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    upsertServiceRequest: {
        path: "upsert-service-request",
        component: UpsertServiceRequestComponent,
        canActivate: [IsLoggedInAuthGuradService]
    },
    serviceCategories: {
        path: "service-categories",
        component: ServiceCategoriesComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    serviceCoordinatorGroups: {
        path: "service-coordinator-groups",
        component: ServiceCoordinatorGroupsComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    upsertServiceCategory: {
        path: "upsert-service-categories",
        component: UpsertServiceCategoryComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    upsertServiceCoordinatorGroup: {
        path: "upsert-service-coordinator-group",
        component: UpsertServiceCoordinatorGroupComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    providerServiceRequests: {
        path: "provider-service-requests",
        component: ProviderServiceRequestsComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                SERVICE_PROVIDER
            ]
        }
    },
    coordinatorAdminServiceRequests: {
        path: "coordinator-admin-service-requests",
        component: CoordinatorAdminServiceRequestsComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                SERVICE_COORDINATOR_ADMINISTRATOR,
                SUPPORT_COORDINATOR_ADMINISTRATOR
            ]
        }
    },
    providerInterestViewServiceRequests: {
        path: "provider-interest-view-service-request",
        component: InterestViewServiceRequestComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR,
                SERVICE_PROVIDER
            ]
        }
    },
    serviceRequestProviderList: {
        path: "service-request-provider-list",
        component: ListProvidersComponent,
        canActivate: [IsLoggedInAuthGuradService]
    },
    changePassword: {
        path: "change-password/:userToken",
        component: ChangePasswordComponent
    },
    serviceRequestsReport: {
        path: "service-requests-report",
        component: ServiceRequestsReportComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR
            ]
        }
    },
    providerCreateProposal: {
        path: "provider-create-proposal",
        component: CreateProposalComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                ADMINISTRATOR,
                SERVICE_PROVIDER
            ]
        }
    },
    serviceRequestProposalView: {
        path: "view-proposal",
        component: ViewProposalComponent,
        canActivate: [IsLoggedInAuthGuradService, HasRoleAuthGuardService],
        data: {
            roles: [
                SERVICE_COORDINATOR,
                SUPPORT_COORDINATOR,
                SERVICE_COORDINATOR_ADMINISTRATOR,
                SUPPORT_COORDINATOR_ADMINISTRATOR
            ]
        }
    },
    auditServiceRequest: {
        path: "app-audit-table",
        component: AuditTableComponent,
        canActivate: [IsLoggedInAuthGuradService]
      },
};

export const routes: Routes = [
    ...Object.values(routeDefinitions),
    {
        path: '**',
        redirectTo: '/login',
        pathMatch: 'full'
    }
];
