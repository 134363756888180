import { Component } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { PaginatedComponent } from '../../../models/paginated.component';
import { PaginatedServiceCategory } from '../../../models/service-coordinator.models';
import { ServiceCategoryPaginationQuery } from '../../../models/query-models/service-category-pagination-query.models';
import { paths } from '../../../../../../../src/clients/backend/backend-openapi-schema';
import { PathsWithMethod } from '../../../services/paginated.service';
import { RouterService } from '../../../services/router.service';
import { ServiceCategoryService } from '../../../services/service-category.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-service-categories',
  standalone: true,
  imports: [
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    CommonModule,
    MatPaginatorModule
  ],
  templateUrl: './service-categories.component.html',
  styleUrl: './service-categories.component.scss'
})
export class ServiceCategoriesComponent extends PaginatedComponent<PaginatedServiceCategory, ServiceCategoryPaginationQuery>{
  override tblQuery: ServiceCategoryPaginationQuery = ServiceCategoryPaginationQuery.create({
    name: null,
    status: null,
    pagination: {
      page: 0,
      pageSize: 25,
      sortAscending: true,
      sortField: "Id"
    }
  });

  override buildQuery(): ServiceCategoryPaginationQuery {
    return this.tblQuery;
  }

  override buildColumnHeaders(): string[] {
    return ['name', 'status', 'options'];
  }

  override buildRoute(): PathsWithMethod<paths, 'post'> {
    return "/ServiceCategory/GetServiceCategories"
  }

  constructor(
    public routerService: RouterService,
    private serviceCategoryService: ServiceCategoryService
  ){
    super(serviceCategoryService)
  }

  editClick(id: string) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
          "id": id
      }
    };

    this.routerService.goToUpsertServiceCategoryScreen(navigationExtras);
  }
}
