@if (this.proposal !== null && this.proposal !== undefined) {
  <div class="flex items-center justify-center mx-40 my-5">
    <div class="flex-row w-3/5 justify-start m-1">
      <h3 class="mb-0">Proposal Details For Service Request #{{ serviceRequest.serviceRequestNumber + " - " + serviceRequest.firstName + " " + serviceRequest.lastName }}</h3>
      <mat-divider class="w-full mb-1"></mat-divider>
      <div class="m-1 border-solid border-2 border-stone-300 rounded">
        <div class="m-2">
          <b>Name:</b>
          {{ this.proposal.firstName + " " + this.proposal.lastName }}
        </div>
        <div class="m-2">
          <b>MCI:</b>
          {{ this.serviceRequest.mci }}
        </div>
        <div class="m-2">
          <b>Provider:</b>
          {{ this.proposal.serviceRequestProvider.providerFirstName + " " + this.proposal.serviceRequestProvider.providerLastName }}
        </div>
        <div class="m-2">
          <b>Start Date:</b>
          {{ this.proposalStartDate.toLocaleDateString() }}
        </div>
        <div class="m-2">
          <b>Frequency & Duration:</b>
          {{ this.proposal.frequencyAndDuration }}
        </div>
        <div class="m-2">
          <b>Service Location:</b>
          {{ this.proposal.serviceLocation }}
        </div>
        <div class="m-2">
          <b>Service Category:</b>
          {{ this.proposal.proposedAction }}
        </div>
        <div class="m-2">
          <b>Comments:</b>
          {{ this.proposal.comments }}
        </div>
      </div>

      <div>
        <div class="flex flex-row-reverse m-1 gap-2">
          <button
            mat-flat-button
            color="primary"
            (click)="acceptProposalClick()"
          >
            Accept Proposal
          </button>

          <button mat-flat-button color="accent" (click)="cancelClick()">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
}
