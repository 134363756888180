import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-accepted-reasons',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatListModule
  ],
  templateUrl: './accepted-reasons.component.html',
  styleUrl: './accepted-reasons.component.scss'
})
export class AcceptedReasonsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {providers: any[]}){}
}