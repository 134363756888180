import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Attachment } from '../models/attachment.model';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  baseUrl = environment.API

  constructor(private httpClient: HttpClient) { }

  uploadServiceRequestAttachment(id: string | void, files: File[]): Observable<any> {
    const formData = new FormData();

    files.forEach(file => {
      formData.append('file', file, file.name);
    });

    return this.httpClient.post(this.baseUrl + `/ServiceRequest/UploadFiles/${id}`, formData);
  }

  downloadAttachment(attachment: Attachment) {
    this.httpClient.post(this.baseUrl + "/ServiceRequest/DownloadServiceRequestAttachment", { "attachmentId": attachment.id }, {"responseType": "blob"}).subscribe(attachmentResponse => {
      const data = window.URL.createObjectURL(attachmentResponse);
      const link = document.createElement("a");
      link.href = data;
      link.download = attachment.displayName; 
      link.click();
    });
  }

  uploadProviderAttachment(serviceRequestProviderId: string, files: File[]): Observable<any> {
    const formData = new FormData();

    files.forEach(file => {
      formData.append('file', file, file.name);
    });
    
    return this.httpClient.post(this.baseUrl + `/ServiceRequest/UploadProviderAttachment/${serviceRequestProviderId}`, formData);
  }

  downloadProviderAttachment(attachment: Attachment) {
    this.httpClient.post(this.baseUrl + "/ServiceRequest/DownloadProviderAttachment", { "attachmentId": attachment.id }, {"responseType": "blob"}).subscribe(attachmentResponse => {
      const data = window.URL.createObjectURL(attachmentResponse);
      const link = document.createElement("a");
      link.href = data;
      link.download = attachment.displayName; 
      link.click();
    });
  }
}
