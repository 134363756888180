export class Role {
    id: string;
    name: string;
    userCount: number;

    constructor(
        id: string,
        name: string,
        userCount: number
    ){
        this.id = id;
        this.name = name;
        this.userCount = userCount;
    }
}
