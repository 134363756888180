@if (dataSource !== null) {
  <div class="my-4 flex flex-col items-center justify-center">
    <div class="flex flex-row w-10/12">
      <h4>Requested Services</h4>
    </div>
    <div class="flex flex-row w-10/12">
      <div class="flex flex-row">
        <div class="place-items-start">
          @if (showingWithdraws) {
            <button
              mat-flat-button
              class="mb-4 ml-2"
              color="primary"
              (click)="showActiveClick()"
            >
              Show Current Service Requests
            </button>
          } @else {
            <button
              mat-flat-button
              class="mb-4 ml-2"
              color="primary"
              (click)="showWithdrawsClick()"
            >
              Show Withdrawn Service Requests
            </button>
          }
        </div>
      </div>
    </div>
    <div class="w-10/12">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z2 rounded"
      >
        <ng-container matColumnDef="mci">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MCI #</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.mci }}
          </td>
        </ng-container>

        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.serviceRequestLastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="coordinatorEmail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Coordinator Email</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.createdByUserEmail }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let serviceRequest">
            @if (serviceRequest.status === "Accepted") {
              Provider Selected
            } @else if (serviceRequest.status === "Pending") {
              Interest Requested
            } @else {
              {{ serviceRequest.status | prettyConstant }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="categories">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
          <td
            mat-cell
            *matCellDef="let serviceRequest"
            [matTooltip]="serviceRequest.categories"
          >
            @if (serviceRequest.categories.length > 1) {
              {{ serviceRequest.categories[0] }}...
            } @else {
              {{ serviceRequest.categories }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="createdAtDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
          <td mat-cell *matCellDef="let serviceRequest">
            {{ serviceRequest.createdAtDate.toLocaleString() }}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let serviceRequest">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                (click)="
                  this.viewClick(
                    serviceRequest.serviceRequestId,
                    serviceRequest.id
                  )
                "
              >
                <span>View</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
          class="font-bold text-white bg-table-header-gray color-sort-header-arrow-white"
        ></tr>
        <tr
          mat-row
          *matRowDef="
            let row;
            let odd = odd;
            let even = even;
            columns: displayedColumns
          "
          [ngClass]="{
            'bg-table-row-odd-gray': odd,
            'bg-table-row-even-gray': even
          }"
        ></tr>
      </table>

      <mat-paginator
        [length]="paginationResult?.totalItems ?? 0"
        [pageSize]="this.pagination.pageSize"
        [pageSizeOptions]="this.pageSizeOptions"
        aria-label="Select page"
        (page)="getData($event)"
        class="mat-elevation-z2"
      ></mat-paginator>
    </div>
  </div>
}
