import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment } from '../../../models/attachment.model';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { ServiceRequestService } from '../../../services/service-request.service';
import { FileService } from '../../../services/file.service';

@Component({
  selector: 'app-attachments-list',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule
  ],
  templateUrl: './attachments-list.component.html',
  styleUrl: './attachments-list.component.scss'
})
export class AttachmentsListComponent {
  @Input() serviceRequestAttachments: Attachment[] = [];
  @Input() serviceRequestProviderAttachments: Attachment[] = [];
  @Input() serviceRequestAttachmentsForProviderReadOnly: Attachment[] = [];

  @Input() newAttachments: File[] = [];
  @Input() disabled: boolean = false;

  @Output() removeNewAttachmentEmit  = new EventEmitter<File>();
  @Output() removeCurrentAttachmentEmit = new EventEmitter<Attachment>();

  constructor (private serviceRequestService: ServiceRequestService, public fileService: FileService) {}

  deleteAttachment(attachment: Attachment) {
    this.serviceRequestService.deleteAttachment(attachment.id);
    this.removeCurrentAttachmentEmit.emit(attachment);
  }

  deleteProviderAttachment(attachment: Attachment) {
    this.serviceRequestService.deleteProviderAttachment(attachment.id);
    this.removeCurrentAttachmentEmit.emit(attachment);
  }
}
