import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { PaginatedServiceRequest, PaginatedServiceRequestForProvider } from '../models/service-request.models';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { ServiceRequestForProviderPaginationQuery, ServiceRequestPaginationQuery, ServiceRequestProvidersWithInterestPaginationQuery } from '../models/query-models/service-request-pagination-query.model';
import { PaginatedService } from './paginated.service';
import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderServiceRequestService extends PaginatedService<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery> {
  constructor(private storageService: StorageService, private fileService: FileService) {
    super(storageService)
  }

  protected override handleSuccess(data: any): PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery> {
    const result = data as PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery>;
    const serviceRequests: PaginatedServiceRequestForProvider[] = result?.items.map(sr => new PaginatedServiceRequestForProvider(sr)) ?? [];
    const query: ServiceRequestForProviderPaginationQuery = ServiceRequestForProviderPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery>(serviceRequests, result.totalItems, query);
  }

  protected override handleNullOrUndefined(): PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery> {
    return new PaginationResult<PaginatedServiceRequestForProvider, ServiceRequestForProviderPaginationQuery>([], 0, new ServiceRequestForProviderPaginationQuery(null));
  }

  providerInterested(id: string, routeForEmail: string, comments: string): Promise<any> {
    return this.authenticatedClient.POST("/ServiceRequest/ProviderInterested", { body: { "serviceRequestProviderId": id, "routeForEmail": routeForEmail, "comments": comments}});
  }

  withdrawProvider(id: string, routeForEmail: string, comments: string): Promise<any> {
    return this.authenticatedClient.POST("/ServiceRequest/WithdrawProvider", { body: { "serviceRequestProviderId": id, "routeForEmail": routeForEmail, "comments": comments}});
  }
}
