<mat-dialog-content class="pb-0">
  <div [formGroup]="cancelServiceRequestForm">
    <h4 mat-dialog-title>Cancel Service Request</h4>

    <mat-form-field class="w-full">
      <mat-label>Cancel Reason</mat-label>
      <mat-select formControlName="comments">
        @for (option of cancelOptions; track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    @if (otherOptionSelected) {
      <p>Please add any comments about cancellation reason.</p>

      <mat-form-field class="w-full">
        <mat-label>Comments</mat-label>
        <textarea
          matInput
          cdkFocusInitial
          formControlName="openEnded"
          required="otherOptionSelected"
        ></textarea>
      </mat-form-field>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions class="mx-4">
  <button mat-flat-button color="accent" [mat-dialog-close]>Cancel</button>

  <button mat-flat-button color="primary" (click)="okClick()" [disabled]="!cancelServiceRequestForm.valid" class="ml-auto">
    Ok
  </button>
</mat-dialog-actions>
