import { User } from "./user.models";

export class ServiceRequest {
    id: string;
    serviceRequestNumber: Number;
    status: string;
    providerInterestResponseDate: string;
    providerProposalResponseDate: string;
    firstName: string;
    lastName: string;
    middleName: string;
    mci: string;
    comments: string;
    preferredStartDate: string;
    serviceLocation: string;
    frequencyAndDuration: string;
    requestType: string;
    canBeClosed: boolean;
    coordinatorEmail: string;
    attachments: {
        id: string;
        attachmentUrl: string;
        displayName: string;
    }[];
    categories: {
        id: string;
        name: string;
        status: Number;
    }[];
    providers: {
        id: string;
        providerId: string;
        providerFirstName: string;
        providerLastName: string;
        providerEmail: string;
        status?: string;
        serviceRequestAttachments: {
            id: string,
            attachmentUrl: string,
            displayName: string
        }[];
    }[];
    proposals: {
        id: string;
        serviceRequestProvider: {
            id: string;
            providerId: string;
            providerFirstName: string;
            providerLastName: string;
            providerEmail: string;
            providerStatus: string;
            interestedAndWithdrawalComments: string | null;
            exclusionReason: string | null;
            serviceRequestAttachments: {
                id: string;
                attachmentUrl: string;
                displayName: string;
            }[];
        };
        firstName: string;
        lastName: string;
        startDate: string;
        frequencyAndDuration: string;
        serviceLocation: string;
        proposedAction: string;
        comments: string | null;
    }[];
    excludedProviders: {
      id: string;
      providerId: string;
      providerFirstName: string;
      providerLastName: string;
      providerEmail: string;
      exclusionReason: string | null;
    }[];

    constructor(
        id: string,
        serviceRequestNumber: Number,
        status: string,
        providerInterestResponseDate: string,
        providerProposalResponseDate: string,
        firstName: string,
        lastName: string,
        middleName: string,
        mci: string,
        comments: string,
        preferredStartDate: string,
        serviceLocation: string,
        frequencyAndDuration: string,
        requestType: string,
        canBeClosed: boolean,
        coordinatorEmail: string,
        attachments: {
            id: string,
            attachmentUrl: string,
            displayName: string,
        }[],
        categories: {
            id: string,
            name: string,
            status: Number,
        }[],
        providers: {
            id: string,
            providerId: string,
            providerFirstName: string,
            providerLastName: string,
            providerEmail: string,
            serviceRequestAttachments: {
                id: string,
                attachmentUrl: string,
                displayName: string
            }[]
        }[],
        proposals: {
            id: string,
            serviceRequestProvider: {
                id: string,
                providerId: string,
                providerFirstName: string,
                providerLastName: string,
                providerEmail: string,
                providerStatus: string,
                interestedAndWithdrawalComments: string | null,
                exclusionReason: string | null,
                serviceRequestAttachments: {
                    id: string,
                    attachmentUrl: string,
                    displayName: string
                }[]
            },
            firstName: string,
            lastName: string,
            startDate: string,
            frequencyAndDuration: string,
            serviceLocation: string,
            proposedAction: string,
            comments: string | null,
        }[],
        excludedProviders: {
          id: string;
          providerId: string;
          providerFirstName: string;
          providerLastName: string;
          providerEmail: string;
          exclusionReason: string | null;
        }[],
    ) {
        this.id = id;
        this.serviceRequestNumber = serviceRequestNumber;
        this.status = status;
        this.providerInterestResponseDate = providerInterestResponseDate;
        this.providerProposalResponseDate = providerProposalResponseDate;
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.mci = mci;
        this.comments = comments;
        this.preferredStartDate = preferredStartDate;
        this.serviceLocation = serviceLocation;
        this.frequencyAndDuration = frequencyAndDuration;
        this.requestType = requestType;
        this.canBeClosed = canBeClosed;
        this.attachments = attachments;
        this.categories = categories;
        this.providers = providers;
        this.excludedProviders = excludedProviders;
        this.proposals = proposals;
        this.coordinatorEmail = coordinatorEmail;
    }

    getServiceCategoriesIds(): string[] {
        return this.categories.map(sc => sc.id) ?? [];
    }

    getProviderIds(): string[] {
        return this.providers.map(p => p.providerId) ?? [];
    }
}

export interface IPaginatedServiceRequest {
    id: string;
    serviceRequestNumber: number;
    status: string;
    firstName: string;
    lastName: string;
    middleName: string;
    categories: string[];
    mci: string;
    createdAt: Date;
    createdByUserFirstName: string | null;
	createdByUserLastName: string | null;
    providers: {
		provider: {
			firstName: string;
			lastName: string;
			email: string;
			providerAgency: string;
		},
		status: number;
		acceptedReason: string;
	}[];
}

export class PaginatedServiceRequest {
    id: string;
    serviceRequestNumber: number;
    status: string;
    firstName: string;
    lastName: string;
    middleName: string;
    categories: string[];
    mci: string;
    createdAt: Date;
    createdByUserFirstName: string | null;
	createdByUserLastName: string | null;
    providers: {
		provider: {
			firstName: string;
			lastName: string;
			email: string;
			providerAgency: string;
		},
		status: number;
		acceptedReason: string;
	}[];

    constructor(serviceRequest: IPaginatedServiceRequest) {
        this.id = serviceRequest.id;
        this.serviceRequestNumber = serviceRequest.serviceRequestNumber;
        this.status = serviceRequest.status;
        this.firstName = serviceRequest.firstName;
        this.lastName = serviceRequest.lastName;
        this.middleName = serviceRequest.middleName;
        this.categories = serviceRequest.categories;
        this.mci = serviceRequest.mci;
        this.createdAt = new Date(serviceRequest.createdAt + "Z");
        this.createdByUserFirstName = serviceRequest.createdByUserFirstName;
        this.createdByUserLastName = serviceRequest.createdByUserLastName;
        this.providers = serviceRequest.providers;
    }
}

export interface IPaginatedServiceRequestForProvider {
    id: string;
    serviceRequestId: string;
    status: string;
    serviceRequestLastName: string;
    serviceRequestMci: string;
    categories: string[];
    serviceRequestStatus: string;
    createdByUserEmail: string;
    interestedAndWithdrawalComments: string;
    providerName: string;
    providerAgency: string;
    createdAt: string;
    createdAtDate: Date;
    mci: string;
    providerEmail: string;
    acceptedReason: string;
    proposalComments: string;
    providerAttachments: {
        id: string;
        attachmentUrl: string;
        displayName: string;
    }[];
}

export class PaginatedServiceRequestForProvider {
    id: string;
    serviceRequestId: string;
    status: string;
    serviceRequestLastName: string;
    serviceRequestMci: string;
    categories: string[];
    serviceRequestStatus: string;
    createdByUserEmail: string;
    interestedAndWithdrawalComments: string;
    providerName: string;
    providerAgency: string;
    createdAt: string;
    createdAtDate: Date;
    mci: string;
    providerEmail: string;
    acceptedReason: string;
    proposalComments: string;
    providerAttachments: {
        id: string;
        attachmentUrl: string;
        displayName: string;
    }[];

    constructor(serviceRequest: IPaginatedServiceRequestForProvider) {
        this.id = serviceRequest.id;
        this.serviceRequestId = serviceRequest.serviceRequestId;
        this.status = serviceRequest.status;
        this.serviceRequestLastName = serviceRequest.serviceRequestLastName;
        this.serviceRequestMci = serviceRequest.serviceRequestMci;
        this.categories = serviceRequest.categories;
        this.serviceRequestStatus = serviceRequest.serviceRequestStatus;
        this.createdByUserEmail = serviceRequest.createdByUserEmail;
        this.interestedAndWithdrawalComments = serviceRequest.interestedAndWithdrawalComments;
        this.providerName = serviceRequest.providerName;
        this.providerAgency = serviceRequest.providerAgency;
        this.providerAttachments = [];
        this.createdAt = serviceRequest.createdAt;
        this.createdAtDate = new Date(this.createdAt);
        this.mci = serviceRequest.mci;
        this.providerEmail = serviceRequest.providerEmail;
        this.acceptedReason = serviceRequest.acceptedReason;
        this.proposalComments = serviceRequest.proposalComments;
    }
}

export class CreateServiceRequest {
    firstName: string;
    lastName: string;
    middleName: string | null;
    mci: string;
    comments: string;
    serviceLocation: string | null;
    frequencyAndDuration: string;
    preferredStartDate: string | null;
    providerInterestResponseDate: string;
    providerProposalResponseDate: string;
    serviceCategoryIds: string[];
    provdersToExclude: {
        providerId: string;
        exclusionReason: string | null;
    }[];

    constructor(
        firstName: string,
        lastName: string,
        middleName: string | null,
        mci: string,
        comments: string,
        serviceLocation: string | null,
        frequencyAndDuration: string,
        preferredStartDate: string | null,
        providerInterestResponseDate: string,
        providerProposalResponseDate: string,
        serviceCategoryIds: string[],
        provdersToExclude: {
            providerId: string,
            exclusionReason: string | null,
        }[]
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.mci = mci;
        this.comments = comments;
        this.serviceLocation = serviceLocation;
        this.frequencyAndDuration = frequencyAndDuration;
        this.preferredStartDate = preferredStartDate;
        this.providerInterestResponseDate = providerInterestResponseDate;
        this.providerProposalResponseDate = providerProposalResponseDate;
        this.serviceCategoryIds = serviceCategoryIds;
        this.provdersToExclude = provdersToExclude;
    }
}

export class UpdateServiceRequest {
    id: string;
    firstName: string;
    lastName: string;
    middleName: string | null;
    mci: string;
    comments: string;
    providerInterestResponseDate: string;
    providerProposalResponseDate: string;
    serviceCategoryIds: string[];
    preferredStartDate: string | null;
    frequencyAndDuration: string;
    serviceLocation: string | null;
    provdersToExclude: {
        providerId: string;
        exclusionReason: string | null;
    }[];

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        middleName: string | null,
        mci: string,
        comments: string,
        providerInterestResponseDate: string,
        providerProposalResponseDate: string,
        serviceCategoryIds: string[],
        preferredStartDate: string | null,
        frequencyAndDuration: string,
        serviceLocation: string | null,
        provdersToExclude: {
            providerId: string,
            exclusionReason: string | null,
        }[]
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.middleName = middleName;
        this.mci = mci;
        this.comments = comments;
        this.providerInterestResponseDate = providerInterestResponseDate;
        this.providerProposalResponseDate = providerProposalResponseDate;
        this.serviceCategoryIds = serviceCategoryIds;
        this.preferredStartDate = preferredStartDate;
        this.frequencyAndDuration = frequencyAndDuration;
        this.serviceLocation = serviceLocation;
        this.provdersToExclude = provdersToExclude;
    }
}

export interface IPaginatedAuditServiceRequest {
  id: string;
  serviceRequest: ServiceRequest;
  type: string;
  user: User;
  createdByUser: User;
  createdAt: Date;
  requestType: string;
}

export class PaginatedAuditServiceRequest {
    id: string;
    serviceRequest: ServiceRequest;
    type: string;
    user: User;
    createdByUser: User;
    createdAt: Date;
    requestType: string;

    constructor(serviceRequestAudit: IPaginatedAuditServiceRequest) {
      this.id = serviceRequestAudit.id;
      this.serviceRequest = serviceRequestAudit.serviceRequest;
      this.type = serviceRequestAudit.type;
      this.user = serviceRequestAudit.user;
      this.createdByUser = serviceRequestAudit.createdByUser;
      this.createdAt = new Date(serviceRequestAudit.createdAt);
      this.requestType = serviceRequestAudit.requestType;
    }
}
