<div class="my-4 flex flex-col items-center justify-center">
  <div class="flex flex-row w-10/12">
    <h4>Audit Service</h4>
  </div>
  <div class="w-10/12">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z2 rounded"
    >
      <ng-container matColumnDef="serviceRequestNumber">
        <th mat-header-cell *matHeaderCellDef>Service Request Id</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.serviceRequest.serviceRequestNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef>Last Name</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.user.lastName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef>First Name</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.user.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="providerAgency">
        <th mat-header-cell *matHeaderCellDef>Provider Agency</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.user.providerAgency }}
        </td>
      </ng-container>

      <ng-container matColumnDef="requestType">
        <th mat-header-cell *matHeaderCellDef>Request Type</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.serviceRequest.requestType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Audit Type</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.type | prettyConstant }}
        </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let serviceRequest">
          {{ serviceRequest.createdAt.toLocaleDateString() }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="font-bold text-white bg-table-header-gray"></tr>
      <tr mat-row *matRowDef="let row; let odd = odd; let even = even; columns: displayedColumns;" [ngClass]="{'bg-table-row-odd-gray': odd, 'bg-table-row-even-gray': even}"></tr>
    </table>

      <mat-paginator
        [length]="paginationResult?.totalItems ?? 0"
        [pageSize]="this.pagination.pageSize"
        [pageSizeOptions]="this.pageSizeOptions"
        aria-label="Select page"
        (page)="getData($event)"
        class="mat-elevation-z2"
    ></mat-paginator>
  </div>
</div>
