import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogContent, MatDialogActions, MatDialogClose, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { cancelServiceRequestReasons } from '../../../utils/constants/cancel-service-request-reasons.constants';

@Component({
  selector: 'app-cancel-service-request',
  standalone: true,
  imports: [
    MatDialogContent,
    MatFormFieldModule,
    FormsModule,
    MatDialogActions,
    MatDialogClose,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatSelectModule
  ],
  templateUrl: './cancel-service-request.component.html',
  styleUrl: './cancel-service-request.component.scss'
})
export class CancelServiceRequestComponent implements OnInit {
  cancelServiceRequestForm = new FormGroup({
    comments: new FormControl("", {
      nonNullable: false,
      validators: [Validators.required],
    }),
    openEnded: new FormControl("", {
      nonNullable: false,
    })
  });

  cancelOptions = cancelServiceRequestReasons;

  get otherOptionSelected(): boolean {
    const comment = this.cancelServiceRequestForm?.controls.comments.value;
    if (comment == "Other; Please Specify") { 
      return true;
    } else {
      return false;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<CancelServiceRequestComponent>
  ) {}
  
  ngOnInit(): void {
    this.cancelServiceRequestForm.controls['comments'].valueChanges.subscribe(value => {
      if (value == "Other; Please Specify") { 
        this.cancelServiceRequestForm?.controls.openEnded.setValidators(
          Validators.required
        );
        this.cancelServiceRequestForm?.controls.openEnded.updateValueAndValidity();
      } else {
        this.cancelServiceRequestForm?.controls.openEnded.setValue(null);
        this.cancelServiceRequestForm?.controls.openEnded.clearValidators();
        this.cancelServiceRequestForm?.controls.openEnded.updateValueAndValidity();
      }
    });
  }

  okClick() {
    if (this.cancelServiceRequestForm.valid) {
      if (this.otherOptionSelected) {
        this.dialogRef.close(this.cancelServiceRequestForm.controls.openEnded.value);
      } else {
        this.dialogRef.close(this.cancelServiceRequestForm.controls.comments.value);
      }
    }
  }
}
