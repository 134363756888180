import { Component, Inject, Input } from '@angular/core';
import { MatDialogContent, MatDialogActions, MatDialogClose, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExcludedProvider } from '../../../../models/user.models';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

@Component({
  selector: 'app-exclusion-reasons',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatListModule
  ],
  templateUrl: './exclusion-reasons.component.html',
  styleUrl: './exclusion-reasons.component.scss'
})
export class ExclusionReasonsComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {providersToExclude: ExcludedProvider[]}){}
}
