<div class="my-4 flex flex-col items-center justify-center">
  <div class="flex flex-row w-10/12">
    <h4>Users</h4>

    <button
      mat-flat-button
      class="ml-auto my-auto"
      color="primary"
      (click)="createClick()"
    >
      New
    </button>
  </div>
  <div class="flex flex-row w-10/12">
    <form
      [formGroup]="filterUserForm"
      class="flex flex-col justify-center mx-10"
      (ngSubmit)="onFilterClick()"
    >
      <div class="flex justify-evenly">
        <mat-form-field class="m-1 flex-grow">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>

        <mat-form-field class="m-1 flex-grow">
          <mat-label>Role</mat-label>
          <mat-select formControlName="roleNames">
            <mat-option value=""></mat-option>
            @for (role of roles; track role) {
              <mat-option [value]="role.name">{{ role.name }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-row">
        <div class="place-items-start">
          <button mat-flat-button class="mb-4" color="primary">Filter</button>
          @if (this.showingActive) {
            <button
              mat-flat-button
              class="mb-4 ml-2"
              color="primary"
              (click)="showBlockedClick()"
            >
              Show Blocked Users
            </button>
          } @else {
            <button
              mat-flat-button
              class="mb-4 ml-2"
              color="primary"
              (click)="showActiveClick()"
            >
              Show Active Users
            </button>
          }
        </div>
      </div>
    </form>
  </div>
  <div class="w-10/12">
    @if (dataSource !== null) {
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)"
        class="mat-elevation-z2 rounded"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let user">
            {{ user.firstName + " " + user.lastName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let user">{{ user.email }}</td>
        </ng-container>

        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Roles</th>
          <td mat-cell *matCellDef="let user" [matTooltip]="user.userRoles">
            @if (user.userRoles.length > 1) {
              {{ user.userRoles[0] }}...
            } @else {
              {{ user.userRoles }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="categories">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Categories</th>
          <td
            mat-cell
            *matCellDef="let user"
            [matTooltip]="user.userCategories"
          >
            @if (user.userCategories.length > 1) {
              {{ user.userCategories[0] }}...
            } @else {
              {{ user.userCategories }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="providerAgency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Provider Agency
          </th>
          <td mat-cell *matCellDef="let user">{{ user.providerAgency }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let user">{{ user.status }}</td>
        </ng-container>

        <ng-container matColumnDef="lastLogin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Login</th>
          <td mat-cell *matCellDef="let user">
            {{ user.lastLoginDate?.toLocaleString() ?? "Never" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="options">
          <th mat-header-cell *matHeaderCellDef>Options</th>
          <td mat-cell *matCellDef="let user">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="Example icon-button with a menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="editClick(user.id)">
                <span>Edit</span>
              </button>
              @if (user.status === "Active") {
                <button
                  mat-menu-item
                  (click)="
                    blockUserClick(user.id, user.firstName, user.lastName)
                  "
                >
                  <span>Block</span>
                </button>
              } @else {
                <button
                  mat-menu-item
                  (click)="
                    unblockUserClick(user.id, user.firstName, user.lastName)
                  "
                >
                  <span>Unblock</span>
                </button>
              }

              <button mat-menu-item (click)="changeUserPasswordClick(user.id)">
                <span>Change Password</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="font-bold text-white bg-table-header-gray"></tr>
        <tr mat-row *matRowDef="let row; let odd = odd; let even = even; columns: displayedColumns;" [ngClass]="{'bg-table-row-odd-gray': odd, 'bg-table-row-even-gray': even}"></tr>
      </table>
    }

    <mat-paginator
      [length]="paginationResult?.totalItems ?? 0"
      [pageSize]="this.pagination.pageSize"
      [pageSizeOptions]="this.pageSizeOptions"
      aria-label="Select page"
      (page)="getData($event)"
      class="mat-elevation-z2"
    ></mat-paginator>
  </div>
</div>
