import { Injectable } from "@angular/core";
import { Login } from "../models/login.model";
import createClient from "openapi-fetch";
import { paths } from "../../../../../src/clients/backend/backend-openapi-schema";
import { StorageService } from "./storage.service";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../utils/constants/storage-key.constants";
import { environment } from "./../../../../../environments/environment"

const client = createClient<paths>({ baseUrl: environment.API });

@Injectable({
    providedIn: "root",
})
export class AuthService {
    get loggedIn() {
        const token = this.storageService.getItem(AUTH_TOKEN);
        return (token != null && token != undefined && token.length > 0);
    }

    constructor(    
        private storageService: StorageService
    ){ }

    async authenticateUser(loginInfo: Login) {
        const { data, error } = await client.POST("/User/AuthenticateUser", {
            body: {
                "email": loginInfo.email,
                "password": loginInfo.password
            },
        });

        return data;
    }

    logoutUser() {
        this.storageService.removeItem(AUTH_TOKEN);
        this.storageService.removeItem(REFRESH_TOKEN);
    }
}
