@if (upsertServiceCategoryForm !== undefined) {
  <form
    [formGroup]="upsertServiceCategoryForm"
    class="flex flex-col items-center justify-center mx-10"
  >
    <div class="flex-row w-3/5 justify-start m-1">
      <h3 class="mb-0">Service Category</h3>
      <mat-divider class="w-full mb-1"></mat-divider>
    </div>

    <div class="flex flex-col justify-center w-3/5 m-1">
      <div class="flex justify-evenly">
        <mat-form-field class="m-1 flex-grow">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>
      </div>
    </div>

    <div class="flex flex-row-reverse w-3/5 m-1 gap-2">
      <button mat-flat-button color="primary" (click)="onSaveClick()">
        Save
      </button>

      <button mat-flat-button color="accent" (click)="onCancelClick()">
        Cancel
      </button>
    </div>
  </form>
}
