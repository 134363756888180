export {};

declare global {
    interface Array<T> {
        addOrRemove(item: T): Array<T>;
    }
}

Array.prototype.addOrRemove = function (item) {
    if (this.includes(item)) {
        const indexToRemove = this.indexOf(item);
        this.splice(indexToRemove, 1);
      } else {
        this.push(item);
      }

    return this;
};