import { ServiceRequestStatusModel } from "../../models/service-request-status.model";

export const InterestStatus: ServiceRequestStatusModel[] = [
    new ServiceRequestStatusModel('Pending', 'Pending'),
    new ServiceRequestStatusModel('Withdrawn', 'Withdrawn'),
    new ServiceRequestStatusModel('Interested', 'Interested'),
    new ServiceRequestStatusModel('Not Responded', 'NotResponded'),
    new ServiceRequestStatusModel('Proposal Requested', 'ProposalRequested'),
    new ServiceRequestStatusModel('Proposal Submitted', 'ProposalSubmitted'),
    new ServiceRequestStatusModel('Not Selected', 'NotSelected'),
    new ServiceRequestStatusModel('Accepted', 'Accepted'),
    new ServiceRequestStatusModel('Excluded', 'Excluded'),
    new ServiceRequestStatusModel('Service Request Cancelled', 'ServiceRequestCancelled'),
]