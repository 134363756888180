import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { PaginatedService } from './paginated.service';
import { PaginationResult } from '../models/query-models/pagination-result.model';
import { CreateServiceCoordinatorGroup, PaginatedServiceCoordinatorGroup, UpdateServiceCoordinatorGroup } from '../models/service-coordinator-group.models';
import { ServiceCoordinationGroupPaginationQuery } from '../models/query-models/service-coordinator-group-pagination-query.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceCoordinatorGroupService extends PaginatedService<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery> {
  protected override handleSuccess(data: any): PaginationResult<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery> {
    const result = data as PaginationResult<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery>;
    const serviceCategories: PaginatedServiceCoordinatorGroup[] = result?.items.map(u => new PaginatedServiceCoordinatorGroup(u)) ?? [];
    const query: ServiceCoordinationGroupPaginationQuery = ServiceCoordinationGroupPaginationQuery.create(result.queryExecuted);

    return new PaginationResult<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery>(serviceCategories, result.totalItems, query);
  }

  protected override handleNullOrUndefined(): PaginationResult<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery> {
    return new PaginationResult<PaginatedServiceCoordinatorGroup, ServiceCoordinationGroupPaginationQuery>([], 0, new ServiceCoordinationGroupPaginationQuery(null));
  }

  constructor(private storageService: StorageService) {
    super(storageService);
  }

  async getServiceCoordinatorGroups(): Promise<PaginatedServiceCoordinatorGroup[]> {
    const { data, error } = await this.authenticatedClient.POST("/ServiceCoordinatorGroup/GetAllServiceCoordinatorGroups", {body: {}});
    const serviceCoordinatorGroups = data?.items.map(scg => new PaginatedServiceCoordinatorGroup(scg));

    return serviceCoordinatorGroups ?? [];
  }

  async createServiceCoordinatorGroup(name: string) {
    const serviceCategory: CreateServiceCoordinatorGroup = new CreateServiceCoordinatorGroup(name);

    const { data, error } = await this.authenticatedClient.POST("/ServiceCoordinatorGroup/CreateServiceCoordinatorGroup", { body: serviceCategory });
  }

  async updateServiceCoordinatorGroup(id: string, name: string) {
    const serviceCategory: UpdateServiceCoordinatorGroup = new UpdateServiceCoordinatorGroup(id, name);

    const { data, error } = await this.authenticatedClient.POST("/ServiceCoordinatorGroup/UpdateServiceCoordinatorGroup", { body: serviceCategory });
  }
}
