export class ServiceCategory {
    id: string;
    name: string;
    status: string;

    constructor(
        id: string,
        name: string,
        status: string
    ){
        this.id = id;
        this.name = name;
        this.status = status;
    }
}

export class GetServiceCategoryById {
  id: string;

  constructor(
    id: string
  ){
    this.id = id;
  }
}

export class CreateServiceCategory {
    name: string;

    constructor(
        name: string
    ){
        this.name = name;
    }
}

export class UpdateServiceCategory {
    id: string;
    name: string;

    constructor(
        id: string,
        name: string
    ){
        this.id = id;
        this.name = name;
    }
}
