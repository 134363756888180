@if (serviceRequest !== null || serviceRequest !== undefined) {
  <div class="my-4 flex flex-col items-center justify-center">
    <div class="flex flex-row w-10/12">
      <h4>
        Interested Providers For Service Request #
        {{
          serviceRequest.serviceRequestNumber +
            " - " +
            serviceRequest.firstName +
            " " +
            serviceRequest.lastName
        }}
      </h4>

      <button
        mat-flat-button
        class="ml-auto my-auto"
        color="primary"
        (click)="closeServiceRequestClick()"
        [disabled]="!this.canBeClosed"
      >
        Finalize Request and Notify Providers
      </button>
    </div>
    <div class="w-10/12">
      @if (dataSource !== null) {
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z2 rounded"
        >
          <ng-container matColumnDef="providerAgency">
            <th mat-header-cell *matHeaderCellDef>Provider Agency</th>
            <td mat-cell *matCellDef="let serviceRequest">
              {{ serviceRequest.providerAgency }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Provider Email</th>
            <td mat-cell *matCellDef="let serviceRequest">
              {{ serviceRequest.providerEmail }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="categories">
            <th mat-header-cell *matHeaderCellDef>Service</th>
            <td
              mat-cell
              *matCellDef="let serviceRequest"
              [matTooltip]="serviceRequest.categories"
            >
              @if (
                serviceRequest.categories !== null &&
                serviceRequest.categories.length > 1
              ) {
                {{ serviceRequest.categories[0] }}...
              } @else {
                {{ serviceRequest.categories }}
              }
            </td>
          </ng-container>
  
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let serviceRequest">
              @if (serviceRequest.status === "Accepted") {
                Provider Selected
              } @else if (serviceRequest.status === "Pending") {
                Interest Requested
              } @else {
                {{ serviceRequest.status | prettyConstant }}
              }
            </td>
          </ng-container>
  
          <ng-container matColumnDef="comments">
            <th mat-header-cell *matHeaderCellDef>Comments</th>
            <td
              mat-cell
              *matCellDef="let serviceRequest"
              class="cursor-pointer"
              (click)="
                viewComments(serviceRequest.interestedAndWithdrawalComments, serviceRequest.proposalComments)
              "
            >
              @if (serviceRequest.interestedAndWithdrawalComments !== null && serviceRequest.interestedAndWithdrawalComments !== "") {
                @if (serviceRequest.interestedAndWithdrawalComments?.length > 10) {
                  {{
                  serviceRequest.interestedAndWithdrawalComments.substring(0, 9)
                  }}...
                } @else {
                  {{ serviceRequest.interestedAndWithdrawalComments }}
                }
              } @else if (serviceRequest.proposalComments !== null && serviceRequest.proposalComments !== "") {
                @if (serviceRequest.proposalComments?.length > 10) {
                  {{ serviceRequest.proposalComments.substring(0, 9) }}...
                } @else {
                  {{ serviceRequest.proposalComments }}
                }
              }
            </td>
          </ng-container>

          <ng-container matColumnDef="acceptedReason">
            <th mat-header-cell *matHeaderCellDef>Accepted Reason</th>
            <td mat-cell *matCellDef="let serviceRequest">
              {{ serviceRequest.acceptedReason }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef>Options</th>
            <td mat-cell *matCellDef="let serviceRequestProvider">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menu"
                aria-label="Example icon-button with a menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
  
              <mat-menu #menu="matMenu">
                @if (
                  (serviceRequestProvider.status === "Interested" ||
                  serviceRequestProvider.status === "ProposalRequested") &&
                  serviceRequest.requestType !== "EI"
                ) {
                  <button
                    mat-menu-item
                    (click)="selectProviderEarlyClick(serviceRequestProvider.id)"
                  >
                    <span>Select Without Proposal</span>
                  </button>
                }
  
                @if (
                  serviceRequest !== null &&
                  serviceRequestProvider.status !== "ProposalSubmitted" &&
                  serviceRequestProvider.status !== "Pending" &&
                  serviceRequestProvider.status !== "Withdrawn" &&
                  serviceRequestProvider.status !== "Accepted" &&
                  serviceRequest.status !== "Closed"
                ) {
                  <button
                    mat-menu-item
                    (click)="requestProposalsClick(serviceRequestProvider.id)"
                  >
                    <span>Request Proposal</span>
                  </button>
                }
  
                @if (serviceRequestProvider.status === "ProposalSubmitted") {
                  <button
                    mat-menu-item
                    (click)="viewProposalClick(serviceRequestProvider.id)"
                  >
                    <span>View Proposal</span>
                  </button>
                }
  
                @if (serviceRequestProvider.providerAttachments.length > 0) {
                  <button
                    mat-menu-item
                    (click)="
                      viewAttachments(serviceRequestProvider.providerAttachments)
                    "
                  >
                    <span>View Attachments</span>
                  </button>
                }
  
                @if (
                  menu?.items?.length !== undefined && menu?.items?.length! === 0
                ) {
                  <span class="p-2">No Options Available</span>
                }
              </mat-menu>
            </td>
          </ng-container>
  
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
            class="font-bold text-white bg-table-header-gray"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              let odd = odd;
              let even = even;
              columns: displayedColumns
            "
            [ngClass]="{
              'bg-table-row-odd-gray': odd,
              'bg-table-row-even-gray': even
            }"
          ></tr>
        </table>
      }
    </div>
  </div>
}