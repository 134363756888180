export class PaginatedServiceRequestReport {
    id: string;
    serviceRequestId: string;
    status: string;
    serviceRequestServiceRequestNumber: number;
    serviceRequestFirstName: string;
    serviceRequestMiddleName: string;
    serviceRequestLastName: string;
    serviceRequestMci: string;
    categories: string[];
    serviceRequestStatus: string;
    providerEmail: string;
    providerFirstName: string;
    providerLastName: string;
    providerAgency: string | null;
    serviceRequestRequestType: string;
    serviceRequestCreatedAt: Date;
    serviceRequestUpdatedAt: Date;
    serviceRequestComments: string;

    constructor(
        id: string,
        serviceRequestId: string,
        status: string,
        serviceRequestServiceRequestNumber: number,
        serviceRequestFirstName: string,
        serviceRequestMiddleName: string,
        serviceRequestLastName: string,
        serviceRequestMci: string,
        categories: string[],
        serviceRequestStatus: string,
        providerEmail: string,
        providerFirstName: string,
        providerLastName: string,
        providerAgency: string | null,
        serviceRequestRequestType: string,
        serviceRequestCreatedAt: Date,
        serviceRequestUpdatedAt: Date,
        serviceRequestComments: string
    ) {
        this.id = id,
        this.serviceRequestId = serviceRequestId,
        this.status = status,
        this.serviceRequestServiceRequestNumber = serviceRequestServiceRequestNumber,
        this.serviceRequestFirstName = serviceRequestFirstName,
        this.serviceRequestMiddleName = serviceRequestMiddleName,
        this.serviceRequestLastName = serviceRequestLastName,
        this.serviceRequestMci = serviceRequestMci,
        this.categories = categories,
        this.serviceRequestStatus = serviceRequestStatus,
        this.providerEmail = providerEmail,
        this.providerFirstName = providerFirstName,
        this.providerLastName = providerLastName,
        this.providerAgency = providerAgency,
        this.serviceRequestRequestType = serviceRequestRequestType,
        this.serviceRequestCreatedAt = serviceRequestCreatedAt,
        this.serviceRequestUpdatedAt = serviceRequestUpdatedAt,
        this.serviceRequestComments = serviceRequestComments
    }
}

export interface IPaginatedServiceRequestReport {
    id: string;
    serviceRequestId: string;
    status: string;
    serviceRequestServiceRequestNumber: number;
    serviceRequestFirstName: string;
    serviceRequestMiddleName: string;
    serviceRequestLastName: string;
    serviceRequestMci: string;
    categories: string[];
    serviceRequestStatus: string;
    providerEmail: string;
    providerFirstName: string;
    providerLastName: string;
    providerAgency: string | null;
    serviceRequestRequestType: string;
    serviceRequestCreatedAt: Date;
    serviceRequestUpdatedAt: Date;

}
